import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useWhiteLabel from '@hooks/use-white-label';
import { WhiteLabelSupplierAddSchema, WhiteLabelSupplierEditSchema } from '@schemas/white-label';
import { IWhiteLabelSupplierPayload } from '@schemas/white-label/types';

import WhiteLabelLayoutBase from '@components/organisms/general/white-label-supplier/layout-base';
import { WhiteLabelSupplierFormContent } from '@components/organisms/general/white-label-supplier/content';
import '@components/organisms/general/white-label-supplier/style.css';
import { scrollToFirstError } from '@utils/common';

interface IWhiteLabelSupplierFormProps {
  operationStep: 'ADD' | 'UPDATE';
}

const emptyDefaultValues: IWhiteLabelSupplierPayload = {
  fileList: {} as File,
  presentation: {
    name: '',
    phone: '',
    email: '',
  },
  type: '' as 'TRADER',
  colors: {
    main: '',
    darker: '',
    brighter: '',
  },
  differences: [
    {
      title: '',
      icon: '',
      description: '',
    },
    {
      title: '',
      icon: '',
      description: '',
    },
    {
      title: '',
      icon: '',
      description: '',
    },
    {
      title: '',
      icon: '',
      description: '',
    },
  ],
  prices: {
    migration: 0,
    management: 0,
    discount: 0,
  },
  users: [],
};

const WhiteLabelSupplierFormPage = ({ operationStep }: IWhiteLabelSupplierFormProps): JSX.Element => {
  const { whiteLabelSupplier } = useWhiteLabel();

  const resolver = zodResolver(operationStep === 'ADD' ? WhiteLabelSupplierAddSchema : WhiteLabelSupplierEditSchema);

  const formController = useForm<IWhiteLabelSupplierPayload>({
    defaultValues: emptyDefaultValues,
    resolver,
    mode: 'all',
  });

  const getOperationType = () => {
    if (operationStep === 'UPDATE') return 'Editar Fornecedora';
    if (operationStep === 'ADD') return 'Adicionar Fornecedora';
    return '';
  };

  const getOperationFlow = () => {
    if (operationStep === 'ADD') return 'WHITE_LABEL_GENERATOR_FORM';
    if (operationStep === 'UPDATE') return 'WHITE_LABEL_UPDATE_FORM';
    return 'WHITE_LABEL_LIST';
  };

  useEffect(() => {
    if (operationStep === 'UPDATE' && whiteLabelSupplier) {
      formController.setValue('colors', whiteLabelSupplier?.colors);
      formController.setValue('differences', whiteLabelSupplier?.differences);
      formController.setValue('presentation', whiteLabelSupplier?.presentation);
      formController.setValue('prices', whiteLabelSupplier?.prices);
      formController.setValue('type', whiteLabelSupplier?.type);
      formController.setValue('users', whiteLabelSupplier?.users);
    }
  }, [operationStep, whiteLabelSupplier]);

  useEffect(() => {
    scrollToFirstError(formController);
  }, [formController.formState.errors]);

  return (
    <WhiteLabelLayoutBase title={getOperationType()} witheLabelType={'WHITE_LABEL'} witheLabelFlow={getOperationFlow()}>
      <WhiteLabelSupplierFormContent formController={formController} />
    </WhiteLabelLayoutBase>
  );
};

export default WhiteLabelSupplierFormPage;
