import { getGroupInfoTableData } from '@pages/detailed-analysis/helper';
import { ISimulationResultBase } from '@schemas/types';
import { DataTable } from '@components/atoms/detailedAnalysisGC/dataTable';

export function GenerateTableGroupInfo({ proposalData }: { proposalData: ISimulationResultBase }) {
  const groupInfoTableData = getGroupInfoTableData(proposalData);
  return (
    <>
      <h3 className="text-lg font-semibold mb-4 flex items-center">Dados do Grupo</h3>
      <DataTable data={groupInfoTableData} />
    </>
  );
}
