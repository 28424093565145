import { IProductTwoSimulationResultSummary } from '@schemas/product-two/types';

import SlideHeader from '@components/atoms/simulation/slide-header';

import ScopeDisplay from './scope-display';

interface ITradersResultsEconomyComparison {
  tradersResults: Array<IProductTwoSimulationResultSummary> | undefined;
}

export const TradersResultsEconomyComparison = ({ tradersResults }: ITradersResultsEconomyComparison): JSX.Element => {
  return (
    <div className="w-full h-full flex flex-col items-start">
      <div className="w-full mb-9">
        <SlideHeader title="Comparativo de fornecedoras" />
      </div>
      <div className="w-full justify-between items-start">
        <ScopeDisplay tradersResults={tradersResults} />
      </div>
    </div>
  );
};
