import z from 'zod';
import {
  aclTypeEnum,
  chargesCoverageTypeEnum,
  chargesCoverageTypeForManualAclPricesEnum,
  guaranteeTypeEnum,
  managementTerminationFineEnum,
  managerTypeEnum,
  productTypeEnum,
  RiskTypeEnum,
} from './types';
import { ProductTwoSimulationStatusEnum } from './product-two/types';

const defaultRequiredMessage = 'Campo é obrigatório';

export const ProposalUserSchema = z.object({
  id: z.string().optional(),
  name: z.string().optional(),
  pictureUrl: z.string().optional(),
});

export const ProposalLinkedUsersSchema = z.object({
  author: ProposalUserSchema.optional(),
  seller: ProposalUserSchema.nullable().optional(),
});

export const CustomFeeAuxiliarFieldsSchema = z.object({
  uniqueMargin: z.number().nullable().optional(),
  isMarginUnique: z.boolean().optional(),
});

export const CustomFeeSchema = z.object({
  type: z.union([z.literal('ABSOLUTE'), z.literal('PERCENTAGE')]).optional(),
  values: z.union([z.array(z.number()), z.record(z.number())]).optional(),

  auxiliarFields: CustomFeeAuxiliarFieldsSchema.optional(),
});

export const OperationHoursRangeSchema = z.object({
  openingTime: z.string({ required_error: 'Horário de abertura inválido' }).nullable().optional(),
  closingTime: z.string({ required_error: 'Horário de fechamento inválido' }).nullable().optional(),
});

export const OperationHoursRangeSetSchema = z.object({
  businessDays: OperationHoursRangeSchema.nullable().optional(),
  saturday: OperationHoursRangeSchema.nullable().optional(),
  sunday: OperationHoursRangeSchema.nullable().optional(),
});

const monthYearSchema = z
  .record(z.union([z.nan().optional(), z.number()]).nullable().nullable().optional())
  .superRefine((data, ctx) => {
    const isInvalid = Object.values(data).some((value) => value !== null || !Number.isNaN(value));
    if (!isInvalid) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: defaultRequiredMessage,
      });
    }
    return true;
  });

export type IConsumption = z.infer<typeof monthYearSchema>;

export const monthConsumptionSchema = z
  .record(z.union([z.nan().optional(), z.string().optional(), z.number()]).nullable().optional())
  .superRefine((data, ctx) => {
    const isInvalid = Object.values(data).some((value) => Number(value) > 0);
    if (!isInvalid) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: defaultRequiredMessage,
      });
    }
    return true;
  });

export const SimulationUnitPayloadBaseSchema = z.object({
  aux__useOpenInWeekends: z.boolean().optional(),
  aux__useOperationInSaturdays: z.boolean().optional(),
  aux__useOperationInSundays: z.boolean().optional(),
  aclContractedDemandOffPeak: z.number().nullable().optional(),
  aclContractedDemandPeak: z.number().nullable().optional(),
  acrContractedDemandOffPeak: z.number().nullable().optional(),
  acrContractedDemandPeak: z.number().nullable().optional(),
  companyType: z.number().nullable().optional(),
  consumptionOffPeak: z
    .record(
      z
        .union([
          z.number({
            invalid_type_error: defaultRequiredMessage,
            required_error: ' Campo é obrigatorio',
          }),
          z.nan().optional(),
          z.string().optional(),
        ])
        .nullable()
        .optional(),
    )
    .optional(),
  consumptionPeak: z
    .record(
      z
        .union([
          z.number({
            invalid_type_error: defaultRequiredMessage,
            required_error: ' Campo é obrigatorio',
          }),
          z.nan().optional(),
          z.string().optional(),
        ])
        .nullable()
        .optional(),
    )

    .optional(),
  consumptionLowVoltage: z
    .record(
      z
        .union([
          z.number({
            invalid_type_error: defaultRequiredMessage,
            required_error: ' Campo é obrigatorio',
          }),
          z.nan().optional(),
          z.string().optional(),
        ])
        .nullable()
        .optional(),
    )
    .nullable()
    .optional(),
  operationHoursRange: OperationHoursRangeSetSchema.nullable().optional(),
  distributedGenerationMonthlyCost: z.number().nullable().optional(),
  energyDistributionCompany: z.number().nullable().optional(),
  icmsType: z.string().optional(),
  initialExpenses: z.number().nullable().optional(),
  injectedEnergyOffPeak: z.record(z.number()).nullable().optional(),
  injectedEnergyPeak: z.record(z.number()).nullable().optional(),
  managementPrice: z.number().nullable().optional(),
  supplyStartDate: z.string().optional(),
  nickname: z.string().optional(),
  powerGeneratorCost: z.number().nullable().optional(),
  targetVolumeGenerated: z.number().nullable().optional(),
  tariff: z.number().nullable().optional(),
});

export const SimulationCompetitorPayloadBaseSchema = z.object({
  aclPrices: z
    .union([z.record(z.number().nullable().optional()), z.array(z.number().nullable().optional())])
    .transform((data) => {
      if (Array.isArray(data)) {
        const yearStart = new Date().getFullYear();
        return data.slice(yearStart).reduce((acc: Record<number, number>, value, index) => {
          acc[yearStart + index] = value as number;
          return acc;
        }, {});
      }
      return data;
    })
    .nullable()
    .optional(),
  chargesCoverageType: chargesCoverageTypeEnum,
  lowerFlexibility: z.number().nullable().optional(),
  guaranteeType: guaranteeTypeEnum,
  managerType: managerTypeEnum,
  monthlyManagementPricePerUnit: z.number().nullable().optional(),
  managementTerminationFine: managementTerminationFineEnum,
  productType: productTypeEnum,
  saving: z.number().nullable().optional(),
  upperFlexibility: z.number().nullable().optional(),
});

export const ManualAclPriceSchema = z
  .union([
    z.array(z.number().nullable()),
    z.record(
      z.string(),
      z.union([z.record(z.number().nullable().optional()), z.array(z.number().nullable().optional())]),
    ),
  ])
  .nullable()
  .optional();

export const MigrationCostSchema = z.object({
  breakdown: z.record(z.number()),
  totalCost: z.number().nullable().optional(),
});

export const AclPriceSchema = z.object({
  year: z.number().nullable().optional(),
  price: z.number().nullable().optional(),
});

export const TargetYearsSchema = z.array(
  z
    .number()
    .min(2025)
    .max(new Date().getFullYear() + 10),
);

export const IRecCertificationSchema = z.object({
  isCertified: z.boolean().optional(),
  certificationDate: z.string().optional(),
  issuer: z.string().optional(),
});

export const AuxiliaryFieldsSchema = z.object({
  useManualDiscount: z.boolean().optional(),
  useManualAclPrice: z.boolean().optional(),
  useManualManagementPrice: z.boolean().optional(),
  useManualMigrationCost: z.boolean().optional(),
});

export const DiscountInputSchema = z.record(z.record(z.number()));

export const latestProposalMetadataSchema = z.object({
  proposalId: z.string().optional(),
  description: z.string().nullable().optional(),
  seller: ProposalUserSchema.optional(),
  author: ProposalUserSchema.required().optional(),
  status: z.enum(Object.values(ProductTwoSimulationStatusEnum) as [string, ...string[]]),
  commercialDealerComission: z.number().nullable().optional(),
  createdAt: z.string().optional(),
});

export const simulatorIndexedMetadataSchema = z.object({
  latestProposalMetadata: latestProposalMetadataSchema.optional(),
  proposalsNumber: z.number().nullable().optional(),
});

export const leadSimulatorsIndexedMetadataSchema = z.object({
  productOne: simulatorIndexedMetadataSchema.optional(),
  productTwo: simulatorIndexedMetadataSchema.optional(),
});

export const proposalLeadSchema = z.object({
  id: z.string().optional(),
  name: z.string().optional(),
  createdAt: z.string({
    required_error: defaultRequiredMessage,
    invalid_type_error: defaultRequiredMessage,
  }),
  deletedAt: z.string().optional().nullable().optional(),
  simulatorsIndexedMetadata: leadSimulatorsIndexedMetadataSchema.optional(),
});

export const simulationPayloadBaseSchema = z
  .object({
    aclType: aclTypeEnum,
    aux__useManualDiscount: z.boolean().optional(),
    aux__useManualAclPrice: z.boolean().optional(),
    aux__useManualMigrationCost: z.boolean().optional(),
    aux__useManualManagementPrice: z.boolean().optional(),
    aux__useCompetitorList: z.boolean().optional(),
    calculationType: z.string().optional(),
    competitorsList: z.array(SimulationCompetitorPayloadBaseSchema).nullable().optional(),
    exclusiveTrader: z.boolean().optional(),
    hasManualDiscounts: z.boolean().optional(),
    hasManualPrice: z.boolean().optional(),
    lead: z.string().nullable().optional(),
    description: z.string().nullable().optional(),
    manualPricesOrDiscountsStartYear: z.number().nullable().optional(),
    manualAclPrice: ManualAclPriceSchema.optional(),
    manualAclPricesHasChargesCoverage: chargesCoverageTypeForManualAclPricesEnum.optional(),
    manualDiscountInputs: z.record(z.record(z.number())).optional(),
    productType: productTypeEnum,
    proposalValidity: z.string().nullable().optional(),
    risk: RiskTypeEnum,
    serviceProposalPricesDiscountPercentage: z.number().nullable().optional(),
    targetYears: z.array(z.number()).nullable().optional(),
    targetYear: z.number().nullable().optional(),
    totalMigrationCost: z.number().nullable().optional(),
    units: z.array(SimulationUnitPayloadBaseSchema).optional(),
    whiteLabelSupplierId: z.string().nullable().optional(),
    rawInputs: z.record(z.any()).nullable().optional(),
  })
  .merge(ProposalLinkedUsersSchema);

export const ProposalSchema = z.object({
  id: z.string().optional(),
  lead: ProposalUserSchema,
  createdAt: z.string().optional(),
  deletedAt: z.string().optional(),
  inputs: simulationPayloadBaseSchema,
  calculatorMetadata: z.record(z.any()),
});

export const SimulationResultBaseUnitSchema = z.object({
  carbonDioxideEmissionInTonsAvoidedByYear: z.record(z.number()),
  energyFeeMonthlyByYear: z.record(z.number()),
  grossMerchandiseVolumeByYear: z.record(z.number()),
  takeRateByYear: z.record(z.number()),
  totalCarbonDioxideEmissionInTonsAvoided: z.number().nullable().optional(),
  totalGrossMerchandiseVolume: z.number().nullable().optional(),
  totalLifetimeValue: z.number().nullable().optional(),
  totalManagementPrice: z.number().nullable().optional(),
  totalSaving: z.number().nullable().optional(),
  savingByMonth: z.number().nullable().optional(),
  totalTakeRate: z.number().nullable().optional(),
});

export const SimulationBaseSchema = z.object({
  calculationType: z.string().optional(),
  competitiveAnalysis: z.array(z.record(z.string(), z.any())),
  discountFreeServiceProposalPrices: z.object({
    discountPercentage: z.number().nullable().optional(),
    totalManagementPrice: z.number().nullable().optional(),
    totalMigrationCost: z.number().nullable().optional(),
  }),
  feasibility: z.boolean().optional(),
  useIRecCertification: z.boolean().optional(),
  iRecCertificationCost: z.number().nullable().optional(),
  iRecCertificationCostMonthly: z.number().nullable().optional(),
  totalMigrationCost: z.number().nullable().optional(),
  totalAverageConsumption: z.number().nullable().optional(),
  totalCurrentSpentByYear: z.record(z.number()),
  totalGuaranteedDiscountSpentByYear: z.record(z.number()),
  totalInitialExpenses: z.number().nullable().optional(),
  totalManagementPriceByYear: z.record(z.number()),
  totalManagementPriceByYearPercentage: z.record(z.number()),
  totalSavingByYear: z.record(z.number()),
  totalSavingByMonth: z.number().nullable().optional(),
  totalSavingByYearPercentage: z.record(z.number()),
  totalSavingPercentage: z.number().nullable().optional(),
  totalMonthlyManagementCost: z.number().nullable().optional(),
  totalTargetSpentByYear: z.record(z.number()),
  commercialDealerComission: z.object({
    initialAmount: z.number().nullable().optional(),
    inCashAmount: z.number().nullable().optional(),
    inInstallmentsAmount: z.number().nullable().optional(),
    totalAmount: z.number().nullable().optional(),
    numberOfInstallments: z.number().nullable().optional(),
  }),
  unitsReports: z.array(z.record(z.string(), z.any())),
  vpl: z.number().nullable().optional(),
});

export const SimulationResultBaseSchema = SimulationBaseSchema.merge(SimulationResultBaseUnitSchema);

export const SimulationExtendedResultBaseSchema = z.object({
  aclPrices: z
    .union([z.record(z.number().nullable()), z.array(z.number().nullable())])
    .transform((data) => {
      if (Array.isArray(data)) {
        const yearStart = new Date().getFullYear();
        return data.slice(yearStart).reduce((acc: Record<number, number>, value, index) => {
          acc[yearStart + index] = value as number;
          return acc;
        }, {});
      }
      return data;
    })
    .nullable()
    .optional(),
  leadName: z.string().nullable().optional(),
  currentSpent: z.number().nullable().optional(),
  currentSpentByYear: z.record(z.number()),
  currentSpentByYearList: z.array(z.number()),
  duration: z.number().nullable().optional(),
  initialExpenses: z.number().nullable().optional(),
  proposalValidity: z.string().optional(),
  retailTraderId: z.string().optional(),
  saving: z.number().nullable().optional(),
  savingByYear: z.record(z.number()),
  savingByYearPercentageList: z.array(z.number()),
  savingPercentage: z.number().nullable().optional(),
  simulationResultYearsList: z.array(z.string()),
  targetSpent: z.number().nullable().optional(),
  targetSpentByYear: z.record(z.number()),
  targetSpentByYearList: z.array(z.number()),
  totalSaving: z.number().nullable().optional(),
});

export const proposalsAggregatedByLeadBaseSchema = z.object({
  lead: proposalLeadSchema,
  proposals: z.array(z.any()),
});

export const simulationTariffFlagScenarioBaseSchema = z.object({
  averageSavingByMonth: z.number().nullable().optional(),
  sampleMonthSimulation: z.any().optional(),
  totalSavingWithInitialExpenses: z.number().nullable().optional(),
  totalSavingInPercentageWithInitialExpenses: z.number().nullable().optional(),
});

export const simulationTariffFlagScenariosBaseSchema = z.object({
  green: simulationTariffFlagScenarioBaseSchema,
  redP1: simulationTariffFlagScenarioBaseSchema,
  redP2: simulationTariffFlagScenarioBaseSchema,
  yellow: simulationTariffFlagScenarioBaseSchema,
  default: simulationTariffFlagScenarioBaseSchema,
});

export const contractUnitPayloadBaseSchema = z.object({
  comsumptionUnitName: z.string().optional(),
  unitManagementPrice: z.string().optional(),
  unitAdress: z.string().optional(),
});

export const contractPayloadBaseSchema = z.object({
  units: z.array(contractUnitPayloadBaseSchema).optional(),
});

export const contractBaseSchema = z.object({
  units: z.array(contractUnitPayloadBaseSchema).optional(),
});

export const discountFreeServiceProposalPricesSchema = z.object({
  discountPercentage: z
    .number({ invalid_type_error: defaultRequiredMessage, required_error: 'O desconto é obrigatório' })
    .min(1, { message: 'O desconto deve ser maior ou igual a 1' })
    .max(100, { message: 'O desconto deve ser menor ou igual a 100' }),
  totalManagementPrice: z.number().nullable().optional(),
  totalMonthlyManagementPrice: z.number().nullable().optional(),
  totalMigrationCost: z.number().nullable().optional(),
});

export const simulationReportBaseSchema = z.object({
  averageConsumption: z.number(),
  carbonDioxideEmissionInTonsAvoidedByYear: z.record(z.number()).nullable().optional(),
  currentSpentByYear: z.record(z.number()),
  energyFeeMonthlyByYear: z.record(z.number()).nullable().optional(),
  grossMerchandiseVolumeByYear: z.record(z.number()).nullable().optional(),
  initialExpenses: z.number().nullable().optional(),
  manualAclPrice: ManualAclPriceSchema.optional(),
  managementPriceByYear: z.record(z.number()).nullable().optional(),
  totalMigrationCost: z.number().nullable().optional(),
  nickname: z.string().optional(),
  priceListWithInflation: z.record(z.record(z.union([z.string().optional(), z.number()]))),
  priceList: z.record(z.record(z.union([z.string().optional(), z.number()]))).optional(),
  savingByYear: z.record(z.number()),
  savingByYearInPercentage: z.record(z.number()),
  savingIn24Monhts: z.number(),
  takeRateByYear: z.record(z.number()).nullable().optional(),
  targetSpentByYear: z.record(z.number()),
  totalCarbonDioxideEmissionInTonsAvoided: z.number().nullable().optional(),
  totalGrossMerchandiseVolume: z.number().nullable().optional(),
  totalLifetimeValue: z.number().nullable().optional(),
  totalManagementPrice: z.number(),
  totalMonths: z.number(),
  totalSaving: z.number(),
  savingByMonth: z.number(),
  totalSavingInPercentage: z.number(),
  totalTakeRate: z.number().nullable().optional(),
});

export const updateSellerPayloadSchema = z.object({
  proposalId: z.string(),
  seller: ProposalUserSchema,
  leadId: z.string(),
});

export const sampleMonthSimulationReportSchema = z.object({
  month: z.number().nullable().optional(),
  monthLabel: z.string().nullable().optional(),
  totalSavingInPercentageWithTaxes: z.number().nullable().optional(),
  totalSavingWithTaxes: z.number().nullable().optional(),
  year: z.number().nullable().optional(),
});

export const sampleMonthSimulationMarketBaseSchema = z.object({
  consumptionOffPeak: z.record(z.number().nullable()).nullable().optional(),
  consumptionPeak: z.record(z.number().nullable()).nullable().optional(),
  demandOffPeak: z.number().nullable().optional(),
  demandPeak: z.number().nullable().optional(),
  distributionTariffOffPeak: z.number().nullable().optional(),
  distributionTariffPeak: z.number().nullable().optional(),
  monthGeneratorFactor: z.number().nullable().optional(),
  monthYear: z.string().optional(),
  tariff: z.any().optional(), // definir as tarifas
  totalWithoutTaxes: z.number().nullable().optional(),
  totalWithTaxes: z.number().nullable().optional(),
});

export const sampleMonthSimulationCurrentMarketSchema = sampleMonthSimulationMarketBaseSchema.extend({
  electricityTariffOffPeak: z.number().nullable().optional(),
  electricityTariffPeak: z.number().nullable().optional(),
  generatorCost: z.number().nullable().optional(),
  injectedEnergyOffPeak: z.number().nullable().optional(),
  tariffFlag: z.number().nullable().optional(),
  totalPisCofins: z.number().nullable().optional(),
  totalWithIcms: z.number().nullable().optional(),
});

export const sampleMonthSimulationTargetMarketSchema = sampleMonthSimulationMarketBaseSchema.extend({
  contributionCcee: z.number().nullable().optional(),
  demandOffPeakDiscount: z.number().nullable().optional(),
  demandPeakDiscount: z.number().nullable().optional(),
  distributionIcms: z.number().nullable().optional(),
  distributionPisCofins: z.number().nullable().optional(),
  distributionTariffPeakDiscount: z.number().nullable().optional(),
  electricityIcms: z.number().nullable().optional(),
  electricityTariff: z.number().nullable().optional(),
  electricityWithLosses: z.number().nullable().optional(),
  icms: z.record(z.any()),
  managementPrice: z.number().nullable().optional(),
  pisCofinsHighlight: z.number().nullable().optional(),
  priceListWithInflation: z.array(z.record(z.any())),
  securityAndModulationEnergyCharges: z.number().nullable().optional(),
  stimulatedDemandOffPeak: z.number().nullable().optional(),
  stimulatedDemandPeak: z.number().nullable().optional(),
  stimulatedDistributionTariffPeak: z.number().nullable().optional(),
});

export const sampleMonthSimulationSchema = z.object({
  currentMarket: sampleMonthSimulationCurrentMarketSchema,
  targetMarket: sampleMonthSimulationTargetMarketSchema,
  report: sampleMonthSimulationReportSchema,
});

export const simulationTariffSchema = z.object({
  demandOffPeak: z.number().nullable().optional(),
  demandPeak: z.number().nullable().optional(),
  distributionTariffOffPeak: z.number().nullable().optional(),
  distributionTariffPeak: z.number().nullable().optional(),
  electricityTariffOffPeak: z.number().nullable().optional(),
  electricityTariffPeak: z.number().nullable().optional(),
  energyDistributionCompany: z.record(z.any()),
  id: z.number().nullable().optional(),
  stimulatedDemandOffPeak: z.number().nullable().optional(),
  stimulatedDemandPeak: z.number().nullable().optional(),
  stimulatedDistributionTariffPeak: z.number().nullable().optional(),
  tariffAdjustmentDate: z.string().optional(),
  tariffType: z.number().nullable().optional(),
});

export const simulationPremisesGeneralSchema = z.object({
  pis: z.number().nullable().optional(),
  cofins: z.number().nullable().optional(),
  basicNetworkLosses: z.number().nullable().optional(),
  securityAndReservationCharges: z.number().nullable().optional(),
  modulation: z.number().nullable().optional(),
  clarkeManagement: z.number().nullable().optional(),
  cceeContribution: z.number().nullable().optional(),
  accessionCcee: z.number().nullable().optional(),
  adequationSmf: z.number().nullable().optional(),
  aclDemandDiscount: z.number().nullable().optional(),
  electricityPisCofins: z.number().nullable().optional(),
  tariffFlag: z.any().optional(),
});

export const simulationPremisesIcmsSchema = z.object({
  fixedValue: z.number().nullable().optional(),
  id: z.number().nullable().optional(),
  percentageValue: z.number().nullable().optional(),
  referenceDate: z.string().optional(),
  taxType: z.string().optional(),
});

export const simulationPremisesEdcInfoFPGDRelationSchema = z.object({
  percentage: z.number().nullable().optional(),
});

export const simulationPremisesEdcInfoSchema = z.object({
  fixedPriceGuaranteedDiscountRelation: simulationPremisesEdcInfoFPGDRelationSchema,
  id: z.number().nullable().optional(),
  name: z.string().optional(),
  removesIcms: z.boolean().optional(),
});

export const simulationPremisesAclPricesSchema = z.object({
  id: z.number().nullable().optional(),
  year: z.string().optional(),
  fixedPrice: z.number().nullable().optional(),
});

export const simulationPremisesTariffEdcInfoSchema = z.object({
  id: z.number().nullable().optional(),
  name: z.string().optional(),
});

export const simulationPremisesTariffSchema = z.object({
  demandOffPeak: z.number().nullable().optional(),
  demandPeak: z.number().nullable().optional(),
  distributionTariffOffPeak: z.number().nullable().optional(),
  distributionTariffPeak: z.number().nullable().optional(),
  electricityTariffOffPeak: z.number().nullable().optional(),
  electricityTariffPeak: z.number().nullable().optional(),
  energyDistributionCompany: simulationPremisesTariffEdcInfoSchema,
  id: z.number().nullable().optional(),
  tariff: z.number().nullable().optional(),
  tariffAdjustmentDate: z.string().optional(),
});

export const simulationPremisesSchema = z.object({
  general: simulationPremisesGeneralSchema,
  icms: simulationPremisesIcmsSchema.optional(),
  edcInfo: simulationPremisesEdcInfoSchema.optional(),
  tariffsList: z.array(simulationPremisesTariffSchema).optional(),
  aclPrices: z.array(simulationPremisesAclPricesSchema).optional(),
});

export const responseBaseSchema = z.object({
  code: z.string().optional(),
  message: z.string().optional(),
  success: z.boolean().optional(),
  data: z.any().optional(),
});

export const paginatedResponseBaseSchema = z.object({
  limit: z.number().nullable().optional(),
  page: z.number().nullable().optional(),
  total: z.number().nullable().optional(),
  data: z.array(z.any()),
});

export const proposalEconomyProjectionTableDataSchema = z.object({
  item: z.any().optional(),
  investment: z.string().optional(),
  total: z.string().optional(),
});

export const generalTitleAndValuePairSchema = z.object({
  title: z.string().optional(),
  value: z.union([z.string().optional(), z.number()]).optional(),
});

export const commercialDealerComissionSchema = z.object({
  initialAmount: z.number().nullable().optional(),
  inCashAmount: z.number().nullable().optional(),
  inInstallmentsAmount: z.number().nullable().optional(),
  totalAmount: z.number().nullable().optional(),
  numberOfInstallments: z.number().nullable().optional(),
});

export const VariantSchema = z.enum(['neutral', 'primary', 'error']);

export const FeedbackNotificationPropsSchema = z.object({
  label: z.string().optional(),
  message: z.string().optional(),
  kind: VariantSchema,
  actionLabel: z.string().optional(),
  action: z.function().args().returns(z.void()).optional(),
  onCloseNotification: z.function().args().returns(z.void()),
  showIcon: z.boolean().optional(),
  timeToCloseAutomaticallyInSeconds: z.number().nullable().optional(),
  customAttrs: z.record(z.string()).nullable().optional(),
});

export const ILeadProposalsHubHistoryBaseSchema = z.object({
  leadId: z.string().optional(),
  selectedProposalId: z.string().nullable().optional(),
  setSelectedProposalId: z
    .function()
    .args(z.union([z.string().optional(), z.undefined()]))
    .returns(z.void()),
  feedbackNotificationsContent: FeedbackNotificationPropsSchema.optional(),
});

export const leadProposalsHubBaseSchema = ILeadProposalsHubHistoryBaseSchema.extend({
  setIsDrawerOpen: z.function().args(z.boolean()).returns(z.void()),
});

export const proposalPreviewBodyBaseSchema = ILeadProposalsHubHistoryBaseSchema.extend({
  isMainContent: z.boolean().optional(),
  onClickDeleteProposal: z.function().args(z.string().optional(), z.string()).returns(z.promise(z.void())),
});

export const simulationBaseSchema = <ISimulationInputs extends z.ZodTypeAny>(inputsSchema: ISimulationInputs) =>
  z.object({
    aclType: aclTypeEnum,
    lead: proposalLeadSchema,
    inputs: inputsSchema,
    pricesExpirationDate: z.string().nullable().optional(),
  });
