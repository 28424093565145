import numeral from 'numeral';
import { twMerge } from 'tailwind-merge';

import tradersLogoDictionary from '@utils/traders';
import { formatNumber } from '@utils/numeral';

import { useAuth } from '@src/auth-wrapper';

import { IProductTwoSimulationResultSummary } from '@schemas/product-two/types';

import GlobeIcon from '@assets/images/globe-icon.svg';

import { IKeyMetric } from '@components/molecules/product-one/lead-proposals-hub/body/helper';

interface IProductTwoProposalKeyMetricsPanel {
  energyFeeMonthly?: number;
  managementFeeMonthly?: number;
  grossMerchandiseVolume?: number;
  takeRate?: number;
  lifetimeValue?: number;
  carbonDioxideEmissionInTonsAvoided?: number;
  totalAverageConsumption?: number;
  isMainContent?: boolean;
}

interface IProductTwoProposalKeyMetricsPanelForCommercialDealer {
  carbonDioxideEmissionInTonsAvoided?: number;
}

interface IKeyMetricWithIcon extends IKeyMetric {
  icon: string;
}

export const ProductTwoProposalKeyMetricsPanelForCommercialDealer = ({
  carbonDioxideEmissionInTonsAvoided,
}: IProductTwoProposalKeyMetricsPanelForCommercialDealer) => {
  const keyMetrics: Array<IKeyMetricWithIcon> = [
    {
      title: 'Emissão de CO² evitadas',
      value: carbonDioxideEmissionInTonsAvoided
        ? numeral(carbonDioxideEmissionInTonsAvoided).format('0,0.00').concat(' toneladas')
        : '-',
      icon: GlobeIcon,
    },
  ];

  return (
    <div className="flex flex-row gap-x-8 mt-5">
      {keyMetrics.map((keyMetric) => (
        <div className="flex flex-row gap-4" key={`proposal-key-metric-${keyMetric.title}`}>
          <div className="w-7 h-7 rounded-medium bg-primary-10 flex justify-center items-center">
            <img className="h-6 w-6" src={keyMetric.icon} alt={`${keyMetric.title}-icon`} />
          </div>
          <div>
            <h6 className="text-paragraph-small text-neutral-50">{keyMetric.title}</h6>
            <h5 className="text-paragraph-medium">{keyMetric.value}</h5>
          </div>
        </div>
      ))}
    </div>
  );
};

export const ProductTwoProposalKeyMetricsPanel = ({
  energyFeeMonthly,
  managementFeeMonthly,
  grossMerchandiseVolume,
  lifetimeValue,
  takeRate,
  carbonDioxideEmissionInTonsAvoided,
  totalAverageConsumption,
  isMainContent,
}: IProductTwoProposalKeyMetricsPanel) => {
  const keyMetrics: Array<IKeyMetric> = [
    {
      title: 'Energy Fee Monthly (EFM)',
      value: energyFeeMonthly ? numeral(energyFeeMonthly).format('$ 0,0.00') : '-',
    },
    {
      title: 'Management Fee Monthly (MFM)',
      value: managementFeeMonthly ? numeral(managementFeeMonthly).format('$ 0,0.00') : '-',
    },
    {
      title: 'Gross Merchandise Volume (GMV)',
      value: grossMerchandiseVolume ? numeral(grossMerchandiseVolume).format('$ 0,0.00') : '-',
    },
    {
      title: 'Take Rate (TR)',
      value: takeRate ? numeral(takeRate).format('$ 0,0.00') : '-',
    },
    {
      title: 'Life Time Value (LTV)',
      value: lifetimeValue ? numeral(lifetimeValue).format('$ 0,0.00') : '-',
    },
    {
      title: 'Emissão de CO² evitadas',
      value: carbonDioxideEmissionInTonsAvoided
        ? numeral(carbonDioxideEmissionInTonsAvoided).format('0,0.00').concat(' toneladas')
        : '-',
    },
    {
      title: ' Consumo Médio',
      value: totalAverageConsumption ? formatNumber(totalAverageConsumption, '0,0.000').concat(' MWm') : '-',
    },
  ];

  return (
    <div className={twMerge('grid mb-5', isMainContent ? 'grid-cols-3' : 'grid-cols-2')}>
      {keyMetrics.map((keyMetric) => (
        <div className="flex flex-col col-span-1 mb-6" key={`proposal-key-metric-${keyMetric.title}`}>
          <label className="text-paragraph-small font-normal text-neutral-50">{keyMetric.title}</label>
          <label>{keyMetric.value}</label>
        </div>
      ))}
    </div>
  );
};

interface IProductTwoTraderSection {
  retailTradersResults?: Array<IProductTwoSimulationResultSummary>;
  contractDuration?: number;
}

export const ProductTwoTraderSection = ({ retailTradersResults, contractDuration }: IProductTwoTraderSection) => {
  const { user } = useAuth();

  return (
    <>
      <div className="my-8 border-t border-gray-200">
        {retailTradersResults?.map((result, index) => (
          <div
            key={`product-two-proposal-preview-trader-result-${result.retailTraderId}`}
            className="border-b border-gray-200"
          >
            <div className="bg-white px-4 py-5 grid grid-cols-3">
              <div className="col-span-1 flex flex-col">
                <dt className="text-paragraph-small text-gray-500">
                  Comercializadora {user?.isCommercialDealer ? index + 1 : ''}
                </dt>
                {!user?.isCommercialDealer && (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {<img src={tradersLogoDictionary[result.retailTraderId]} className=" max-w-[6.25rem] max-h-8" />}
                  </dd>
                )}
              </div>
              <div className="col-span-1 flex flex-col">
                <dt className="text-paragraph-small text-gray-500">Economia em {contractDuration} anos (R$)</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {numeral(result.totalSaving).format('$ 0,0.00')}
                </dd>
              </div>
              <div className="col-span-1 flex flex-col">
                <dt className="text-paragraph-small text-gray-500">Economia em {contractDuration} anos (%)</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {numeral(result.totalSavingInPercentage).format('% 0.00')}
                </dd>
              </div>
            </div>
          </div>
        ))}
      </div>
      {user?.isCommercialDealer && (
        <div className="mb-9">
          <dt className="text-paragraph-small text-gray-500 mb-5">
            Comercializadoras disponíveis para esta simulação:
          </dt>
          <div className="flex flex-row gap-6">
            {retailTradersResults?.map((result, index) => (
              <img
                key={`sales-company-available-for-simulation-${index}`}
                src={tradersLogoDictionary[result.retailTraderId]}
                className=" max-w-[6.25rem] max-h-8"
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
