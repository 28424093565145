import { IconCardProps } from '@clarke-energia/foton';
import newCurrencyDollarIcon from '@assets/newProposal/currency-dollar.svg';
import newCurrencyDollarIconWhite from '@assets/newProposal/currency-dollar-white.svg';
import leafIcon from '@assets/newProposal/Leaf.svg';
import treeIcon from '@assets/newProposal/Tree.svg';
import { IWhiteLabelSupplierPayload } from '@schemas/white-label/types';
import { IHeroIcon } from '@components/atoms/form/hero-icon';

type IconColor = 'primary' | 'neutral' | 'main' | 'special' | 'variantMain' | 'variantBrighter';

export interface IconCardProposalProps extends Omit<IconCardProps, 'iconColor'> {
  iconColor?: IconColor;
  whiteLabelSupplierIcon?: IHeroIcon['icon'];
}

const whatWeDoCardData: IconCardProposalProps[] = [
  {
    title: 'Análise de',
    label: 'viabilidade',
    alternativeIcon: newCurrencyDollarIcon,
    iconColor: 'main',
  },
  {
    label: 'Migração',
    title: 'Processo de ',
    icon: 'ArrowRightOnRectangleIcon',
    iconColor: 'neutral',
  },
  {
    label: 'Energia',
    title: 'Contratação de ',
    icon: 'DocumentDuplicateIcon',
    iconColor: 'neutral',
  },
  {
    label: 'Gestão e novas contratações',
    title: 'Acompanhamento,',
    icon: 'UserPlusIcon',
    iconColor: 'neutral',
  },
];

function exclusiveWhiteLabelCardData(differences: IWhiteLabelSupplierPayload['differences']): IconCardProposalProps[] {
  if (!differences || !Array.isArray(differences) || differences.length === 0) {
    return [];
  }

  const result: IconCardProposalProps[] = differences.map((difference, index) => {
    const item: IconCardProposalProps = {
      title: difference.title || '',
      label: difference.description || '',
      whiteLabelSupplierIcon: difference.icon as IHeroIcon['icon'],
      iconColor: index < 4 ? 'variantBrighter' : 'neutral',
    };
    return item;
  });

  return result;
}

const whiteLabelSupplierCardData: IconCardProposalProps[] = [
  {
    title: 'Análise de',
    label: 'viabilidade',
    alternativeIcon: newCurrencyDollarIconWhite,
    iconColor: 'variantMain',
  },
  {
    label: 'Migração',
    title: 'Processo de ',
    icon: 'ArrowRightOnRectangleIcon',
    iconColor: 'neutral',
  },
  {
    label: 'Energia',
    title: 'Contratação de ',
    icon: 'DocumentDuplicateIcon',
    iconColor: 'neutral',
  },
  {
    label: 'Gestão e novas contratações',
    title: 'Acompanhamento,',
    icon: 'UserPlusIcon',
    iconColor: 'neutral',
  },
];

const migrationCardData: IconCardProposalProps[] = [
  {
    title: 'Proposta sem ',
    label: 'padrão',
    icon: 'BriefcaseIcon',
    iconColor: 'special',
  },
  {
    title: 'Pouca Adesão ',
    label: 'ao mercado',
    icon: 'FlagIcon',
    iconColor: 'special',
  },
  {
    title: 'Muitos riscos ',
    label: 'desconhecidos',
    icon: 'ExclamationTriangleIcon',
    iconColor: 'special',
  },
];

const howWeDoCardData: IconCardProposalProps[] = [
  {
    title: 'Atendimento',
    label: 'Conte com o time de especialistas da Clarke para guiar as decisões de energia da sua empresa.',
    icon: 'UserIcon',
    iconColor: 'main',
  },
  {
    title: 'Rescisão fácil ',
    label:
      'Somos a única gestora que te oferece um contrato sem multa de rescisão - apenas um aviso prévio de 3 meses.',
    icon: 'DocumentCheckIcon',
    iconColor: 'main',
  },
  {
    title: 'Tecnologia',
    label: 'Tenha todos os dados relevantes sobre sua energia na palma da sua mão, de maneira rápida e fácil.',
    icon: 'CodeBracketIcon',
    iconColor: 'main',
  },
  {
    title: 'Segurança',
    label: 'Tenha visibilidade do risco de cada fornecedor e tome decisões seguras nos contratos de energia.',
    icon: 'ShieldCheckIcon',
    iconColor: 'main',
  },
];

const exclusiveEnergisaCardData: IconCardProposalProps[] = [
  howWeDoCardData[2],
  {
    ...howWeDoCardData[3],
    label:
      'Tenha a segurança de um fornecedor de energia com mais de 119 anos de atuação e mais de R$ 100 milhões de patrimônio líquido',
  },
  howWeDoCardData[0],
];

const iRecCardData: IconCardProposalProps[] = [
  { title: 'Neutralizar', label: 'as emissões <b>antes</b> de migrar', alternativeIcon: treeIcon, iconColor: 'main' },
  { title: 'Evitar', label: 'as emissões <b>depois</b> de migrar', alternativeIcon: leafIcon, iconColor: 'main' },
  { title: 'R$ 000,00', label: 'de investimento para o certificado', icon: 'CurrencyDollarIcon', iconColor: 'main' },
];

export {
  migrationCardData,
  whatWeDoCardData,
  howWeDoCardData,
  iRecCardData,
  exclusiveEnergisaCardData,
  whiteLabelSupplierCardData,
  exclusiveWhiteLabelCardData,
};
