import { IGeneralFormSelectableInputsOptions } from '@components/atoms/form/types';
import { SimulatorTypeEnum, getSimulatorLabel, simulatorLabelIndexer } from '@schemas/types';

export const getSimulatorsTypesInputOptions = (simulatorType: keyof typeof SimulatorTypeEnum) => {
  const parsedSimulatorsTypes = Object.keys(simulatorLabelIndexer).map((simulatorTypeKey) => {
    const parsedSimulatorType = simulatorTypeKey as keyof typeof SimulatorTypeEnum;

    if (parsedSimulatorType === 'CONTRACTED_DEMAND' || parsedSimulatorType === simulatorType) return;

    return {
      id: parsedSimulatorType,
      value: parsedSimulatorType,
      optionLabel: getSimulatorLabel(parsedSimulatorType),
    };
  });

  const filteredSimulatorsTypesInputOptions = parsedSimulatorsTypes.filter(
    (option) => option !== undefined,
  ) as Array<IGeneralFormSelectableInputsOptions>;

  return filteredSimulatorsTypesInputOptions;
};
