import { UseFieldArrayRemove } from 'react-hook-form';
import { IManagementContractPayload } from '@contexts/management-contract/types';
import { GenericFormField } from '../../fields/generic-form-field';
import { NumericFormField } from '../../fields/numeric-form-field';

interface ISimulatorFormSignatorySection {
  children?: JSX.Element;
  equalDemandAsDefault?: boolean;
  fieldsController?: Record<string, boolean>;
  removeCustomerSignatory?: UseFieldArrayRemove;
  unitary?: boolean;
  customerSignatoryNum: number;
  customerSignatoryLength?: number;
  unifiedConsumption?: boolean;
}

interface IContractCustomerGeneralSubsection extends ISimulatorFormSignatorySection {
  signatoryNickName?: string;
}

export function ContractCustomerGeneralSubsection({ customerSignatoryNum }: IContractCustomerGeneralSubsection) {
  return (
    <>
      <GenericFormField<IManagementContractPayload>
        id={`inputs.signatories.customerSignatories.${customerSignatoryNum}.fullName`}
        field={`inputs.signatories.customerSignatories.${customerSignatoryNum}.fullName`}
        tooltipContent="Nome completo do representante da Clarke, quem irá assinar o contrato."
        label="Nome do Responsável"
      />
      <NumericFormField<IManagementContractPayload>
        id={`inputs.signatories.customerSignatories.${customerSignatoryNum}.identityNumber`}
        field={`inputs.signatories.customerSignatories.${customerSignatoryNum}.identityNumber`}
        label="N° de Identidade do Responsável"
        formatProps={{ format: '##.###.###-#', isNumericString: true }}
      />
      <NumericFormField<IManagementContractPayload>
        id={`inputs.signatories.customerSignatories.${customerSignatoryNum}.cpfNumber`}
        field={`inputs.signatories.customerSignatories.${customerSignatoryNum}.cpfNumber`}
        label="CPF do Responsável"
        formatProps={{ format: '###.###.###-##', isNumericString: true }}
      />
      <GenericFormField<IManagementContractPayload>
        id={`inputs.signatories.customerSignatories.${customerSignatoryNum}.email`}
        field={`inputs.signatories.customerSignatories.${customerSignatoryNum}.email`}
        label="E-mail do Responsável"
      />
    </>
  );
}
