import { useState, useEffect } from 'react';
import { Modal } from '@clarke-energia/foton';

import { useAuth } from '@src/auth-wrapper';
import useWhiteLabel from '@hooks/use-white-label';
import { WhiteLabelSupplierStatusEnum } from '@schemas/white-label/types';

import {
  GENERAL_LIST_PATH,
  CLARKE_MANAGEMENT_PATH,
  WHITE_LABEL_PATH,
  UPSERT_SUPPLIERS_PROJECTION_PATH,
  HOME_PATH,
  GENERAL_SUPPLIERS_PATH,
} from '@routers/constants';

import ProductOneSimulatorForm from './product-one-simulator-form';

const ProductOneSimulator = (): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(true);
  const [openModalWhiteLabel, setOpenModalWhiteLabel] = useState<boolean>(false);
  const [openModalMissingWhiteLabel, setOpenModalMissingWhiteLabel] = useState<boolean>(false);
  const { whiteLabelSupplier, getWhiteLabelSupplierByUserId, isLoading, isUserMissingSupplier } = useWhiteLabel();
  const { user } = useAuth();

  useEffect(() => {
    user?.isWhiteLabel && getWhiteLabelSupplierByUserId(user.id);
  }, [user]);

  useEffect(() => {
    if (
      whiteLabelSupplier &&
      whiteLabelSupplier?.status === WhiteLabelSupplierStatusEnum.INCOMPLETE_REGISTRATION &&
      !isLoading
    ) {
      setOpenModalWhiteLabel(true);
    } else if (user?.isWhiteLabel && isUserMissingSupplier) {
      setOpenModalMissingWhiteLabel(true);
    }
  }, [whiteLabelSupplier, isLoading]);

  const handleCompleteRegistrationWhiteLabel = () => {
    setOpenModalWhiteLabel(false);
    location.href = `${WHITE_LABEL_PATH}/${GENERAL_SUPPLIERS_PATH}${UPSERT_SUPPLIERS_PROJECTION_PATH}/${whiteLabelSupplier?.id}`;
  };
  if (openModalMissingWhiteLabel) {
    return (
      <Modal
        open={openModalMissingWhiteLabel}
        setOpen={() => (location.href = HOME_PATH)}
        title="Não existe fornecedora vinculada a esse usuário"
        id="missing-white-label-for-user-modal"
        description="Para criar propostas, é necessário estar vinculado a uma fornecedora."
      />
    );
  }
  if (openModalWhiteLabel) {
    return (
      <Modal
        open={openModalWhiteLabel}
        setOpen={() => (location.href = HOME_PATH)}
        title="Complete o cadastro da sua fornecedora"
        id="incomplete-registration-modal"
        description="Para criar propostas, é necessário ter um cadastro completo da fornecedora."
        modalButtons={{
          primary: {
            label: 'Completar cadastro',
            onClick: handleCompleteRegistrationWhiteLabel,
          },
        }}
      />
    );
  }
  return (
    <>
      <ProductOneSimulatorForm {...{ setSuccessModal, setOpenModal, isLoading }} />

      <Modal
        open={openModal}
        setOpen={() => setOpenModal((previous) => !previous)}
        title={successModal ? 'Sucesso' : 'Houve um problema...'}
        id="modal-product-one-result"
        description={
          successModal
            ? 'O formulário foi enviado com sucesso. Estamos calculando os possíveis cenários e em instantes os resultados estarão na listagem de propostas Clarke Gestão.'
            : 'Houve um problema ao enviar os dados. Verifique o formulário e tente novamente. Caso persista, contate o setor responsável.'
        }
        modalButtons={{
          primary: {
            label: successModal ? 'Ir para lista' : 'Fechar',
            onClick: () =>
              successModal ? (location.href = `${CLARKE_MANAGEMENT_PATH}/${GENERAL_LIST_PATH}`) : setOpenModal(false),
          },
        }}
      />
    </>
  );
};

export default ProductOneSimulator;
