import React from 'react';

import { IPaginatedResponseBase, IProposalLead, SimulatorTypeEnum } from '@schemas/types';
import useLeads from '@hooks/use-leads';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { getCalculatorName, isStringEmpty, transformObjectKeysToCamelCase } from '@utils/common';
import debounce from '@utils/debounce';

import { useAuth } from '@src/auth-wrapper';

export type ILeadsPaginated = IPaginatedResponseBase<IProposalLead>;

export function useLeadsHistory<T>(simulatorType: keyof typeof SimulatorTypeEnum) {
  const { getLeads } = useLeads();
  const { user } = useAuth();

  const [loading, setLoading] = React.useState(true);
  const [searchText, setSearchText] = React.useState<string>();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [leadList, setLeadList] = React.useState<ILeadsPaginated>();

  const sellerId = user?.isCommercialDealer ? user.id : undefined;

  const parseLeadList = (leadList: Record<string, any>): ILeadsPaginated => {
    return transformObjectKeysToCamelCase(leadList) as ILeadsPaginated;
  };

  const fetchData = (searchText?: string, currentPage?: number) => {
    setLoading(true);
    getLeads(currentPage, searchText, true, simulatorType, sellerId)
      .then((response) => {
        const parsedLeadList = parseLeadList(response.data.data);
        setLeadList(parsedLeadList);
      })
      .finally(() => setLoading(false));
  };

  const debouncedFetchData = React.useCallback(
    debounce((searchText?: string, currentPage?: number) => {
      fetchData(searchText, currentPage);
    }, 500),
    [],
  );

  React.useEffect(() => {
    if (!isStringEmpty(searchText)) {
      setCurrentPage(1);
    }

    debouncedFetchData(searchText, currentPage);
  }, [searchText, currentPage, debouncedFetchData]);

  React.useEffect(() => {
    eventTracker.trackEvent(eventLabels.PAGE_VIEW_HISTORY, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  }, []);

  return {
    setSearchText,
    loading,
    leadList,
    setCurrentPage,
    fetchData,
  };
}
