import React from 'react';
import { UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';

import { ISimulationPayloadBase, SimulatorTypeEnum } from '@schemas/types';

import { SimulatorCompetitorGeneralSubsection } from '@components/atoms/form/simulation-competitor-section/simulator-competitor-general-subsection';
import { SimulatorCompetitorEnergyPriceSubsection } from '@components/atoms/form/simulation-competitor-section/simulator-competitor-energy-price-subsection';

export interface ISimulatorFormCompetitorsListItem {
  fieldsController?: Record<string, boolean>;
  removeCompetitor?: UseFieldArrayRemove;
  appendCompetitor?: UseFieldArrayAppend<ISimulationPayloadBase, 'competitorsList'>;
  competitorIndex: number;
  competitorsNumber?: number;
  simulatorType?: keyof typeof SimulatorTypeEnum;
}

function SimulatorFormCompetitorsListItem({
  fieldsController,
  competitorIndex,
  removeCompetitor,
  appendCompetitor,
  competitorsNumber,
  simulatorType,
}: ISimulatorFormCompetitorsListItem) {
  const isNotClarkeCompetitor = competitorIndex !== 0;
  return (
    <>
      <SimulatorCompetitorGeneralSubsection
        {...{
          competitorIndex,
          fieldsController,
          removeCompetitor,
          appendCompetitor,
          competitorsNumber,
          simulatorType,
          isNotClarkeCompetitor,
        }}
      />
      {isNotClarkeCompetitor && <SimulatorCompetitorEnergyPriceSubsection {...{ competitorIndex, fieldsController }} />}
    </>
  );
}

export default SimulatorFormCompetitorsListItem;
