import { IManagementContract, IManagementContractPayload } from '@contexts/management-contract/types';
import { transformObjectKeysToSnakeCase } from '@utils/common';
import { parseMultipartPayload } from '@utils/form-data';

const additionalServicesKeysIndexer: Record<string, any> = {
  changeOfOwnership: 'CHANGE_OF_OWNERSHIP',
  demandAdjustment: 'DEMAND_ADJUSTMENT',
  tariffModalityChange: 'TARIFF_MODALITY_CHANGE',
};

export const parseAdditionalServicesResponse = (response: any): void => {
  const additionalServices: { [key: string]: boolean | Record<string, any> | undefined } = {
    changeOfOwnership: undefined,
    demandAdjustment: undefined,
    tariffModalityChange: undefined,
  };
  response.additionalServices &&
    response.additionalServices.map((service: Record<string, any>) => {
      Object.entries(additionalServicesKeysIndexer).forEach(([key, value]) => {
        if (value == service) {
          additionalServices[key] = true;
        }
      });
    });

  response.additionalServices = additionalServices;

  return response;
};

const _parseAdditionalServicesPayload = (payload: IManagementContractPayload | IManagementContract): void => {
  const checkboxValues = payload.additionalServices;
  const tranformObjToArray =
    checkboxValues &&
    Object.entries(checkboxValues)
      .filter(([_, value]) => value)
      .map(([key]) => additionalServicesKeysIndexer[key] as keyof IManagementContractPayload['additionalServices']);

  payload.additionalServices = tranformObjToArray as IManagementContractPayload['additionalServices'];
};

const _cleanUndefinedProperties = (obj: any) => {
  Object.keys(obj).forEach(function (key) {
    if (typeof obj[key] === 'undefined') {
      delete obj[key];
    }
  });
  return obj;
};

const _cleanCustomerSignatories = (draft: any) => {
  const customerSignatories = draft.inputs?.signatories?.customerSignatories;
  if (
    customerSignatories &&
    !(
      customerSignatories[0].fullName ||
      customerSignatories[0].cpfNumber ||
      customerSignatories[0].email ||
      customerSignatories[0].identityNumber
    )
  ) {
    delete draft.inputs?.signatories?.customerSignatories;
  }
  return;
};

export const parseContractPayload = (payload: IManagementContractPayload) => {
  const cleanedPayload = { ...payload };

  _cleanCustomerSignatories(cleanedPayload);
  _cleanUndefinedProperties(cleanedPayload);
  _parseAdditionalServicesPayload(cleanedPayload);

  const snakedCasePayload = transformObjectKeysToSnakeCase(cleanedPayload);
  const parsedPayload = parseMultipartPayload(snakedCasePayload, ['attachments']);

  return parsedPayload;
};
