import { transformObjectKeysToCamelCase, transformObjectKeysToSnakeCase } from '@utils/common';
import { IWhiteLabelSupplierPayload } from './type';
import { parseMultipartPayload } from '@utils/form-data';

export const parseWhiteLabelSupplier = (
  whiteLabelSupplierSupplierRegistration: Record<string, any>,
): IWhiteLabelSupplierPayload => {
  return transformObjectKeysToCamelCase(whiteLabelSupplierSupplierRegistration) as IWhiteLabelSupplierPayload;
};

export const parseWhiteLabelSuppliers = (
  whiteLabelSupplierSupplierRegistration: Record<string, any>,
): Array<IWhiteLabelSupplierPayload> => {
  return transformObjectKeysToCamelCase(whiteLabelSupplierSupplierRegistration) as IWhiteLabelSupplierPayload[];
};

export const parseWhiteLabelSupplierPayload = (payload: IWhiteLabelSupplierPayload | IWhiteLabelSupplierPayload) => {
  const cleanedPayload = { ...payload };

  const snakedCasePayload = transformObjectKeysToSnakeCase(cleanedPayload);
  const parsedPayload = parseMultipartPayload(snakedCasePayload, ['file_list']);
  return parsedPayload;
};

export const parseDataWhiteLabelSupplier = (whiteLabelSupplierSupplierData: IWhiteLabelSupplierPayload) => {
  const result = transformObjectKeysToSnakeCase(whiteLabelSupplierSupplierData);
  return result;
};
