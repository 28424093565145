import { IWhiteLabelSupplierPayload, IWhiteLabelSupplierPresentation } from '@schemas/white-label/types';
import { GenericFormField } from '../fields/generic-form-field';
import { NumericFormField } from '../fields/numeric-form-field';
import { isValidEmail } from '@utils/common';

enum presentationEnum {
  name = 'Nome da Empresa',
  phone = 'Telefone',
  email = 'Email',
}

export function RenderSupplierPresentation({
  presentation,
  required,
}: {
  presentation: Omit<IWhiteLabelSupplierPresentation, 'logoUrl'>;
  required: boolean;
}) {
  return (
    <>
      {Object.keys(presentation).map((key) => {
        const presentationKey = key as keyof Omit<IWhiteLabelSupplierPresentation, 'logoUrl'>;
        const fieldOptions: any = {
          required: { value: required, message: 'Campo é obrigatório' },
        };
        if (presentationKey === 'email') {
          fieldOptions.validate = (value: string) => {
            if (value === '') {
              return 'Campo é obrigatório';
            } else if (!isValidEmail(value)) {
              return 'Email inválido';
            }
            return true;
          };
        } else if (presentationKey === 'phone') {
          fieldOptions.validate = (value: string) => {
            if (value === '') {
              return 'Campo é obrigatório';
            } else if ((value as string).length !== 11) {
              return 'Telefone inválido';
            }
            return true;
          };
        } else {
          fieldOptions.validate = (value: string) => {
            if (value === '') {
              return 'Campo é obrigatório';
            } else if ((value as string).length < 2) {
              return 'Nome da Empresa deve ter no mínimo 2 caracteres';
            }
            return true;
          };
        }

        if (presentationKey === 'phone') {
          return (
            <div key={`white-label-supplier-presentation-${presentationKey}`} className="xl:min-h-13">
              <NumericFormField<IWhiteLabelSupplierPayload>
                label={presentationEnum[presentationKey as keyof typeof presentationEnum]}
                field={`presentation.${presentationKey}`}
                id={`presentation.${presentationKey}`}
                formatProps={{ format: '(##) #####-####', isNumericString: true }}
                options={fieldOptions}
              />
            </div>
          );
        } else {
          return (
            <div key={`white-label-supplier-presentation-${presentationKey}`} className="xl:min-h-13">
              <GenericFormField<IWhiteLabelSupplierPayload>
                label={presentationEnum[presentationKey as keyof typeof presentationEnum]}
                placeholder={presentationEnum[presentationKey as keyof typeof presentationEnum]}
                field={`presentation.${presentationKey}`}
                data-cy={`presentation.${presentationKey}`}
                id={`presentation.${presentationKey}`}
                options={fieldOptions}
              />
            </div>
          );
        }
      })}
    </>
  );
}
