import { IWhiteLabelSupplier } from '@schemas/white-label/types';

export function SuppliersProjectionsHistoryTableResponsibleColumn({
  suppliersProjection,
}: {
  suppliersProjection: IWhiteLabelSupplier;
}) {
  return (
    <td className="text-paragraph-small sm:text-paragraph-medium 2xl:text-paragraph-large col-span-4 items-center self-center flex flex-nowrap gap-4 w-full text-start h-full">
      {suppliersProjection.presentation?.name ? (
        <label className="text-paragraph-small">{suppliersProjection.presentation?.name}</label>
      ) : (
        <label>Fornecedora não identificada</label>
      )}
    </td>
  );
}
