import { z } from 'zod';
import { AxiosPromise } from 'axios';
import * as schema from '.';

type ISimulatorLabel = 'Clarke Gestão' | 'Clarke Varejo' | 'Demanda Contratada' | 'White-Label';

export enum SimulatorTypeEnum {
  CLARKE_MANAGEMENT,
  CLARKE_RETAIL,
  CONTRACTED_DEMAND,
  WHITE_LABEL,
}

export enum ContractGeneratorTypeEnum {
  PRODUCT_ONE = 'Clarke Gestão',
  PRODUCT_TWO = 'Clarke Varejo',
}

export enum ProductTypeEnum {
  ACL_RETAILER = 'Varejista',
  ACL_WHOLESALER = 'Atacadista',
}

export enum GuaranteeTypeEnum {
  NO_GUARANTEE = 'Sem garantia',
  CONTRACTUAL_INSURANCE = 'Seguro Contratual',
  BANK_GUARANTEE = 'Fiança Bancária',
  SECURITY_DEPOSIT = 'Depósito Caução',
}

export enum ManagementTerminationFineEnum {
  THREE_MONTHS_NOTICE = '3 meses de aviso prévio',
  SIX_MONTHS_NOTICE = '6 meses de aviso prévio',
  THIRTY_PERCENT_OF_THE_CONTRACT_RESIDUAL = '30% do residual do contrato',
  FIFTY_PERCENT_OF_THE_CONTRACT_RESIDUAL = '50% do residual do contrato',
  HAS_A_FINE = 'Possui multa',
  NO_FINE = 'Não possui multa',
}

export enum ManagerTypeEnum {
  INDEPENDENT_MANAGER = 'Gestora independente',
  SALES_MANAGER = 'Gestora comercializadora',
}

export enum ChargesCoverageTypeEnum {
  FULL_COVERAGE_FOR_ENERGY_CONTRACT = 'Cobertura total pelo contrato de energia',
  FULL_PASS_THROUGH_OF_COSTS_TO_THE_CUSTOMER = 'Repasse integral dos custos ao cliente',
}

export enum ChargesCoverageTypeForManualAclPricesEnum {
  true = 'Cobertura total pelo contrato de energia',
  false = 'Repasse integral dos custos ao cliente',
}

export enum AclTypeEnum {
  FIXED_PRICE = 'Preço Fixo',
  GUARANTEED_DISCOUNT = 'Desconto Garantido',
}

export enum LowTensionTypeEnum {
  OPTING = 'BT Optante',
  STANDARD = 'BT Padrão',
}

export const getSimulatorLabel = (simulatorType: keyof typeof SimulatorTypeEnum) => {
  return simulatorLabelIndexer[simulatorType];
};

export const simulatorLabelIndexer: Record<keyof typeof SimulatorTypeEnum, ISimulatorLabel> = {
  CLARKE_MANAGEMENT: 'Clarke Gestão',
  CLARKE_RETAIL: 'Clarke Varejo',
  CONTRACTED_DEMAND: 'Demanda Contratada',
  WHITE_LABEL: 'White-Label',
};

export interface IProposalBase<
  IInputs extends ISimulationPayloadBase,
  ICalculatorMetadata extends ISimulationBase<ISimulationPayloadBase> | Record<string, any>,
> extends IProposalLinkedUsers {
  id: string;
  lead: IProposalLead;
  createdAt: string;
  deletedAt?: string;
  inputs: IInputs;
  calculatorMetadata: ICalculatorMetadata;
}

export const simulatorTypeEnum = z
  .enum(['CLARKE_MANAGEMENT', 'CLARKE_RETAIL', 'CONTRACTED_DEMAND', 'WHITE_LABEL'], {
    required_error: 'Tipo de multa é obrigatório',
  })
  .nullable()
  .optional();

export const contractGeneratorTypeEnum = z
  .enum(['PRODUCT_ONE', 'PRODUCT_TWO'], {
    required_error: 'Tipo de multa é obrigatório',
  })
  .nullable()
  .optional();
export const productTypeEnum = z
  .enum(['ACL_RETAILER', 'ACL_WHOLESALER'], {
    required_error: 'Tipo de produto é obrigatório',
  })
  .nullable()
  .optional();
export const guaranteeTypeEnum = z
  .enum(['NO_GUARANTEE', 'CONTRACTUAL_INSURANCE', 'BANK_GUARANTEE', 'SECURITY_DEPOSIT'], {
    required_error: 'Tipo de garantia é obrigatório',
  })
  .nullable()
  .optional();
export const managementTerminationFineEnum = z
  .enum([
    'THREE_MONTHS_NOTICE',
    'SIX_MONTHS_NOTICE',
    'THIRTY_PERCENT_OF_THE_CONTRACT_RESIDUAL',
    'FIFTY_PERCENT_OF_THE_CONTRACT_RESIDUAL',
    'HAS_A_FINE',
    'NO_FINE',
  ])
  .nullable()
  .optional();
export const managerTypeEnum = z
  .enum(['INDEPENDENT_MANAGER', 'SALES_MANAGER'], {
    required_error: 'Tipo de multa é obrigatório',
  })
  .nullable()
  .optional();
export const chargesCoverageTypeEnum = z
  .enum(['FULL_COVERAGE_FOR_ENERGY_CONTRACT', 'FULL_PASS_THROUGH_OF_COSTS_TO_THE_CUSTOMER'], {
    required_error: 'Tipo de multa é obrigatório',
  })
  .nullable()
  .optional();
export const chargesCoverageTypeForManualAclPricesEnum = z
  .union([z.literal('true'), z.literal('false'), z.boolean()])
  .nullable()
  .optional();
export const aclTypeEnum = z
  .enum(['FIXED_PRICE', 'GUARANTEED_DISCOUNT'], {
    required_error: 'Tipo de multa é obrigatório',
  })
  .nullable()
  .optional();
export const lowTensionTypeEnum = z
  .enum(['OPTING', 'STANDARD'], {
    required_error: 'Tipo de multa é obrigatório',
  })
  .nullable()
  .optional();
export const RiskTypeEnum = z
  .enum(['CONSERVATIVE', 'BOLD'], {
    required_error: 'Tipo de risco é obrigatório',
  })
  .nullable()
  .optional();

export type IAclPrice = z.infer<typeof schema.AclPriceSchema>;
export type IAuxiliaryFields = z.infer<typeof schema.AuxiliaryFieldsSchema>;
export type ICustomFee = z.infer<typeof schema.CustomFeeSchema>;
export type ICustomFeeAuxiliarFields = z.infer<typeof schema.CustomFeeAuxiliarFieldsSchema>;
export type IDiscountInput = z.infer<typeof schema.DiscountInputSchema>;
export type IDeleteProposalsByIds = (leadId: string, proposalId: Array<string>) => AxiosPromise<IResponseBase>;
export type IIRecCertification = z.infer<typeof schema.IRecCertificationSchema>;
export type IMigrationCost = z.infer<typeof schema.MigrationCostSchema>;
export type IProposalUser = z.infer<typeof schema.ProposalUserSchema>;
export type IProposalLinkedUsers = z.infer<typeof schema.ProposalLinkedUsersSchema>;
export type IOperationHoursRangeSet = z.infer<typeof schema.OperationHoursRangeSetSchema>;
export type IOperationHoursRange = z.infer<typeof schema.OperationHoursRangeSchema>;
export type ISimulationUnitPayloadBase = z.infer<typeof schema.SimulationUnitPayloadBaseSchema>;
export type ISimulationCompetitorPayloadBase = z.infer<typeof schema.SimulationCompetitorPayloadBaseSchema>;
export type ISimulationPayloadBase = z.infer<typeof schema.simulationPayloadBaseSchema>;
export type ISimulationResultBase = z.infer<typeof schema.SimulationResultBaseSchema>;
export type ISimulationExtendedResultBase = z.infer<typeof schema.SimulationExtendedResultBaseSchema>;
export type ISimulationResultBaseUnit = z.infer<typeof schema.SimulationResultBaseUnitSchema>;
export type ITargetYears = z.infer<typeof schema.TargetYearsSchema>;
export type IProposalLead = z.infer<typeof schema.proposalLeadSchema>;
export type IProposalsAggregatedByLeadBase = z.infer<typeof schema.proposalsAggregatedByLeadBaseSchema>;
export type ISimulationTariffFlagScenarioBase = z.infer<typeof schema.simulationTariffFlagScenarioBaseSchema>;
export type ISimulationTariffFlagScenariosBase = z.infer<typeof schema.simulationTariffFlagScenariosBaseSchema>;
export type IContractUnitPayloadBase = z.infer<typeof schema.contractUnitPayloadBaseSchema>;
export type IContractPayloadBase = z.infer<typeof schema.contractPayloadBaseSchema>;
export type IContractBase = z.infer<typeof schema.contractBaseSchema>;
export type IDiscountFreeServiceProposalPrices = z.infer<typeof schema.discountFreeServiceProposalPricesSchema>;
export type ISimulationReportBase = z.infer<typeof schema.simulationReportBaseSchema>;
export type IUpdateSellerPayload = z.infer<typeof schema.updateSellerPayloadSchema>;
export type ISampleMonthSimulationReport = z.infer<typeof schema.sampleMonthSimulationReportSchema>;
export type ISampleMonthSimulationMarketBase = z.infer<typeof schema.sampleMonthSimulationMarketBaseSchema>;
export type ISampleMonthSimulationCurrentMarket = z.infer<typeof schema.sampleMonthSimulationCurrentMarketSchema>;
export type ISampleMonthSimulationTargetMarket = z.infer<typeof schema.sampleMonthSimulationTargetMarketSchema>;
export type ISampleMonthSimulation = z.infer<typeof schema.sampleMonthSimulationSchema>;
export type ISimulationTariff = z.infer<typeof schema.simulationTariffSchema>;
export type ISimulationPremisesGeneral = z.infer<typeof schema.simulationPremisesGeneralSchema>;
export type ISimulationPremisesIcms = z.infer<typeof schema.simulationPremisesIcmsSchema>;
export type ISimulationPremisesEdcInfoFPGDRelation = z.infer<typeof schema.simulationPremisesEdcInfoFPGDRelationSchema>;
export type ISimulationPremisesEdcInfo = z.infer<typeof schema.simulationPremisesEdcInfoSchema>;
export type ISimulationPremisesAclPrices = z.infer<typeof schema.simulationPremisesAclPricesSchema>;
export type ISimulationPremisesTariffEdcInfo = z.infer<typeof schema.simulationPremisesTariffEdcInfoSchema>;
export type ISimulationPremisesTariff = z.infer<typeof schema.simulationPremisesTariffSchema>;
export type ISimulationPremises = z.infer<typeof schema.simulationPremisesSchema>;
export type IResponseBase = z.infer<typeof schema.responseBaseSchema>;
export type IPaginatedResponseBase = z.infer<typeof schema.paginatedResponseBaseSchema>;
export type IProposalEconomyProjectionTableData = z.infer<typeof schema.proposalEconomyProjectionTableDataSchema>;
export type IGeneralTitleAndValuePair = z.infer<typeof schema.generalTitleAndValuePairSchema>;
export type ICommercialDealerComission = z.infer<typeof schema.commercialDealerComissionSchema>;
export type LatestProposalMetadata = z.infer<typeof schema.latestProposalMetadataSchema>;
export type ISimulatorIndexedMetadata = z.infer<typeof schema.simulatorIndexedMetadataSchema>;
export type ILeadSimulatorsIndexedMetadata = z.infer<typeof schema.leadSimulatorsIndexedMetadataSchema>;
export type IProposalCommercialStage = 'approved' | 'waitingForApproval';
export type IFeedbackNotificationProps = z.infer<typeof schema.FeedbackNotificationPropsSchema>;
export type ILeadProposalsHubHistoryBase = z.infer<typeof schema.ILeadProposalsHubHistoryBaseSchema>;
export type ILeadProposalsHubBase = z.infer<typeof schema.leadProposalsHubBaseSchema>;
export type IProposalPreviewBodyBase = z.infer<typeof schema.proposalPreviewBodyBaseSchema>;

export interface ISimulationBase<ISimulationInputs> {
  aclType: keyof typeof AclTypeEnum;
  lead: IProposalLead;
  inputs: ISimulationInputs;
  pricesExpirationDate?: string;
}
