import { clone } from 'lodash';

import { ISimulationUnitPayloadBase, SimulatorTypeEnum } from '@schemas/types';

export class SimulatorsInputsTranslator {
  inputs: Record<string, any>;
  translatedInputs: Record<string, any>;
  suplementaryData?: Record<string, any>;

  constructor(inputs: Record<string, any>, suplementaryData?: Record<string, any>) {
    this.inputs = clone(inputs);
    this.translatedInputs = clone(inputs);
    this.suplementaryData = suplementaryData;
  }

  translate = (simulatorType: keyof typeof SimulatorTypeEnum): Record<string, any> => {
    const translatorFunctionIndexer: Record<keyof typeof SimulatorTypeEnum, () => Record<string, any>> = {
      CLARKE_MANAGEMENT: this.toProductOne,
      CLARKE_RETAIL: this.toProductTwo,
      CONTRACTED_DEMAND: this.toContractedDemand,
      WHITE_LABEL: this.toProductOne,
    };

    return translatorFunctionIndexer[simulatorType]();
  };

  toProductOne = (newTargetYears?: Array<number>) => {
    this.translatedInputs = clone(this.inputs);

    this._cleanProductTwoRelatedData();
    this._convertTargetYearsToMultiple(newTargetYears);
    this._cleanLegacyData();

    return this.translatedInputs;
  };

  toProductTwo = () => {
    this.translatedInputs = clone(this.inputs);

    this._cleanLegacyData();
    return this.translatedInputs;
  };

  toContractedDemand = () => {
    this._cleanLegacyData();
    return this.translatedInputs;
  };

  _convertTargetYearsToUnique = (newTargetYear?: number) => {
    const parsedNewTargetYear =
      newTargetYear ?? this.translatedInputs.targetYear ?? this.translatedInputs.targetYears[0] ?? '';
    delete this.translatedInputs.targetYears;
    this.translatedInputs.targetYear = parsedNewTargetYear;
  };

  _convertTargetYearsToMultiple = (newTargetYears?: Array<number>) => {
    const parsedNewTargetYears = newTargetYears ??
      this.translatedInputs.targetYears ?? [this.translatedInputs.targetYear];

    delete this.translatedInputs.targetYear;
    this.translatedInputs.targetYears = parsedNewTargetYears;
  };

  _convertUnitToUnits = () => {
    if (this.inputs.unit) {
      const parsedUnitToUnits = this.inputs.unit;
      this.inputs.units = [parsedUnitToUnits];
      delete this.inputs.unit;
    }
  };

  _cleanProductTwoRelatedData = () => {
    delete this.translatedInputs.retailTradersPrices;
    delete this.translatedInputs.state;
    this.translatedInputs.units.forEach((unit: ISimulationUnitPayloadBase) => {
      delete unit.targetVolumeGenerated;
      delete unit.injectedEnergyOffPeak;
      delete unit.injectedEnergyPeak;
      delete unit.distributedGenerationMonthlyCost;
    });
    delete this.translatedInputs.validPrices;
    delete this.translatedInputs.customFee;
    delete this.translatedInputs.customTaxes;
  };

  _cleanLegacyData = () => {
    delete this.translatedInputs.retailTradersPrices;
    delete this.translatedInputs.state;
    this.translatedInputs.units.forEach((unit: ISimulationUnitPayloadBase) => {
      // @ts-expect-error -> legacy field
      delete unit.solarPlantInOperation;
      // @ts-expect-error -> legacy field
      delete unit.manualAcrContractedDemandPeak;
      // @ts-expect-error -> legacy field
      delete unit.manualAcrContractedDemandOffPeak;
      // @ts-expect-error -> legacy field
      delete unit.manualAclContractedDemandPeak;
      // @ts-expect-error -> legacy field
      delete unit.manualAclContractedDemandOffPeak;
      // @ts-expect-error -> legacy field
      delete unit.manualConsumptionOffPeak;
      // @ts-expect-error -> legacy field
      delete unit.manualConsumptionPeak;
      // @ts-expect-error -> legacy field
      delete unit.totalMigrationCost;

      const legacySupplyStartDate = unit.supplyStartDate;
      unit.supplyStartDate = legacySupplyStartDate;

      delete unit.supplyStartDate;
    });
  };

  _cleanAuxiliaryInputs = () => {
    delete this.translatedInputs.hasManualDiscounts;
    delete this.translatedInputs.aux__useManualAclPrice;
  };
}
