import { ColumnOptions, ThreeDotsMenu, ThreeDotsMenuProps } from '@clarke-energia/foton';

import { IProposalLead, SimulatorTypeEnum } from '@schemas/types';

import { columnsIndexer } from './helper';

export function getProposalsHistoryTableColumns(
  getMoreOptionsButtons: (leadId: string) => ThreeDotsMenuProps['menuOptions'],
  getTableColumns: Array<string>,
  simulatorType: keyof typeof SimulatorTypeEnum,
): Array<ColumnOptions<IProposalLead>> {
  const proposalsHistoryTableColumns: Array<ColumnOptions<IProposalLead>> = [];

  getTableColumns.map((tableColumn) => proposalsHistoryTableColumns.push(columnsIndexer(simulatorType)[tableColumn]));

  proposalsHistoryTableColumns.push({
    isClickDisabled: true,
    renderAsElement: (entry) => {
      const [leadId] = [entry.id];
      return <ThreeDotsMenu extraClasses="md:mr-8 lg:mr-10" menuOptions={getMoreOptionsButtons(leadId)} />;
    },
  });

  return proposalsHistoryTableColumns;
}
