import { twMerge } from 'tailwind-merge';
import { HeroIconSolidOrOutline } from '@components/atoms/form/hero-icon';
import { IProductOneUnitReport } from '@contexts/product-one/types';
import { IProductTwoUnitReport } from '@schemas/product-two/types';
import { ISimulationResultBase } from '@schemas/types';
import {
  ICurrentMarket,
  IPremises,
  IPriceListComposition,
  ISampleMonth,
  ITargetMarket,
  IUnitReport,
} from '@pages/detailed-analysis/types';
import { formatDate } from '@utils/datetime';
import { formatNumber } from '@utils/numeral';
import intervalIcon from '@assets/newProposal/image (5).png';
const monthsListDescription = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const getCurrentMarketUnitTableDataMediumVoltage = (unitReport: IUnitReport) => {
  const currentMarketUnitTableDataMediumVoltage = [
    {
      label: 'Demanda Ponta (kW)',
      value: formatNumber(unitReport.premises.acrContractedDemandPeak as number, '0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.acrContractedDemandPeak',
    },
    {
      label: 'Demanda Ponta (R$/kW)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.tariff.demandPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.demandPeak',
    },
    {
      label: 'Total Demanda Ponta (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.demandPeak as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.demandPeak',
    },
    {
      label: 'Demanda Fora Ponta (kW)	',
      value: formatNumber(unitReport.premises.acrContractedDemandOffPeak as number, '0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.acrContractedDemandOffPeak',
    },
    {
      label: 'Demanda Fora Ponta (R$/kW)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.tariff.demandOffPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.demandOffPeak',
    },
    {
      label: 'Total Demanda Fora Ponta (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.demandOffPeak as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.demandOffPeak',
    },
    {
      label: 'TUSD Ponta (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.consumptionPeak as number, '0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionPeak',
    },
    {
      label: 'TUSD Ponta (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffPeak as number,
        ' 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffPeak',
    },
    {
      label: 'Total TUSD Ponta (R$)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.distributionTariffPeakCost as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.distributionTariffPeakCost',
    },
    {
      label: 'TUSD Fora Ponta (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.consumptionOffPeak as number, '0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionOffPeak',
    },
    {
      label: 'TUSD Fora Ponta (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffOffPeak as number,
        '0,0.00',
      ),

      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffOffPeak',
    },
    {
      label: 'Total TUSD Fora Ponta (R$)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.distributionTariffOffPeakCost as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.distributionTariffOffPeakCost',
    },
    {
      label: 'TE Ponta (MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.consumptionPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionPeak',
    },
    {
      label: 'TE Ponta (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffPeak as number,
        ' 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffPeak',
    },
    {
      label: 'Total TE Ponta (R$)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.electricityTariffPeakCost as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.electricityTariffPeakCost',
    },
    {
      label: 'TE Fora Ponta (MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.consumptionOffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionOffPeak',
    },
    {
      label: 'TE Fora Ponta (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffOffPeak as number,
        ' 0,0.00',
      ),

      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffOffPeak',
    },
    {
      label: 'Total TE Fora Ponta (R$)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.electricityTariffOffPeakCost as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.electricityTariffOffPeakCost',
    },
    {
      label: 'Total custo gerador (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.generatorCost as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.generatorCost',
    },
    {
      label: 'Total Bandeiras Tarifárias',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.tariffFlag as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariffFlag',
    },
    {
      label: 'ICMS',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalWithIcms as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithIcms',
    },
    {
      label: 'PIS/COFINS',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalPisCofins as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalPisCofins',
    },
    {
      label: 'Total sem impostos (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalWithoutTaxes as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithoutTaxes',
    },
    {
      label: 'Total com impostos (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalWithTaxes as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithTaxes',
    },
  ];

  return currentMarketUnitTableDataMediumVoltage;
};

const getCurrentMarketUnitTableDataLowVoltage = (unitReport: IUnitReport) => {
  const currentMarketUnitTableDataLowVoltage = [
    {
      label: 'TUSD (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.consumptionLowVoltage as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionLowVoltage',
    },
    {
      label: 'TUSD (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffLowVoltage as number,
        ' 0,0.00',
      ),

      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffLowVoltage',
    },
    {
      label: 'Total TUSD (R$)	custo com TUSD',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.distributionTariffLowVoltageCost as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.distributionTariffLowVoltageCost',
    },
    {
      label: 'TE (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.consumptionLowVoltage as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionLowVoltage',
    },
    {
      label: 'TE (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffLowVoltage as number,
        ' 0,0.00',
      ),

      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffLowVoltage',
    },
    {
      label: 'Total TE (R$)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.electricityTariffLowVoltageCost as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.electricityTariffLowVoltageCost',
    },
    {
      label: 'bandeira tarifária',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.tariffFlag as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariffFlag',
    },
    {
      label: 'ICMS',
      value: `${unitReport.sampleMonthSimulation.currentMarket.icms.percentageValue * 100} %`,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.icms.percentageValue',
    },
    {
      label: 'Valor Total de Consumo sem Impostos',
      value: unitReport.sampleMonthSimulation.currentMarket.rawTotalConsumptionCost,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.rawTotalConsumptionCost',
    },
    {
      label: 'Valor Total de Consumo com ICMS',
      value: unitReport.sampleMonthSimulation.currentMarket.totalWithIcms,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithIcms',
    },
    {
      label: 'PIS COFINS',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalPisCofins as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalPisCofins',
    },
    {
      label: 'Valor Total sem Taxas',
      value: unitReport.sampleMonthSimulation.currentMarket.totalWithoutTaxes,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithoutTaxes',
    },
    {
      label: 'Valor Total com Taxas',
      value: unitReport.sampleMonthSimulation.currentMarket.totalWithTaxes,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithTaxes',
    },
  ];

  return currentMarketUnitTableDataLowVoltage;
};

export const getCurrentMarketUnitTableData = (unitReport: IUnitReport) => {
  const currentMarketUnitTableData =
    unitReport.premises.tariff == 8 || unitReport.premises.tariff == 9
      ? getCurrentMarketUnitTableDataLowVoltage(unitReport)
      : getCurrentMarketUnitTableDataMediumVoltage(unitReport);

  return currentMarketUnitTableData;
};

export const getTargetMarketUnitTableData = (unitReport: IUnitReport) => {
  const targetMarketUnitTableData = [
    {
      label: 'Demanda Ponta (kW)	',
      value: formatNumber(unitReport.premises.aclContractedDemandPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.aclContractedDemandPeak',
    },
    {
      label: 'Demanda Ponta (R$/kW)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.tariff.demandPeak as number, ' 0,0.00'),

      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.tariff.demandPeak',
    },
    {
      label: 'Total Demanda Ponta (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.demandPeak, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.demandPeak',
    },
    {
      label: 'Demanda Fora Ponta (kW)	',
      value: formatNumber(unitReport.premises.aclContractedDemandOffPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.aclContractedDemandOffPeak',
    },
    {
      label: 'Demanda Fora Ponta (R$/kW)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.tariff.demandOffPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.tariff.demandOffPeak',
    },
    {
      label: 'Total Demanda Fora Ponta (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.demandOffPeak, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.demandOffPeak',
    },
    {
      label: 'TUSD Ponta (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.consumptionPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.consumptionPeak',
    },
    {
      label: 'TUSD Ponta (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.targetMarket.tariff.distributionTariffPeak as number,
        ' 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.tariff.distributionTariffPeak',
    },
    {
      label: 'Total TUSD Ponta (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.distributionTariffPeakCost, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionTariffPeakCost',
    },
    {
      label: 'TUSD Fora Ponta (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.consumptionOffPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.consumptionOffPeak',
    },
    {
      label: 'TUSD Fora Ponta (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.targetMarket.tariff.distributionTariffOffPeak as number,
        ' 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.tariff.distributionTariffOffPeak',
    },
    {
      label: 'Total TUSD Fora Ponta (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.distributionTariffOffPeakCost, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionTariffOffPeakCost',
    },
    {
      label: 'Desconto Demanda Ponta',
      value: unitReport.sampleMonthSimulation.targetMarket.demandPeakDiscount,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.demandPeakDiscount',
    },
    {
      label: 'Desconto Demanda Fora Ponta',
      value: unitReport.sampleMonthSimulation.targetMarket.demandOffPeakDiscount,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.demandOffPeakDiscount',
    },
    {
      label: 'Desconto TUSD Ponta',
      value: unitReport.sampleMonthSimulation.targetMarket.distributionTariffPeakDiscount,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionTariffPeakDiscount',
    },
    {
      label: 'ICMS distribuição',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.distributionIcms, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionIcms',
    },
    {
      label: 'ICMS eletricidade',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.electricityIcms, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.electricityIcms',
    },
    {
      label: 'ICMS',
      value: formatNumber(
        unitReport.sampleMonthSimulation.targetMarket.distributionIcms +
          unitReport.sampleMonthSimulation.targetMarket.electricityIcms,
        '$ 0,0.00',
      ),
      tooltipContent:
        'propriedade:  unitReport.sampleMonthSimulation.targetMarket.distributionIcms + unitReport.sampleMonthSimulation.targetMarket.electricityIcms	',
    },
    {
      label: 'PIS/COFINS',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.distributionPisCofins, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionPisCofins',
    },
    {
      label: 'Energia com perdas (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.electricityWithLosses as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.electricityWithLosses',
    },
    {
      label: 'Destaque PIS/COFINS',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.pisCofinsHighlight, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.pisCofinsHighlight',
    },
    {
      label: 'Total Energia (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.electricityTariffCost, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.electricityTariffCost',
    },
    {
      label: 'ESS/EER',
      value: formatNumber(
        unitReport.sampleMonthSimulation.targetMarket.securityAndModulationEnergyCharges as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.securityAndModulationEnergyCharges',
    },
    {
      label: 'Contribuição CCEE',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarketContributionCcee, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarketContributionCcee',
    },
    {
      label: 'Gestão Clarke',
      value: formatNumber(unitReport.premises.managementPrice, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.managementPrice',
    },
    {
      label: 'Total sem impostos (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.totalWithoutTaxes, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.totalWithoutTaxes',
    },
    {
      label: 'Total com impostos (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.totalWithTaxes, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.totalWithTaxes',
    },
  ];

  return targetMarketUnitTableData;
};

export const getEconomyUnitTableData = (unitReport: IUnitReport) => {
  const economyUnitTableData = [
    {
      label: 'Economia Total (R$)',
      value: formatNumber(unitReport?.sampleMonthSimulation?.report?.totalSavingWithTaxes, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.report.totalSavingWithTaxes',
    },
    {
      label: 'Economia Total %',
      value: formatNumber(unitReport?.sampleMonthSimulation?.report?.totalSavingInPercentageWithTaxes, '%'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.report.totalSavingInPercentageWithTaxes',
    },
  ];

  return economyUnitTableData;
};

export const getLowVoltageFinalTariffTableData = (unitReport: IUnitReport) => {
  if (!unitReport.lowVoltageFinalTariffsList) {
    return {
      headers: [],
      rows: [],
    };
  }
  const headers = [
    'Data de ajuste',
    ...unitReport.lowVoltageFinalTariffsList.map((item) => formatDate(item.tariffAdjustmentDate, 'DD/MM/YYYY')),
  ];
  const tagStyle = '';
  const dataRows = [
    {
      row: [
        'TUSD (MWh)',
        ...unitReport.lowVoltageFinalTariffsList.map(
          (item) => formatNumber(item.distributionTariffLowVoltage, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
    {
      row: [
        'TE (MWh)',
        ...unitReport.lowVoltageFinalTariffsList.map(
          (item) => formatNumber(item.electricityTariffLowVoltage, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
  ];
  return {
    headers,
    rows: dataRows,
  };
};

export const getMediumVoltageFinalTariffTableData = (unitReport: IUnitReport) => {
  if (!unitReport.mediumVoltageFinalTariffsList) {
    return {
      headers: [],
      rows: [],
    };
  }
  const headers = [
    'Data de ajuste',
    ...unitReport.mediumVoltageFinalTariffsList.map((item) => formatDate(item.tariffAdjustmentDate, 'DD/MM/YYYY')),
  ];
  const tagStyle = '';
  const dataRows = [
    {
      row: [
        'Demanda Ponta (R$/kW)',
        ...unitReport.mediumVoltageFinalTariffsList.map((item) => formatNumber(item.demandPeak ?? 0, '$ 0,0.00') || ''),
      ],
      tagStyle,
    },
    {
      row: [
        'Demanda Fora Ponta (R$/kW)',
        ...unitReport.mediumVoltageFinalTariffsList.map(
          (item) => formatNumber(item.demandOffPeak ?? 0, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
    {
      row: [
        'TUSD Ponta (R$/MWh)',
        ...unitReport.mediumVoltageFinalTariffsList.map(
          (item) => formatNumber(item.distributionTariffPeak ?? 0, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
    {
      row: [
        'TUSD Fora Ponta (R$/MWh)',
        ...unitReport.mediumVoltageFinalTariffsList.map(
          (item) => formatNumber(item.distributionTariffOffPeak ?? 0, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
    {
      row: [
        'TE Ponta (R$/MWh)',
        ...unitReport.mediumVoltageFinalTariffsList.map(
          (item) => formatNumber(item.electricityTariffPeak ?? 0, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
    {
      row: [
        'TE Fora Ponta (R$/MWh)',
        ...unitReport.mediumVoltageFinalTariffsList.map(
          (item) => formatNumber(item.electricityTariffOffPeak ?? 0, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
  ];

  return {
    headers,
    rows: dataRows,
  };
};

export const getPremissisesTableData = (unitReport: IUnitReport) => {
  const premissesTableData = [
    {
      label: 'Data de migração',
      // TODO after a month of implementation remove this conditional
      value: unitReport.premises.supplyStartDate
        ? unitReport.premises.supplyStartDate
        : unitReport.premises.supplyStartDate ?? 0,
      tooltipContent: 'propriedade: unitReport.premises.supplyStartDate',
    },
    {
      label: 'Distribuidora',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.energyDistributionCompany.name,
      tooltipContent:
        'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.energyDistributionCompany.name',
    },

    {
      label: 'Modalidade Tarifária',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.tariff,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.tariff',
    },
    {
      label: 'Demanda Ponta ACR',
      value: unitReport.premises.aclContractedDemandPeak,
      tooltipContent: 'propriedade: unitReport.premises.aclContractedDemandPeak',
    },
    {
      label: 'Demanda Fora Ponta ACR',
      value: unitReport.premises.aclContractedDemandOffPeak,
      tooltipContent: 'propriedade: unitReport.premises.aclContractedDemandOffPeak',
    },
    {
      label: 'Classificação da empresa',
      value: unitReport.premises.icmsType,
      tooltipContent: 'propriedade: unitReport.premises.icmsType',
    },
    {
      label: 'ICMS',
      value: `${unitReport.sampleMonthSimulation.currentMarket.icms.percentageValue * 100} %`,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.icms.percentageValue',
    },
    {
      label: 'Gestão Clarke/mês',
      value: formatNumber(unitReport.premises.managementPrice, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.managementPrice',
    },
    {
      label: 'Investimentos iniciais da unidade',
      value: formatNumber(unitReport.premises.initialExpenses, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.initialExpenses',
    },
    {
      label: 'Contribuição CCEE',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.contributionCcee, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.contributionCcee',
    },
  ];

  return premissesTableData;
};

export const getGroupInfoTableData = (proposalData: ISimulationResultBase) => {
  const groupInfoTableData = [
    {
      label: 'I-REC',
      value: formatNumber(Number(proposalData?.iRecCertificationCostMonthly), '$ 0,0.00'),
      tooltipContent: 'propriedade: iRecCertificationCostMonthly',
    },
    {
      label: 'Custo de migração do grupo',
      value: formatNumber(Number(proposalData?.totalMigrationCost), '$ 0,0.00'),
      tooltipContent: 'propriedade: totalMigrationCost',
    },
  ];

  return groupInfoTableData;
};

export const getConsumptionMediumVoltageTableData = (unitReport: IUnitReport) => {
  const consumptionPeak = unitReport.premises.consumptionPeak;
  const consumptionOffPeak = unitReport.premises.consumptionOffPeak;

  const parsedConsumptioMediumVoltage = monthsListDescription.map((item, index) => ({
    label: item,
    consumptionPeak: formatNumber(consumptionPeak[index + 1], '0000,'),
    consumptionOffPeak: formatNumber(consumptionOffPeak[index + 1], '0000,'),
  }));
  return parsedConsumptioMediumVoltage;
};

export const getConsumptionLowVoltageTableData = (unitReport: IUnitReport) => {
  const consumptionLowVoltage = unitReport.premises.consumptionLowVoltage;

  const parsedConsumptionLowVoltage = Object.entries(consumptionLowVoltage).map(([key, value]) => ({
    label: monthsListDescription[parseInt(key) - 1],
    value: value,
  }));

  return parsedConsumptionLowVoltage;
};

export const getPriceListTableData = (unitReport: IUnitReport) => {
  const priceList = Object.values(unitReport.priceList)[0];
  const parsedPriceList = Object.entries(priceList).map(([key, value]) => ({
    label: key,
    value: value,
  }));
  return parsedPriceList;
};

const formatValueForUniqueValue = (value: number, type: string) => {
  return type === 'ABSOLUTE' ? `${value ?? '0'} (R$/MWh)` : `${(value * 100)?.toFixed(0) ?? '0'}%`;
};

const formatValueForValueByYear = (valueByYear: Record<string, number>, type: string) => {
  const formattedValueList: string[] = [];
  for (const year in valueByYear) {
    if (type === 'PERCENTAGE') {
      formattedValueList.push(`${year}: ${(valueByYear[year] * 100).toFixed(0)}%`);
    } else {
      formattedValueList.push(`${year}: ${valueByYear[year]} (R$/MWh)`);
    }
  }
  return formattedValueList.join(' | ');
};

export const getPriceListCompositionTableData = (unitReport: IUnitReport) => {
  const keyIndex = getMaxKeyIndex(unitReport);
  const uniqueYears = getUniqueYears(unitReport, keyIndex);
  const headers = ['Etapa', 'Premissas', ...uniqueYears];
  const dataRows = unitReport?.priceListComposition?.map((item) => createDataRow(item, uniqueYears));

  return {
    headers,
    rows: dataRows,
  };
};

const getMaxKeyIndex = (unitReport: IUnitReport) => {
  const keysIndex = unitReport?.priceListComposition?.map((item) => Object.keys(item.priceList ?? {})?.[0]);
  return keysIndex && Math.max(Number(...keysIndex));
};

const getUniqueYears = (unitReport: IUnitReport, keyIndex: number) => {
  const allYears = unitReport?.priceListComposition?.flatMap((item) => Object?.keys(item.priceList?.[keyIndex] ?? {}));
  return Array?.from(new Set(allYears))?.sort();
};

const createDataRow = (item: IPriceListComposition, uniqueYears: string[]) => {
  const keyIndex = Object.keys(item.priceList ?? {})?.[0];
  const years = uniqueYears?.map((year) => formatNumber(item.priceList?.[keyIndex][year], '$0,0.00'));

  const {
    value,
    type,
    variationValueCeil,
    variationValueFloor,
    label,
    valueByYear,
    additionalInfo,
    priceValueCeil,
    priceValueFloor,
  } = item.priceComponent;

  const formattedValue = valueByYear
    ? formatValueForValueByYear(valueByYear, type)
    : formatValueForUniqueValue(value, type);
  const premisesContent = additionalInfo ? additionalInfo : formattedValue;
  const premisesTag = CreatePremisesTag({
    premisesContent,
    value,
    variationValueCeil: variationValueCeil,
    variationValueFloor: variationValueFloor,
    priceValueCeil: priceValueCeil,
    priceValueFloor: priceValueFloor,
  });

  return { row: [label, premisesTag, ...years], tagStyle: getTagStyle(value, formattedValue, additionalInfo) };
};

interface ICreatePremisesTag {
  premisesContent: string;
  value: number;
  variationValueCeil: number | null;
  variationValueFloor: number | null;
  priceValueCeil: number | null;
  priceValueFloor: number | null;
}

export const CreatePremisesTag = ({
  premisesContent,
  value,
  variationValueCeil,
  variationValueFloor,
  priceValueCeil,
  priceValueFloor,
}: ICreatePremisesTag): JSX.Element => {
  const isCeil = variationValueCeil || priceValueCeil;
  const isFloor = variationValueFloor || priceValueFloor;
  return (
    <div className="flex gap-2">
      <p className={twMerge('flex gap-4 justify-center items-center min-w-fit w-10', getPremisesTagClass(value))}>
        {premisesContent !== 'Preços Energisa' && (
          <HeroIconSolidOrOutline
            icon={Number(value) < 0 ? 'TrendingDownIcon' : 'TrendingUpIcon'}
            className={twMerge(getTagStyle(value, premisesContent, null), 'min-w-5 size-5')}
          />
        )}
        {premisesContent.includes('(R$/MWh)') ? `${premisesContent.replace('(R$/MWh)', '')} ` : premisesContent}
        {premisesContent.includes('(R$/MWh)') && <p className="text-caption-medium">R$/MWh</p>}
      </p>
      {isCeil && (
        <span
          className={twMerge(
            'bg-brand-gray-10 rounded-lg border border-opacity-50 border-brand-gray-50 text-brand-gray-60 text-start px-4 py-1 min-w-fit flex justify-center items-center gap-2',
          )}
        >
          <img src={intervalIcon} alt="interval icon" className="size-5 opacity-70" />
          {variationValueCeil && `teto R$ ${variationValueCeil}`}
          {variationValueCeil && priceValueCeil && <hr className="text-brand-gray-30 w-1 h-4/6" />}
          {priceValueCeil && `teto R$ ${priceValueCeil}`}
        </span>
      )}
      {isFloor && (
        <span
          className={twMerge(
            isFloor &&
              'bg-brand-gray-10 rounded-lg border-opacity-50 border border-brand-gray-50 text-brand-gray-60 text-start px-4 py-1 min-w-fit flex justify-center items-center gap-2',
          )}
        >
          <HeroIconSolidOrOutline icon="CurrencyDollarIcon" className="min-w-5 size-6 text-black opacity-60" />
          {variationValueFloor && `piso R$ ${variationValueFloor}`}
          {variationValueFloor && priceValueFloor && <hr className="bg-brand-gray-60 w-1 h-4/6" />}
          {priceValueFloor && `piso R$ ${priceValueFloor}`}
        </span>
      )}
    </div>
  );
};

export const getPremisesTagClass = (value: number) =>
  value > 0
    ? 'rounded-lg bg-primary-60 text-center px-4 py-1 text-brand-gray-60'
    : value < 0
    ? 'rounded-lg bg-red-400 text-center px-4 py-1 text-brand-gray-60'
    : value === null
    ? 'rounded-lg bg-primary-60 text-brand-gray-60 text-center px-4 py-1'
    : '';

export const getTagStyle = (value: number, formattedValue: string, additionalInfo: string | null) =>
  !additionalInfo && (value > 0 || !formattedValue.includes('-'))
    ? 'text-brand-gray-10 font-semiligth text-paragrath-medium'
    : value < 0 || formattedValue.includes('-')
    ? 'text-brand-gray-10 font-semiligth text-paragrath-medium'
    : 'text-gray-60 font-semiligth text-paragrath-medium';

export function parseProposalToUnitsReportsRetail(unitsReports: Array<IProductTwoUnitReport>): IUnitReport[] {
  return unitsReports?.map((unit: Record<string, any>) => {
    const premises: IPremises = {
      nickname: unit?.premises?.nickname,
      icmsType: unit?.premises?.icmsType,
      supplyStartDate: unit?.premises?.supplyStartDate,
      managementPrice: unit?.premises?.managementPrice,
      initialExpenses: unit?.premises?.initialExpenses,
      powerGeneratorCost: unit?.premises?.powerGeneratorCost,
      solarPlantInOperation: unit?.premises?.solarPlantInOperation,
      consumptionPeak: unit?.premises?.consumptionPeak,
      consumptionOffPeak: unit?.premises?.consumptionOffPeak,
      consumptionLowVoltage: unit?.premises?.consumptionLowVoltage,
      companyType: unit?.premises?.companyType,
      operationHoursRange: unit?.premises?.operationHoursRange,
      distributedGenerationMonthlyCost: unit?.premises?.distributedGenerationMonthlyCost,
      injectedEnergyOffPeak: unit?.premises?.injectedEnergyOffPeak,
      injectedEnergyPeak: unit?.premises?.injectedEnergyPeak,
      manualAcrContractedDemandPeak: unit?.premises?.manualAcrContractedDemandPeak,
      manualAcrContractedDemandOffPeak: unit?.premises?.manualAcrContractedDemandOffPeak,
      manualAclContractedDemandPeak: unit?.premises?.manualAclContractedDemandPeak,
      manualAclContractedDemandOffPeak: unit?.premises?.manualAclContractedDemandOffPeak,
      manualConsumptionOffPeak: unit?.premises?.manualConsumptionOffPeak,
      manualConsumptionPeak: unit?.premises?.manualConsumptionPeak,
      targetVolumeGenerated: unit?.premises?.targetVolumeGenerated,
      energyDistributionCompany: unit?.premises?.energyDistributionCompany,
      acrContractedDemandPeak: unit?.premises?.acrContractedDemandPeak,
      acrContractedDemandOffPeak: unit?.premises?.acrContractedDemandOffPeak,
      aclContractedDemandPeak: unit?.premises?.aclContractedDemandPeak,
      aclContractedDemandOffPeak: unit?.premises?.aclContractedDemandOffPeak,
      tariff: unit?.premises?.tariff,
    };

    const currentMarket: ICurrentMarket = {
      consumptionPeak: unit?.sampleMonthSimulation?.currentMarket?.consumptionPeak,
      consumptionOffPeak: unit?.sampleMonthSimulation?.currentMarket?.consumptionOffPeak,
      distributionTariffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffPeakCost,
      distributionTariffOffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffOffPeakCost,
      electricityTariffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffPeakCost,
      electricityTariffOffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffOffPeakCost,
      generatorCost: unit?.sampleMonthSimulation?.currentMarket?.generatorCost,
      demandOffPeak: unit?.sampleMonthSimulation?.currentMarket?.demandOffPeak,
      demandPeak: unit?.sampleMonthSimulation?.currentMarket?.demandPeak,
      consumptionLowVoltage: unit?.sampleMonthSimulation?.currentMarket?.consumptionLowVoltage,
      tariff: unit?.sampleMonthSimulation?.currentMarket?.tariff,
      distributionTariffLowVoltageCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffLowVoltageCost,
      electricityTariffLowVoltageCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffLowVoltageCost,
      monthYear: unit?.sampleMonthSimulation?.currentMarket?.monthYear,
      generalGeneratorFactor: unit?.sampleMonthSimulation?.currentMarket?.generalGeneratorFactor,
      tariffFlagList: unit?.sampleMonthSimulation?.currentMarket?.tariffFlagList,
      targetsupplyStartDate: unit?.sampleMonthSimulation?.currentMarket?.targetsupplyStartDate,
      injectedEnergyOffPeak: unit?.sampleMonthSimulation?.currentMarket?.injectedEnergyOffPeak,
      monthGeneratorFactor: unit?.sampleMonthSimulation?.currentMarket?.monthGeneratorFactor,
      icms: unit?.sampleMonthSimulation?.currentMarket?.icms,
      rawTotalConsumptionCost: unit?.sampleMonthSimulation?.currentMarket?.rawTotalConsumptionCost,
      totalWithIcms: unit?.sampleMonthSimulation?.currentMarket?.totalWithIcms,
      totalPisCofins: unit?.sampleMonthSimulation?.currentMarket?.totalPisCofins,
      totalWithoutTaxes: unit?.sampleMonthSimulation?.currentMarket?.totalWithoutTaxes,
      totalWithTaxes: unit?.sampleMonthSimulation?.currentMarket?.totalWithTaxes,
      tariffFlag: unit?.sampleMonthSimulation?.currentMarket?.tariffFlag,
    };

    const targetMarket: ITargetMarket = {
      demandOffPeakDiscount: unit?.sampleMonthSimulation?.targetMarket?.demandOffPeakDiscount,
      demandPeakDiscount: unit?.sampleMonthSimulation?.targetMarket?.demandPeakDiscount,
      risk: unit?.sampleMonthSimulation?.targetMarket?.risk,
      productType: unit?.sampleMonthSimulation?.targetMarket?.productType,
      monthYear: unit?.sampleMonthSimulation?.targetMarket?.monthYear,
      priceListWithInflation: unit?.sampleMonthSimulation?.targetMarket?.priceListWithInflation,
      customTaxes: unit?.sampleMonthSimulation?.targetMarket?.customTaxes,
      icms: unit?.sampleMonthSimulation?.targetMarket?.icms,
      manualAclPrice: unit?.sampleMonthSimulation?.targetMarket?.manualAclPrice,
      manualAclPricesHasChargesCoverage: unit?.sampleMonthSimulation?.targetMarket?.manualAclPricesHasChargesCoverage,
      contributionCcee: unit?.sampleMonthSimulation?.targetMarket?.contributionCcee,
      modulation: unit?.sampleMonthSimulation?.targetMarket?.modulation,
      demandPeak: unit?.sampleMonthSimulation?.targetMarket?.demandPeak,
      demandOffPeak: unit?.sampleMonthSimulation?.targetMarket?.demandOffPeak,
      tariff: unit?.sampleMonthSimulation?.targetMarket?.tariff,
      consumptionPeak: unit?.sampleMonthSimulation?.targetMarket?.consumptionPeak,
      consumptionOffPeak: unit?.sampleMonthSimulation?.targetMarket?.consumptionOffPeak,
      distributionTariffPeakCost: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffPeakCost,
      distributionTariffOffPeakCost: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffOffPeakCost,
      distributionTariffPeakDiscount: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffPeakDiscount,
      stimulatedDemandPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDemandPeak,
      stimulatedDemandOffPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDemandOffPeak,
      stimulatedDistributionTariffPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDistributionTariffPeak,
      distributionIcms: unit?.sampleMonthSimulation?.targetMarket?.distributionIcms,
      electricityIcms: unit?.sampleMonthSimulation?.targetMarket?.electricityIcms,
      distributionPisCofins: unit?.sampleMonthSimulation?.targetMarket?.distributionPisCofins,
      electricityWithLosses: unit?.sampleMonthSimulation?.targetMarket?.electricityWithLosses,
      pisCofinsHighlight: unit?.sampleMonthSimulation?.targetMarket?.pisCofinsHighlight,
      electricityTariffCost: unit?.sampleMonthSimulation?.targetMarket?.electricityTariffCost,
      securityAndModulationEnergyCharges: unit?.sampleMonthSimulation?.targetMarket?.securityAndModulationEnergyCharges,
      targetMarketContributionCcee: unit?.sampleMonthSimulation?.targetMarket?.targetMarketContributionCcee,
      clarkeManagement: unit?.sampleMonthSimulation?.targetMarket?.clarkeManagement,
      totalWithoutTaxes: unit?.sampleMonthSimulation?.targetMarket?.totalWithoutTaxes,
      monthGeneratorFactor: unit?.sampleMonthSimulation?.targetMarket?.monthGeneratorFactor,
      totalWithTaxes: unit?.sampleMonthSimulation?.targetMarket?.totalWithTaxes,
      managementPrice: unit?.sampleMonthSimulation?.targetMarket?.managementPrice,
    };

    const sampleMonthSimulation: ISampleMonth = {
      currentMarket,
      targetMarket,
      report: unit?.sampleMonthSimulation.report,
    };

    const unitReport: IUnitReport = {
      priceListWithInflation: unit?.priceListWithInflation,
      priceListComposition: unit?.priceListComposition,
      premises,
      totalSavingWithTaxes: unit?.totalSavingWithTaxes,
      totalSavingInPercentageWithTaxes: unit?.totalSavingInPercentageWithTaxes,
      averageConsumption: unit?.averageConsumption,
      carbonDioxideEmissionInTonsAvoidedByYear: unit?.carbonDioxideEmissionInTonsAvoidedByYear,
      currentSpentByYear: unit?.currentSpentByYear,
      averageBillAmount: unit?.averageBillAmount,
      energyFeeMonthlyByYear: unit?.energyFeeMonthlyByYear,
      grossMerchandiseVolumeByYear: unit?.grossMerchandiseVolumeByYear,
      initialExpenses: unit?.initialExpenses,
      lowVoltageFinalTariffsList: unit?.lowVoltageFinalTariffsList,
      managementPriceByYear: unit?.managementPriceByYear,
      manualAclPrice: unit?.manualAclPrice,
      mediumVoltageFinalTariffsList: unit?.mediumVoltageFinalTariffsList,
      nickname: unit?.nickname,
      priceList: unit?.priceList,
      rawPriceList: unit?.rawPriceList,
      usedPriceProjection: unit?.usedPriceProjection,
      sampleMonthSimulation: sampleMonthSimulation,
      savingByYearInPercentage: unit?.savingByYearInPercentage,
      savingByYear: unit?.savingByYear,
      savingByMonth: unit?.savingByMonth,
      takeRateByYear: unit?.takeRateByYear,
      targetSpentByYear: unit?.targetSpentByYear,
      totalCarbonDioxideEmissionInTonsAvoided: unit?.totalCarbonDioxideEmissionInTonsAvoided,
      totalGrossMerchandiseVolume: unit?.totalGrossMerchandiseVolume,
      totalManagementPrice: unit?.totalManagementPrice,
      totalMonths: unit?.totalMonths,
      totalSavingInPercentage: unit?.totalSavingInPercentage,
      totalSaving: unit?.totalSaving,
      totalTakeRate: unit?.totalTakeRate,
      totalConsumptionSummationInMwh: unit?.totalConsumptionSummationInMwh,
      totalLifetimeValue: unit?.totalLifetimeValue,
    };

    return unitReport;
  });
}
export function parseProposalToUnitsReportsManagement(unitsReports: Array<IProductOneUnitReport>): IUnitReport[] {
  return unitsReports?.map((unit: Record<string, any>) => {
    const premises: IPremises = {
      nickname: unit?.premises?.nickname,
      icmsType: unit?.premises?.icmsType,
      supplyStartDate: unit?.premises?.supplyStartDate,
      managementPrice: unit?.premises?.managementPrice,
      initialExpenses: unit?.premises?.initialExpenses,
      powerGeneratorCost: unit?.premises?.powerGeneratorCost,
      solarPlantInOperation: unit?.premises?.solarPlantInOperation,
      consumptionPeak: unit?.premises?.consumptionPeak,
      consumptionOffPeak: unit?.premises?.consumptionOffPeak,
      consumptionLowVoltage: unit?.premises?.consumptionLowVoltage,
      companyType: unit?.premises?.companyType,
      operationHoursRange: unit?.premises?.operationHoursRange,
      distributedGenerationMonthlyCost: unit?.premises?.distributedGenerationMonthlyCost,
      injectedEnergyOffPeak: unit?.premises?.injectedEnergyOffPeak,
      injectedEnergyPeak: unit?.premises?.injectedEnergyPeak,
      manualAcrContractedDemandPeak: unit?.premises?.manualAcrContractedDemandPeak,
      manualAcrContractedDemandOffPeak: unit?.premises?.manualAcrContractedDemandOffPeak,
      manualAclContractedDemandPeak: unit?.premises?.manualAclContractedDemandPeak,
      manualAclContractedDemandOffPeak: unit?.premises?.manualAclContractedDemandOffPeak,
      manualConsumptionOffPeak: unit?.premises?.manualConsumptionOffPeak,
      manualConsumptionPeak: unit?.premises?.manualConsumptionPeak,
      targetVolumeGenerated: unit?.premises?.targetVolumeGenerated,
      energyDistributionCompany: unit?.premises?.energyDistributionCompany,
      acrContractedDemandPeak: unit?.premises?.acrContractedDemandPeak,
      acrContractedDemandOffPeak: unit?.premises?.acrContractedDemandOffPeak,
      aclContractedDemandPeak: unit?.premises?.aclContractedDemandPeak,
      aclContractedDemandOffPeak: unit?.premises?.aclContractedDemandOffPeak,
      tariff: unit?.premises?.tariff,
    };

    const currentMarket: ICurrentMarket = {
      consumptionPeak: unit?.sampleMonthSimulation?.currentMarket?.consumptionPeak,
      consumptionOffPeak: unit?.sampleMonthSimulation?.currentMarket?.consumptionOffPeak,
      distributionTariffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffPeakCost,
      distributionTariffOffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffOffPeakCost,
      electricityTariffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffPeakCost,
      electricityTariffOffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffOffPeakCost,
      generatorCost: unit?.sampleMonthSimulation?.currentMarket?.generatorCost,
      demandOffPeak: unit?.sampleMonthSimulation?.currentMarket?.demandOffPeak,
      demandPeak: unit?.sampleMonthSimulation?.currentMarket?.demandPeak,
      consumptionLowVoltage: unit?.sampleMonthSimulation?.currentMarket?.consumptionLowVoltage,
      tariff: unit?.sampleMonthSimulation?.currentMarket?.tariff,
      distributionTariffLowVoltageCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffLowVoltageCost,
      electricityTariffLowVoltageCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffLowVoltageCost,
      monthYear: unit?.sampleMonthSimulation?.currentMarket?.monthYear,
      generalGeneratorFactor: unit?.sampleMonthSimulation?.currentMarket?.generalGeneratorFactor,
      tariffFlagList: unit?.sampleMonthSimulation?.currentMarket?.tariffFlagList,
      targetsupplyStartDate: unit?.sampleMonthSimulation?.currentMarket?.targetsupplyStartDate,
      injectedEnergyOffPeak: unit?.sampleMonthSimulation?.currentMarket?.injectedEnergyOffPeak,
      monthGeneratorFactor: unit?.sampleMonthSimulation?.currentMarket?.monthGeneratorFactor,
      icms: unit?.sampleMonthSimulation?.currentMarket?.icms,
      rawTotalConsumptionCost: unit?.sampleMonthSimulation?.currentMarket?.rawTotalConsumptionCost,
      totalWithIcms: unit?.sampleMonthSimulation?.currentMarket?.totalWithIcms,
      totalPisCofins: unit?.sampleMonthSimulation?.currentMarket?.totalPisCofins,
      totalWithoutTaxes: unit?.sampleMonthSimulation?.currentMarket?.totalWithoutTaxes,
      totalWithTaxes: unit?.sampleMonthSimulation?.currentMarket?.totalWithTaxes,
      tariffFlag: unit?.sampleMonthSimulation?.currentMarket?.tariffFlag,
    };

    const targetMarket: ITargetMarket = {
      demandOffPeakDiscount: unit?.sampleMonthSimulation?.targetMarket?.demandOffPeakDiscount,
      demandPeakDiscount: unit?.sampleMonthSimulation?.targetMarket?.demandPeakDiscount,
      risk: unit?.sampleMonthSimulation?.targetMarket?.risk,
      productType: unit?.sampleMonthSimulation?.targetMarket?.productType,
      monthYear: unit?.sampleMonthSimulation?.targetMarket?.monthYear,
      priceListWithInflation: unit?.sampleMonthSimulation?.targetMarket?.priceListWithInflation,
      customTaxes: unit?.sampleMonthSimulation?.targetMarket?.customTaxes,
      icms: unit?.sampleMonthSimulation?.targetMarket?.icms,
      manualAclPrice: unit?.sampleMonthSimulation?.targetMarket?.manualAclPrice,
      manualAclPricesHasChargesCoverage: unit?.sampleMonthSimulation?.targetMarket?.manualAclPricesHasChargesCoverage,
      contributionCcee: unit?.sampleMonthSimulation?.targetMarket?.contributionCcee,
      modulation: unit?.sampleMonthSimulation?.targetMarket?.modulation,
      demandPeak: unit?.sampleMonthSimulation?.targetMarket?.demandPeak,
      demandOffPeak: unit?.sampleMonthSimulation?.targetMarket?.demandOffPeak,
      tariff: unit?.sampleMonthSimulation?.targetMarket?.tariff,
      consumptionPeak: unit?.sampleMonthSimulation?.targetMarket?.consumptionPeak,
      consumptionOffPeak: unit?.sampleMonthSimulation?.targetMarket?.consumptionOffPeak,
      distributionTariffPeakCost: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffPeakCost,
      distributionTariffOffPeakCost: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffOffPeakCost,
      distributionTariffPeakDiscount: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffPeakDiscount,
      stimulatedDemandPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDemandPeak,
      stimulatedDemandOffPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDemandOffPeak,
      stimulatedDistributionTariffPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDistributionTariffPeak,
      distributionIcms: unit?.sampleMonthSimulation?.targetMarket?.distributionIcms,
      electricityIcms: unit?.sampleMonthSimulation?.targetMarket?.electricityIcms,
      distributionPisCofins: unit?.sampleMonthSimulation?.targetMarket?.distributionPisCofins,
      electricityWithLosses: unit?.sampleMonthSimulation?.targetMarket?.electricityWithLosses,
      pisCofinsHighlight: unit?.sampleMonthSimulation?.targetMarket?.pisCofinsHighlight,
      electricityTariffCost: unit?.sampleMonthSimulation?.targetMarket?.electricityTariffCost,
      securityAndModulationEnergyCharges: unit?.sampleMonthSimulation?.targetMarket?.securityAndModulationEnergyCharges,
      targetMarketContributionCcee: unit?.sampleMonthSimulation?.targetMarket?.targetMarketContributionCcee,
      clarkeManagement: unit?.sampleMonthSimulation?.targetMarket?.clarkeManagement,
      totalWithoutTaxes: unit?.sampleMonthSimulation?.targetMarket?.totalWithoutTaxes,
      monthGeneratorFactor: unit?.sampleMonthSimulation?.targetMarket?.monthGeneratorFactor,
      totalWithTaxes: unit?.sampleMonthSimulation?.targetMarket?.totalWithTaxes,
      managementPrice: unit?.sampleMonthSimulation?.targetMarket?.managementPrice,
    };

    const sampleMonth: ISampleMonth = {
      currentMarket,
      targetMarket,
      report: unit?.sampleMonthSimulation.report,
    };

    const unitReport: IUnitReport = {
      priceListComposition: unit?.priceListComposition,
      premises,
      totalSavingWithTaxes: unit?.totalSavingWithTaxes,
      totalSavingInPercentageWithTaxes: unit?.totalSavingInPercentageWithTaxes,
      averageConsumption: unit?.averageConsumption,
      carbonDioxideEmissionInTonsAvoidedByYear: unit?.carbonDioxideEmissionInTonsAvoidedByYear,
      currentSpentByYear: unit?.currentSpentByYear,
      averageBillAmount: unit?.averageBillAmount,
      energyFeeMonthlyByYear: unit?.energyFeeMonthlyByYear,
      grossMerchandiseVolumeByYear: unit?.grossMerchandiseVolumeByYear,
      initialExpenses: unit?.initialExpenses,
      lowVoltageFinalTariffsList: unit?.lowVoltageFinalTariffsList,
      mediumVoltageFinalTariffsList: unit?.mediumVoltageFinalTariffsList,
      managementPriceByYear: unit?.managementPriceByYear,
      manualAclPrice: unit?.manualAclPrice,
      nickname: unit?.nickname,
      priceList: unit?.priceList,
      priceListWithInflation: unit?.priceListWithInflation,
      rawPriceList: unit?.rawPriceList,
      usedPriceProjection: unit?.usedPriceProjection,
      sampleMonthSimulation: sampleMonth,
      savingByYearInPercentage: unit?.savingByYearInPercentage,
      savingByYear: unit?.savingByYear,
      savingByMonth: unit?.savingByMonth,
      takeRateByYear: unit?.takeRateByYear,
      targetSpentByYear: unit?.targetSpentByYear,
      totalCarbonDioxideEmissionInTonsAvoided: unit?.totalCarbonDioxideEmissionInTonsAvoided,
      totalGrossMerchandiseVolume: unit?.totalGrossMerchandiseVolume,
      totalManagementPrice: unit?.totalManagementPrice,
      totalMonths: unit?.totalMonths,
      totalSavingInPercentage: unit?.totalSavingInPercentage,
      totalSaving: unit?.totalSaving,
      totalTakeRate: unit?.totalTakeRate,
      totalConsumptionSummationInMwh: unit?.totalConsumptionSummationInMwh,
      totalLifetimeValue: unit?.totalLifetimeValue,
    };

    return unitReport;
  });
}
