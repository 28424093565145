import useWhiteLabel from '@hooks/use-white-label';
import { IWhiteLabelSupplier } from '@schemas/white-label/types';

import { CardContainer } from '@components/atoms/simplified-proposal/card-container';
import CardHeader from '@components/atoms/simplified-proposal/card-header';
import SimplifiedProposalEconomyChart, { ISubtitle } from './economy-chart';

export interface ISimplifiedProposalEconomyChartWithoutSubtitle {
  labels: Array<string>;
  currentValues: Array<number>;
  targetValues: Array<number>;
  savingValues: Array<number>;
  isWhiteLabelProposal?: boolean;
  whiteLabelSupplier?: IWhiteLabelSupplier;
}

export interface ISimplifiedProposalEconomyChartData extends ISimplifiedProposalEconomyChartWithoutSubtitle {
  subtitleData: Array<ISubtitle>;
}

export interface ISimplifiedProposalEconomyChart {
  chartData: ISimplifiedProposalEconomyChartData;
}

const SimplifiedProposalEconomyChartSection = ({ chartData }: ISimplifiedProposalEconomyChart) => {
  const { whiteLabelSupplier } = useWhiteLabel();
  return (
    <CardContainer columnsSpan={3}>
      <>
        <CardHeader title={'Economia projetada por ano'} icon={'CurrencyDollarIcon'} imageSvg={''} />
        <SimplifiedProposalEconomyChart
          labels={chartData.labels}
          currentValues={chartData.currentValues}
          targetValues={chartData.targetValues}
          savingValues={chartData.savingValues}
          subtitleData={chartData.subtitleData}
          whiteLabelSupplier={whiteLabelSupplier}
        />
      </>
    </CardContainer>
  );
};
export default SimplifiedProposalEconomyChartSection;
