import React from 'react';

import { CONTRACTED_DEMAND_PATH } from '@routers/constants';

import { IContractedDemandSimulatorInputs, IContractedDemandSimulation } from '@schemas/contracted-demand/types';

import ContractedDemandSimulatorResultCore from '@components/organisms/contracted-demand/simulator/contracted-demand-simulator-result-core';
import SimulatorLayoutBase from '@components/organisms/simulator-layout-base';
import { SimulatorResultFooter } from '@components/molecules/general/simulation-calculator-result-footer';

export interface IContractedDemandSimulatorResult {
  simulatorResult?: IContractedDemandSimulation;
  simulatorInputs?: IContractedDemandSimulatorInputs;
}

const ContractedDemandSimulatorResult = ({
  simulatorResult,
  simulatorInputs,
}: IContractedDemandSimulatorResult): JSX.Element => (
  <SimulatorLayoutBase
    title="Resultado de Simulação"
    simulatorType="CONTRACTED_DEMAND"
    simulatorFlow="SIMULATOR_RESULT"
  >
    <div className="col-span-full w-full">
      <ContractedDemandSimulatorResultCore {...{ simulatorInputs, simulatorResult }} />
      <SimulatorResultFooter
        simulatorType="CONTRACTED_DEMAND"
        simulatorPath={CONTRACTED_DEMAND_PATH}
        calculationInputs={simulatorInputs}
      />
    </div>
  </SimulatorLayoutBase>
);

export default ContractedDemandSimulatorResult;
