import React from 'react';
import { Menu } from '@clarke-energia/foton';

import { useAuth } from '@src/auth-wrapper';

import ClarkeLogo from '@assets/images/clarke-logo-minimal.svg';
import {
  generateSidebarNavigationLinksPropsForAdmin,
  generateSidebarNavigationLinksPropsForCommercialDealer,
  generateSidebarNavigationLinksPropsForSuperAdmin,
  generateSidebarNavigationLinksPropsForWhiteLabel,
} from '@components/atoms/general/sidebar/generate-sidebar-navigation-links-props';

interface IDesktopSidebar {
  isMenuCollapsedByDefault?: boolean;
}

const DesktopSidebar = ({ isMenuCollapsedByDefault }: IDesktopSidebar) => {
  const { user, logout } = useAuth();

  const [isMenuCollapsed, setIsMenuCollapsed] = React.useState<boolean>(isMenuCollapsedByDefault ?? false);
  const navigationLinks = user?.isCommercialDealer
    ? generateSidebarNavigationLinksPropsForCommercialDealer(isMenuCollapsed)
    : user?.isWhiteLabel
    ? generateSidebarNavigationLinksPropsForWhiteLabel(isMenuCollapsed)
    : user?.isSuperAdmin
    ? generateSidebarNavigationLinksPropsForSuperAdmin(isMenuCollapsed)
    : generateSidebarNavigationLinksPropsForAdmin(isMenuCollapsed);

  return (
    <div className="h-full">
      {user && (
        <Menu
          adminLogo={user.pictureUrl}
          userLogo={user.pictureUrl}
          name={user.name}
          isAdmin={user.isAdmin}
          companyLogo={ClarkeLogo}
          adminLinks={navigationLinks}
          userLinks={navigationLinks}
          logoutAction={logout}
          menuCollapseControl={{
            isMenuCollapsed,
            setIsMenuCollapsed: () => setIsMenuCollapsed((previous) => !previous),
          }}
        />
      )}
    </div>
  );
};

export default DesktopSidebar;
