import { useWatch } from 'react-hook-form';
import { useAuth } from '@src/auth-wrapper';
import { Tooltip } from '@clarke-energia/foton';

import { ISimulationPayloadBase } from '@schemas/types';

import { CheckboxFormField } from '@components/atoms/form/fields/checkbox-form-field';

const SimulatorFormExclusiveTraderSection = (): JSX.Element => {
  const exclusiveTraderValue = useWatch<ISimulationPayloadBase>({ name: 'exclusiveTrader' });

  const { user } = useAuth();
  const userIsNotSuperAdmin = user?.isSuperAdmin !== true;

  return (
    <div className="bg-neutral-10 mt-3 py-5 px-6 rounded-medium flex items-center gap-3">
      <fieldset disabled={userIsNotSuperAdmin}>
        <CheckboxFormField<ISimulationPayloadBase>
          label=""
          field="exclusiveTrader"
          inputOptions={[
            {
              id: 'exclusiveTrader',
              optionLabel: 'Exclusividade Energisa',
              value: 'exclusiveTrader',
              defaultChecked: (exclusiveTraderValue as boolean) ?? true,
            },
          ]}
        />
      </fieldset>
      <Tooltip content="Se marcada, somente as projeções de preço da Energisa serão utilizadas na simulação. Essa opção só pode ser desmarcada por usuários Super Admin." />
    </div>
  );
};

export default SimulatorFormExclusiveTraderSection;
