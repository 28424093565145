import Skeleton from 'react-loading-skeleton';

const ProposalPreviewContainerSkeleton = (): JSX.Element => {
  return (
    <div className="flex flex-col gap-8 w-full">
      <div>
        {Array(3)
          .fill({})
          .map((_, rowIndex) => (
            <Skeleton containerClassName="w-full" key={`skeleton-header-row-${rowIndex}`} width="75%" height={20} />
          ))}
      </div>
      <div>
        <Skeleton containerClassName="w-full" key={`skeleton-description-row-title`} width="50%" height={20} />
        {Array(3)
          .fill({})
          .map((_, rowIndex) => (
            <Skeleton
              containerClassName="w-full"
              key={`skeleton-description-row-${rowIndex}`}
              width="100%"
              height={20}
            />
          ))}
      </div>
      <div className="flex flex-col gap-4">
        <Skeleton containerClassName="w-full" key={'skeleton-seller-and-author-title'} width="50%" height={20} />
        <div className="flex flex-row">
          {Array(2)
            .fill({})
            .map((_, rowIndex) => (
              <div key={`skeleton-seller-and-author-div-${rowIndex}`} className="flex flex-col gap-2 pl-6">
                <Skeleton
                  containerClassName="w-full"
                  key={`skeleton-seller-and-author-icon-${rowIndex}`}
                  width={50}
                  height={50}
                  circle={true}
                />
                <Skeleton
                  containerClassName="w-full"
                  key={`skeleton-seller-and-author-name-${rowIndex}`}
                  width={80}
                  height={20}
                />
              </div>
            ))}
        </div>
      </div>
      <div className="flex flex-row gap-4">
        {Array(3)
          .fill({})
          .map((_, columnIndex) => (
            <div className="flex flex-col gap-6 w-1/3" key={`table-skeleton-column-${columnIndex}`}>
              {Array(3)
                .fill({})
                .map((_, rowIndex) => (
                  <div
                    key={`skeleton-proposal-key-metrics-panel-label-column-${columnIndex}-row-${rowIndex}-div`}
                    className="flex flex-col gap-2"
                  >
                    <Skeleton
                      containerClassName="w-full"
                      key={`skeleton-proposal-key-metrics-panel-label-column-${columnIndex}-row-${rowIndex}`}
                      width="80%"
                      height={20}
                    />
                    <Skeleton
                      containerClassName="w-full"
                      key={`skeleton-proposal-key-metrics-panel-value-column-${columnIndex}-row-${rowIndex}`}
                      width="50%"
                      height={20}
                    />
                  </div>
                ))}
            </div>
          ))}
      </div>
      <div className="flex flex-row gap-4 flex-wrap">
        {Array(6)
          .fill({})
          .map((_, rowIndex) => (
            <div key={`skeleton-button-${rowIndex}-div`}>
              <Skeleton containerClassName="w-full" key={`skeleton-button-${rowIndex}`} width={130} height={30} />
            </div>
          ))}
      </div>
      <div className="flex flex-col gap-4 mt-8">
        <Skeleton containerClassName="w-full" key={`skeleton-historic-title`} width="30%" height={20} />
        {Array(10)
          .fill({})
          .map((_, rowIndex) => (
            <Skeleton containerClassName="w-full" key={`skeleton-historic-row-${rowIndex}`} width="100%" height={30} />
          ))}
      </div>
    </div>
  );
};

export default ProposalPreviewContainerSkeleton;
