import { useContextSelector } from 'use-context-selector';

import { ContractedDemandContext } from '@contexts/contracted-demand';
import { IContractedDemandContext } from '@schemas/contracted-demand/types';

const useContractedDemand = (): IContractedDemandContext => {
  const createContractedDemandSimulation = useContextSelector(
    ContractedDemandContext,
    (contractedDemand) => contractedDemand.createContractedDemandSimulation,
  );

  return {
    createContractedDemandSimulation,
  };
};

export default useContractedDemand;
