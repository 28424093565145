import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useAuth } from '@src/auth-wrapper';

import { ISimulationPayloadBase } from '@schemas/types';

import { yearsList, yearsListCommercialDealer } from '@utils/constants';

import { RadioFormField } from '@components/atoms/form/fields/radio-form-field';
import { IGeneralFormSelectableInputsOptions } from '@components/atoms/form/types';

import validateSimulatorFormFields from '../validate-simulator-form-fields';

import { SimulatorFormTooltipsContentIndexer as tooltipsContent } from '../static-data';

interface ISimulatorFormTargetYearsSection {
  outputAsArray?: boolean;
}

const SimulatorFormTargetYearsSection = ({ outputAsArray }: ISimulatorFormTargetYearsSection): JSX.Element => {
  const {
    clearErrors,
    getValues,
    setError,
    setValue,
    watch,
    formState: { isDirty, defaultValues, errors },
  } = useFormContext<ISimulationPayloadBase>();

  const [targetYears, setTargetYears] = React.useState<Array<number>>(
    (defaultValues?.targetYears as Array<number>) ?? [],
  );
  const [targetYear, setTargetYear] = React.useState<number | undefined>(defaultValues?.targetYear);
  const [inputOptions, setInputsOptions] = React.useState<Array<IGeneralFormSelectableInputsOptions>>();

  const { user } = useAuth();
  const getBaseYearsListForSimulation = () => {
    const baseYearsListForSimulation: Array<number> = user?.isCommercialDealer ? yearsListCommercialDealer : yearsList;
    return baseYearsListForSimulation;
  };

  const getInputOptions = (updatedInputValue?: Array<number> | number): Array<IGeneralFormSelectableInputsOptions> => {
    const baseYearsListForSimulation = getBaseYearsListForSimulation();

    return baseYearsListForSimulation.map((year) => {
      const parsedUpdatedInputValueAsNumber = !outputAsArray ? (updatedInputValue as number) : undefined;
      const parsedUpdatedInputValueAsList = outputAsArray ? (updatedInputValue as Array<number>) : undefined;

      const isDefaultChecked = outputAsArray
        ? parsedUpdatedInputValueAsList?.find((defaultYear) => defaultYear === year) !== undefined
        : parsedUpdatedInputValueAsNumber === year;

      const parsedYear = year.toString();
      return {
        id: parsedYear,
        optionLabel: `${parsedYear} ano(s)`,
        value: parsedYear,
        defaultChecked: isDefaultChecked,
      };
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedYear = parseInt(event.target.value);
    if (event.target.checked) {
      if (outputAsArray) setTargetYears([selectedYear]);
      else setTargetYear(selectedYear);
    }
  };

  const conditionalFieldName: keyof ISimulationPayloadBase = outputAsArray ? 'targetYears' : 'targetYear';
  const fieldWatch = watch(conditionalFieldName);

  React.useEffect(() => {
    const updatedInputValue = getInputOptions(fieldWatch);
    setInputsOptions(updatedInputValue);

    if (isDirty) {
      const values = getValues();

      validateSimulatorFormFields({
        ...{ values, setError, clearErrors },
        onlyFields: [conditionalFieldName],
      });
    }
  }, [fieldWatch]);

  React.useEffect(() => {
    if (!outputAsArray) setValue('targetYear', targetYear);
    else setValue('targetYears', targetYears);
  }, [targetYears, targetYear]);

  const errorMessage = outputAsArray ? errors.targetYears?.message : errors.targetYear?.message;
  const errorClassname = errorMessage ? 'border-1 border-danger-60' : '';

  return (
    <div className={`flex flex-col bg-neutral-10 mt-6 pt-4 pb-6 px-6 rounded-medium ${errorClassname}`}>
      {inputOptions && (
        <RadioFormField<ISimulationPayloadBase>
          key="simulator-form-target-year-radio"
          label="Grupo de Anos para simulação"
          field={outputAsArray ? 'targetYears' : 'targetYear'}
          inputOptions={inputOptions}
          options={{
            onChange: handleInputChange,
            required: true,
          }}
          tooltipContent={tooltipsContent.targetYear}
        />
      )}
      {errorMessage && <p className="text-paragraph-small text-danger-60 mt-4 -mb-2">{errorMessage}</p>}
    </div>
  );
};

export default SimulatorFormTargetYearsSection;
