import { twMerge } from 'tailwind-merge';
import ClarkeLogo from '@assets/images/clarke-logo-minimal.svg';
import {
  ISimplifiedProposalHeaderContent,
  SimplifiedProposalHeaderContent,
} from '@components/atoms/simplified-proposal/simplified-proposal-header-content';
import useWhiteLabel from '@hooks/use-white-label';
import { validationDatePricesExpirationDate } from '@utils/datetime';

interface ISimplifiedProposalHeader extends ISimplifiedProposalHeaderContent {
  leadName?: string | { name: string };
  proposalValidity?: string;
}

const SimplifiedProposalHeader = ({
  totalSaving,
  totalSavingByMonth,
  savingPercentage,
  commercialStage,
  leadName,
  proposalValidity,
}: ISimplifiedProposalHeader) => {
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();
  const lead = (leadName as { name: string })?.name || leadName;
  return (
    <div className="relative col-span-1 xl:col-span-4 w-full h-full rounded-large grid grid-cols-1 md:grid-cols-2 xl:gap-5 bg-white items-center">
      <div
        className={twMerge(
          'col-span-1 w-full h-full rounded-b-3xl md:rounded-r-[1000px] md:rounded-l-[100px] flex flex-row md:flex-col items-center md:items-start gap-6 xl:flex-col xl:gap-9 px-5 pt-9 md:pt-24 xl:pt-9 pb-56 md:pb-0',
          isWhiteLabelProposal ? 'bg-white-label-main' : ' bg-primary-60',
        )}
      >
        {isWhiteLabelProposal ? (
          <img className="max-w-[40%] object-fill rounded-xl" src={whiteLabelSupplier?.presentation?.logoUrl} />
        ) : (
          <img className="w-8 h-8 md:w-[3.75rem] md:h-[3.75rem] rounded-full border-1 border-black" src={ClarkeLogo} />
        )}
        <div className="ml-5 mb-8 flex flex-col justify-center items-start">
          <h1 className="text-paragraph-medium md:text-heading-xsmall font-light">Proposta</h1>
          <h1 className="text-heading-small md:text-heading-2xlarge font-bold">{lead}</h1>
          {proposalValidity && (
            <p className="text-paragraph-medium hidden sm:block mt-5">
              Válida até {validationDatePricesExpirationDate(proposalValidity)}
            </p>
          )}
        </div>
      </div>
      <SimplifiedProposalHeaderContent
        totalSaving={totalSaving}
        totalSavingByMonth={totalSavingByMonth}
        savingPercentage={savingPercentage}
        commercialStage={commercialStage}
      />
    </div>
  );
};
export default SimplifiedProposalHeader;
