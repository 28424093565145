import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';

import config from '@config';

import { IContractedDemandContext, IContractedDemandSimulatorInputs } from '../../schemas/contracted-demand/types';
import { parseContractedDemandSimulationPayload } from './parser';

export const ContractedDemandContext = createContext({} as IContractedDemandContext);

interface Provider {
  children: React.ReactNode;
}

const ContractedDemandProvider: React.FC<Provider> = ({ children }) => {
  const calculatorApiClient = axios.create({
    baseURL: config.CALCULATOR_API_HOST,
    headers: {
      Authorization: `Apikey ${config.CALCULATOR_API_KEY}`,
    },
  });

  function createContractedDemandSimulation(simulationData: IContractedDemandSimulatorInputs): AxiosPromise {
    const simulationPayload = parseContractedDemandSimulationPayload(simulationData);
    return calculatorApiClient.post('/api/v2/power-demand', simulationPayload);
  }

  return (
    // @ts-expect-error -> generic error caused by external lib
    <ContractedDemandContext.Provider
      value={{
        createContractedDemandSimulation,
      }}
    >
      {children}
    </ContractedDemandContext.Provider>
  );
};

export default ContractedDemandProvider;
