import { UseFormReturn } from 'react-hook-form';
import { IWhiteLabelSupplierPayload } from '@schemas/white-label/types';
import { AddSupplierFormFooter } from '@components/molecules/form/white-label/add-supplier-form-footer';
import { WhiteLabelSupplierFormPresentationSection } from '@components/molecules/form/white-label/supplier-presentation';
import { WhiteLabelSupplierFormTypesSection } from '@components/molecules/form/white-label/supplier-types-section';
import { WhiteLabelSupplierFormDifferencesSection } from '@components/molecules/form/white-label/form-differences-section';
import { WhiteLabelSupplierFormPriceSection } from '@components/molecules/form/white-label/prices-section';
import { WhiteLabelSupplierFormUsersSection } from '@components/molecules/form/white-label/form-users-section';

interface IWhiteLabelSupplierFormCore {
  formController: UseFormReturn<IWhiteLabelSupplierPayload, any>;
  onSubmit: (values: IWhiteLabelSupplierPayload) => void;
}
export function WhiteLabelSupplierFormCore({ formController, onSubmit }: IWhiteLabelSupplierFormCore) {
  return (
    <form
      className="flex flex-col flex-wrap gap-5 justify-start relative"
      onSubmit={formController.handleSubmit(onSubmit)}
    >
      <WhiteLabelSupplierFormPresentationSection />
      <WhiteLabelSupplierFormTypesSection />
      <WhiteLabelSupplierFormDifferencesSection />
      <WhiteLabelSupplierFormPriceSection />
      <WhiteLabelSupplierFormUsersSection />
      <AddSupplierFormFooter />
    </form>
  );
}
