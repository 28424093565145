import { ColumnOptions, Tag, TagProps } from '@clarke-energia/foton';
import moment from 'moment';

import UserIcon from '@assets/images/user-icon.png';

import { ProductTwoSimulationStatusEnum } from '@schemas/product-two/types';
import { IProposalLead, LatestProposalMetadata, SimulatorTypeEnum } from '@schemas/types';

import { formatNumber } from '@utils/numeral';

import {
  SimulationsListCommercialGroup,
  SimulationsListSeller,
} from '@components/atoms/general/simulations-list-columns';

export const defaultTableColumns: Array<string> = ['lead', 'seller', 'createdAt', 'proposalsNumber'];
export const productTwoTableColumns: Array<string> = ['lead', 'seller', 'status', 'createdAt', 'proposalsNumber'];
export const commercialDealerTableColumnsForProductOne: Array<string> = [
  'lead',
  'createdAt',
  'commercialDealerComission',
];
export const commercialDealerTableColumnsForProductTwo: Array<string> = [
  'lead',
  'createdAt',
  'commercialDealerComission',
];

type ILatestProposalMetadataSimulator = 'productOne' | 'productTwo' | undefined;

const getLatestProposalMetadataSimulator = (simulatorType: keyof typeof SimulatorTypeEnum) => {
  const simulatorTypeIndexer: Record<keyof typeof SimulatorTypeEnum, ILatestProposalMetadataSimulator> = {
    CLARKE_MANAGEMENT: 'productOne',
    CLARKE_RETAIL: 'productTwo',
    CONTRACTED_DEMAND: undefined,
    WHITE_LABEL: undefined,
  };

  return simulatorTypeIndexer[simulatorType];
};

export const columnsIndexer = (
  simulatorType: keyof typeof SimulatorTypeEnum,
): Record<string, ColumnOptions<IProposalLead>> => {
  const simulatorName = getLatestProposalMetadataSimulator(simulatorType);

  const getLatestProposalMetadata = (entry: IProposalLead): LatestProposalMetadata | undefined => {
    return (
      entry.simulatorsIndexedMetadata &&
      simulatorName &&
      entry.simulatorsIndexedMetadata[simulatorName]?.latestProposalMetadata
    );
  };

  const getLatestProposalsNumber = (entry: IProposalLead): number | undefined => {
    return (
      (entry.simulatorsIndexedMetadata &&
        simulatorName &&
        entry.simulatorsIndexedMetadata[simulatorName]?.proposalsNumber) ??
      0
    );
  };

  const getLastProposalStatusTagColor = (proposalStatus?: keyof typeof ProductTwoSimulationStatusEnum) => {
    const tagColorIndexer: Record<keyof typeof ProductTwoSimulationStatusEnum, TagProps['color']> = {
      DONE: 'green',
      IN_PROGRESS: 'yellow',
      FAILED: 'red',
      IN_QUEUE: 'gray',
    };

    return (proposalStatus && tagColorIndexer[proposalStatus]) ?? tagColorIndexer['FAILED'];
  };

  const renderTagElementForProposal = (entry: IProposalLead) => {
    const latestProposalMetadata = getLatestProposalMetadata(entry);
    if (!latestProposalMetadata) return <></>;

    const color = getLastProposalStatusTagColor(
      latestProposalMetadata?.status as keyof typeof ProductTwoSimulationStatusEnum,
    );
    const label =
      ProductTwoSimulationStatusEnum[latestProposalMetadata?.status as keyof typeof ProductTwoSimulationStatusEnum] ??
      'Indisponível';

    return <Tag kind="default" color={color} label={label} />;
  };

  return {
    lead: {
      accessor: 'id',
      header: 'Grupo Comercial',
      renderAsElement: (entry) => (
        <SimulationsListCommercialGroup
          leadName={entry.name as string}
          description={getLatestProposalMetadata(entry)?.description as string}
        />
      ),
    },
    seller: {
      header: 'Vendedor',
      renderAsElement: (entry) => {
        const hasSeller = getLatestProposalMetadata(entry)?.seller;
        const imageUrl = hasSeller ? getLatestProposalMetadata(entry)?.seller?.pictureUrl : UserIcon;
        return <SimulationsListSeller pictureUrl={imageUrl} />;
      },
    },
    createdAt: {
      header: 'Data de Criação',
      renderAsElement: (entry): string => moment(getLatestProposalMetadata(entry)?.createdAt).format('DD/MM/YYYY'),
    },
    proposalsNumber: {
      header: 'N° de Propostas',
      renderAsElement: (entry): string => formatNumber(getLatestProposalsNumber(entry), '00'),
    },
    simulationsNumber: {
      header: 'N° de Simulações',
      renderAsElement: (entry): string => formatNumber(getLatestProposalsNumber(entry), '00'),
    },
    commercialDealerComission: {
      header: 'Comissão estimada',
      renderAsElement: (entry): string =>
        formatNumber(getLatestProposalMetadata(entry)?.commercialDealerComission ?? 0, '$ 0,0.00'),
    },
    status: {
      header: 'Status',
      renderAsElement: (entry) => renderTagElementForProposal(entry),
    },
  };
};
