import z from 'zod';
import {
  WhiteLabelSupplierAddSchema,
  WhiteLabelSupplierColorsSchema,
  WhiteLabelSupplierDifferencesSchema,
  WhiteLabelSupplierEditSchema,
  WhiteLabelSupplierPresentationSchema,
  WhiteLabelSupplierPricesSchema,
  WhiteLabelSupplierUsersSchema,
} from '.';
export enum PricesTypesEnum {
  migration = 'Preço padrão de migração',
  management = 'Preço padrão de gestão',
  discount = 'Desconto padrão',
}
export enum WhiteLabelSupplierTypeEnum {
  MANAGER = 'Gestora',
  TRADER = 'Comercializadora',
  HYBRID = 'Hibrido',
}
export enum WhiteLabelSupplierStatusEnum {
  INCOMPLETE_REGISTRATION = 'INCOMPLETE_REGISTRATION',
  COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION',
}

export interface IWhiteLabelSupplier extends Omit<IWhiteLabelSupplierPayload, 'fileList'> {
  id: string;
  status: WhiteLabelSupplierStatusEnum;
}

export type IWhiteLabelSupplierColors = z.infer<typeof WhiteLabelSupplierColorsSchema>;
export type IWhiteLabelSupplierPrices = z.infer<typeof WhiteLabelSupplierPricesSchema>;
export type IWhiteLabelSupplierDifferences = z.infer<typeof WhiteLabelSupplierDifferencesSchema>;
export type IWhiteLabelSupplierUser = z.infer<typeof WhiteLabelSupplierUsersSchema>;
export type IWhiteLabelSupplierPresentation = z.infer<typeof WhiteLabelSupplierPresentationSchema>;
export type IWhiteLabelSupplierAdd = z.infer<typeof WhiteLabelSupplierAddSchema>;
export type IWhiteLabelSupplierEdit = z.infer<typeof WhiteLabelSupplierEditSchema>;
export type IWhiteLabelSupplierPayload = z.infer<typeof WhiteLabelSupplierAddSchema>;
