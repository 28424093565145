import { IEnergyTypeComparisonItem } from '@schemas/product-two/types';

interface IEnergyType {
  i50: IEnergyTypeComparisonItem;
  i100: IEnergyTypeComparisonItem;
}

const legends = {
  renewableEnergy: 'Energia de fontes renováveis.',
  discount: {
    i50: '50% de desconto nos custos associados à utilização da rede de distribuição de energia',
    i100: '100% de desconto nos custos de utilização da rede de distribuição de energia',
  },
  advantageous: {
    i50: 'Vantajoso para empresas que possuem um consumo alto',
    i100: 'Vantajoso para empresas que possuem uma demanda contratada alta e um baixo consumo de energia',
  },
};
export function getIconsList(energyType: keyof IEnergyType) {
  return [
    { iconName: 'wind', description: legends.renewableEnergy },
    { iconName: 'dolar', description: legends.discount[energyType] },
    { iconName: 'ligthBulb', description: legends.advantageous[energyType] },
  ];
}
