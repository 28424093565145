import { ISimulationPayloadBase, ISimulationUnitPayloadBase } from '@schemas/types';

import { calculateManagementPriceSuggestion } from '@components/atoms/form/simulation-units-section/management-price-suggestion';

import { calculateObjectValuesAverage, isObjectEmpty } from '@utils/common';
import { TARIFF } from '@utils/constants';

export const isNumber = (value: any) => {
  return typeof value === 'number' && !isNaN(value);
};

const consumptionHistoryRecordFiller = (
  consumptionHistoryAverage: number,
  consumptionHistory?: Record<number, number | null>,
) => {
  if (!consumptionHistory) return null;

  const filledObject: Record<string, number | null> = {};
  for (let index = 1; index <= 12; index++) {
    filledObject[index.toString()] = isNumber(consumptionHistory[index] as number)
      ? consumptionHistory[index]
      : consumptionHistoryAverage;
  }
  return filledObject;
};

export const enforceUnitsManagementPriceSuggestion = (units: ISimulationPayloadBase['units']) => {
  return units?.map((unit) => {
    const { consumptionOffPeak, consumptionPeak } = unit;
    const managementPriceSuggestion = calculateManagementPriceSuggestion(consumptionOffPeak, consumptionPeak);

    return { ...unit, managementPrice: managementPriceSuggestion ?? unit.managementPrice };
  });
};

export const parseUnitsConsumptionHistory = (units: ISimulationPayloadBase['units'], unifiedConsumption?: boolean) =>
  units?.map((unit) => {
    const isLowTension = unit.tariff === TARIFF.B3_OPTING || unit.tariff === TARIFF.B3_STANDARD;
    const paredConsumptionHistory = isLowTension
      ? parseUnitConsumptionHistory(unit, true)
      : parseUnitConsumptionHistory(unit, unifiedConsumption);
    return paredConsumptionHistory;
  });

export const parseUnitConsumptionHistory = (unit: ISimulationUnitPayloadBase, unifiedConsumption?: boolean) => {
  if (unifiedConsumption) {
    const consumptionHistory = unit.consumptionLowVoltage as Record<number, number | null>;
    if (!isObjectEmpty(consumptionHistory)) {
      const consumptionHistoryAverage = calculateObjectValuesAverage(consumptionHistory);
      const filledConsumptionHistory = consumptionHistoryRecordFiller(
        consumptionHistoryAverage as number,
        consumptionHistory,
      );

      unit.consumptionLowVoltage = filledConsumptionHistory ?? undefined;
    } else {
      unit.consumptionLowVoltage = {};
    }
  } else {
    const consumptionPeakHistory = unit.consumptionPeak as Record<number, number | null>;
    const consumptionOffPeakHistory = unit.consumptionOffPeak as Record<number, number | null>;

    if (!isObjectEmpty(consumptionPeakHistory)) {
      const consumptionPeakHistoryAverage = calculateObjectValuesAverage(consumptionPeakHistory);
      const filledConsumptionPeakHistory = consumptionHistoryRecordFiller(
        consumptionPeakHistoryAverage as number,
        consumptionPeakHistory,
      );

      unit.consumptionPeak = filledConsumptionPeakHistory ?? undefined;
    } else {
      unit.consumptionPeak = {};
    }

    if (!isObjectEmpty(consumptionOffPeakHistory)) {
      const consumptionOffPeakHistoryAverage = calculateObjectValuesAverage(consumptionOffPeakHistory);
      const filledConsumptionOffPeakHistory = consumptionHistoryRecordFiller(
        consumptionOffPeakHistoryAverage as number,
        consumptionOffPeakHistory,
      );

      unit.consumptionOffPeak = filledConsumptionOffPeakHistory ?? undefined;
    } else {
      unit.consumptionOffPeak = {};
    }
  }

  return unit;
};
