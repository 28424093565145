import React from 'react';
import { useFormContext, UseFormResetField, useWatch } from 'react-hook-form';

import { NumericFormField } from './fields/numeric-form-field';

interface IManualInputsTargetYearRow {
  manualInputFieldPrefix?: string;
  manualInputFieldSuffix?: string;
  manualInputsFieldName: string;
  startYear: number;
  targetYear: number;
}

export const ManualInputsTargetYearRow = ({
  manualInputFieldPrefix,
  manualInputFieldSuffix,
  manualInputsFieldName,
  startYear,
  targetYear,
}: IManualInputsTargetYearRow) => {
  const { setValue, watch, resetField } = useFormContext();

  const [fieldNamesWithError, setFieldNamesWithError] = React.useState<Array<string>>();

  const targetYearFieldPath = `${manualInputsFieldName}.${targetYear}`;
  const currentManualInputs = useWatch({ name: manualInputsFieldName });
  const targetYears = watch('targetYears') ?? [];

  const NOT_VALID_PRICE_INPUT_ERROR_MESSAGE = 'Insira um preço';

  const handleFieldNamesWithErrorOnChange = (fieldName: string, value?: number) => {
    if (!value) {
      const updatedFieldNamesWithError = fieldNamesWithError ? [...fieldNamesWithError, fieldName] : [fieldName];
      setFieldNamesWithError(updatedFieldNamesWithError);
    } else {
      const updatedFieldNamesWithError = fieldNamesWithError?.filter((item) => item !== fieldName);
      setFieldNamesWithError(updatedFieldNamesWithError);
    }
  };

  const handleOnChange = (currentYear: number, value: number, fieldName: string) => {
    const currentManualInputsTargetYear = currentManualInputs[targetYear.toString()];

    const updatedManualInputsTargetYear = { ...currentManualInputsTargetYear, [currentYear]: value };
    const updatedManualInputsFieldValue = {
      ...currentManualInputs,
      [targetYear.toString()]: updatedManualInputsTargetYear,
    };

    setValue(manualInputsFieldName, updatedManualInputsFieldValue);
    handleFieldNamesWithErrorOnChange(fieldName, value);
  };

  const resetManualAclPriceField = (
    targetYears: number[],
    aclPriceInputs: number[],
    resetField: UseFormResetField<Record<string, any>>,
  ) => {
    const resetFieldsManualInputs: { [key: number]: number } = {};

    for (const value of targetYears) {
      resetFieldsManualInputs[value] = aclPriceInputs?.[value];

      if (targetYears.length > 0) {
        resetField('manualAclPrice', { defaultValue: resetFieldsManualInputs });
      } else {
        resetField('manualAclPrice', {
          defaultValue: {},
        });
      }
    }
  };

  const validateInputValueByFieldName = (fieldName: string) => {
    if (fieldNamesWithError?.find((value) => value === fieldName)) return NOT_VALID_PRICE_INPUT_ERROR_MESSAGE;
    else return undefined;
  };

  const formatProps = {
    mask: '_',
    allowNegative: false,
    fixedDecimalScale: false,
    decimalSeparator: ',',
    thousandSeparator: '.',
    decimalScale: 10,
    prefix: manualInputFieldPrefix,
    suffix: manualInputFieldSuffix,
  };

  React.useEffect(() => {
    resetManualAclPriceField(targetYears, currentManualInputs, resetField);
  }, [targetYears]);

  return (
    <div className="flex flex-col gap-2 pt-5 px-4 border-t-1 border-solid border-t-neutral-20">
      <p className="w-full text-paragraph-medium">{`Preços para ${targetYear} ano(s):`}</p>
      <div className="grid grid-cols-6 w-full gap-4">
        {[...Array(targetYear).keys()].map((yearlyProjection) => {
          const currentYear = startYear + yearlyProjection;
          const fieldName = `${targetYearFieldPath}.${currentYear}`;

          return (
            <div
              className="col-span-10 sm:col-span-1"
              key={`manual-prices-or-discounts-year-group-row-content-for-${yearlyProjection}-years`}
            >
              <NumericFormField
                id={fieldName}
                field={fieldName}
                label={currentYear.toString()}
                error={validateInputValueByFieldName(fieldName)}
                formatProps={formatProps}
                options={{
                  required: { value: true, message: NOT_VALID_PRICE_INPUT_ERROR_MESSAGE },
                  validate: (value) => value || NOT_VALID_PRICE_INPUT_ERROR_MESSAGE,
                  onChange: (value) => handleOnChange(currentYear, value as number, fieldName),
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
