import React from 'react';
import { Button } from '@clarke-energia/foton';
import { FormProvider, useForm } from 'react-hook-form';

import { SimulatorTypeEnum } from '@schemas/types';
import { SimulatorsInputsTranslator } from '@contexts/calculator/simulator-inputs-translator';
import { AutoProposalGenerator, ISimulatorHandlers } from '@contexts/calculator/auto-proposal-generator';

import useProductOne from '@hooks/use-product-one';
import useProductTwo from '@hooks/use-product-two';

import { isArrayEmpty } from '@utils/common';

import { CheckboxFormField } from '@components/atoms/form/fields/checkbox-form-field';
import SemanticMarquee from '@components/atoms/general/semantic-marquee';

import { getFeedbackMessageAndKind } from './feedback-messages';
import { getSimulatorsTypesInputOptions } from './helper';

interface IReuseSimulationForm {
  simulatorType: keyof typeof SimulatorTypeEnum;
  simulatorInputs: Record<string, any>;
}

const ReuseSimulationForm = ({ simulatorType, simulatorInputs }: IReuseSimulationForm) => {
  const { createProductOneSimulation, createProductOneProposal } = useProductOne();
  const { createProductTwoSimulation } = useProductTwo();

  const simulatorsHandlersIndexer: Record<keyof typeof SimulatorTypeEnum, ISimulatorHandlers | undefined> = {
    CLARKE_MANAGEMENT: {
      createSimulation: createProductOneSimulation,
      createProposal: createProductOneProposal,
    },
    CLARKE_RETAIL: {
      createSimulation: createProductTwoSimulation,
      createProposal: undefined,
    },
    CONTRACTED_DEMAND: undefined,
    WHITE_LABEL: {
      createSimulation: createProductOneSimulation,
      createProposal: createProductOneProposal,
    },
  };

  const [targetSimulators, setTargetSimulators] = React.useState<Array<keyof typeof SimulatorTypeEnum>>([]);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>();

  const formController = useForm({
    mode: 'all',
    defaultValues: {
      targetSimulators: undefined,
      state: undefined,
    },
  });

  const simulatorsTypesInputOptions = getSimulatorsTypesInputOptions(simulatorType);

  const formErrorsMessage = formController.formState.errors.targetSimulators?.message;
  const { feedbackMessage, feedbackKind } = getFeedbackMessageAndKind(success, loading, formErrorsMessage);

  const onSubmit = () => {
    setLoading(true);

    const simulatorsInputsTranslator = new SimulatorsInputsTranslator(simulatorInputs);
    const simulationsPromises = targetSimulators.map((selectedTargetSimulator) => {
      const parsedSimulationInputs = simulatorsInputsTranslator.translate(selectedTargetSimulator);

      return new Promise((resolve) => {
        const proposalAutoGenerator = new AutoProposalGenerator(parsedSimulationInputs, selectedTargetSimulator);
        const simulatorHandlers = simulatorsHandlersIndexer[selectedTargetSimulator] as ISimulatorHandlers;

        resolve(proposalAutoGenerator.create(simulatorHandlers));
      });
    });

    Promise.all(simulationsPromises)
      .then((response) => {
        const errorOccurredDuringGeneration = response.includes(false);
        setSuccess(errorOccurredDuringGeneration ? false : true);
      })
      .catch(() => setSuccess(false))
      .finally(() => setLoading(false));
  };

  const handleOnChangeSelectedTargetSimulatorsTypes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetSimulator = String(event.target.value) as keyof typeof SimulatorTypeEnum;
    if (event.target.checked) {
      setTargetSimulators([...targetSimulators, targetSimulator]);
    } else {
      setTargetSimulators(
        targetSimulators.filter((selectedTargetSimulator) => selectedTargetSimulator !== targetSimulator),
      );
    }
  };

  React.useEffect(() => {
    setSuccess(undefined);

    if (isArrayEmpty(targetSimulators)) {
      formController.setError('targetSimulators', { message: 'Selecione ao menos um simulador' });
    } else formController.clearErrors();
  }, [targetSimulators]);

  return (
    <FormProvider {...formController}>
      <form onSubmit={formController.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-7">
          <CheckboxFormField
            label="Quais simulações você quer gerar?"
            field="targetSimulators"
            inputOptions={simulatorsTypesInputOptions}
            options={{
              onChange: handleOnChangeSelectedTargetSimulatorsTypes,
              required: false,
            }}
            tooltipContent="Selecione pelo menos um simulador.
              Para simuladores que aceitam somente um grupo de anos, 
              o sistema selecionará o primeiro grupo escolhido."
          />
        </div>
        <div className="flex flex-col gap-4 w-full mt-8 -mb-4">
          {feedbackMessage && feedbackKind && <SemanticMarquee kind={feedbackKind}>{feedbackMessage}</SemanticMarquee>}
          <Button
            type="submit"
            kind="primary"
            label="Gerar Simulações"
            disabled={formErrorsMessage !== undefined}
            loading={loading}
            className="w-full"
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default ReuseSimulationForm;
