import { useFormContext } from 'react-hook-form';

import { ISimulationCompetitorPayloadBase } from '@schemas/types';

import { ISimulatorFormCompetitorsListItem } from '@components/molecules/form/simulator/sections/simulator-form-competitors-list-section/simulator-form-competitor-section';
import { calculateUnitSupplyStartDate } from '@components/molecules/form/simulator/default-values-getters';

import { NumericFormField } from '../fields/numeric-form-field';

export function SimulatorCompetitorEnergyPriceSubsection({ competitorIndex }: ISimulatorFormCompetitorsListItem) {
  const { setValue, watch } = useFormContext();

  const baseFieldName = `competitorsList.${competitorIndex.toString()}.aclPrices`;

  const currentManualInputs = watch(baseFieldName);

  const getTargetYears = () => {
    const uniqueTargetYear = watch('targetYear');
    const multipleTargetYears = watch('targetYears');

    const greaterValueInMultipleTargetYears =
      multipleTargetYears?.length >= 1 &&
      multipleTargetYears.sort(function (a: number, b: number) {
        return b - a;
      })[0];

    return uniqueTargetYear ?? greaterValueInMultipleTargetYears;
  };

  const targetYear = getTargetYears();

  const handleOnChange = (currentYear: string, value: number) => {
    const updatedManualInputs = { ...currentManualInputs, [currentYear]: value };

    setValue(baseFieldName, updatedManualInputs);
  };

  // TODO checar se essa lógica deve ser mantida
  const migrationYear = Number(calculateUnitSupplyStartDate(true, false));

  return (
    <div className="flex flex-col gap-1 bg-neutral-10 pt-4 pb-6 px-6 rounded-medium">
      <h1 className="text-paragraph-medium">Preço da energia</h1>
      <div
        className="flex flex-row justify-start gap-6"
        key={`competitorsList-${competitorIndex.toString()}-aclPrices`}
      >
        {[...Array(targetYear).keys()].map((yearlyProjection) => {
          const currentYear = migrationYear + yearlyProjection;
          const fieldNameForYear =
            `${baseFieldName}.${currentYear.toString()}` as keyof ISimulationCompetitorPayloadBase;

          return (
            <div
              className="w-[114px]"
              key={`competitorsList-${competitorIndex.toString()}-aclPrices-${currentYear.toString()}`}
            >
              <NumericFormField<ISimulationCompetitorPayloadBase>
                id={fieldNameForYear}
                field={fieldNameForYear}
                label={currentYear.toString()}
                formatProps={{
                  mask: '_',
                  allowNegative: false,
                  fixedDecimalScale: false,
                  decimalSeparator: ',',
                  thousandSeparator: '.',
                  decimalScale: 10,
                  prefix: 'R$ ',
                }}
                options={{
                  onChange: (value) => handleOnChange(currentYear.toString(), value as number),
                  required: { value: true, message: 'Insira um valor' },
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
