import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useAuth } from '@src/auth-wrapper';
import { ISimulationPayloadBase, SimulatorTypeEnum } from '@schemas/types';
import { getSimulatorCompetitorDefaultValues } from '@components/molecules/form/simulator/default-values-getters';
import { CheckboxFormField } from '@components/atoms/form/fields/checkbox-form-field';

import SimulatorFormCompetitorsListItem from './simulator-form-competitor-section';

interface ISimulatorFormCompetitorsListItem<T> {
  fieldsController?: Record<keyof T | string, boolean>;
  simulatorType: keyof typeof SimulatorTypeEnum;
}

function SimulatorFormCompetitorsListSection<T>({
  fieldsController,
  simulatorType,
}: ISimulatorFormCompetitorsListItem<T>) {
  const {
    setValue,
    formState: { errors },
  } = useFormContext<ISimulationPayloadBase>();
  const {
    fields: competitorsListFields,
    append,
    remove,
  } = useFieldArray<ISimulationPayloadBase>({
    name: 'competitorsList',
    rules: { minLength: 0, required: false },
  });
  const aux__useCompetitorList = useWatch({ name: 'aux__useCompetitorList' });
  const { user } = useAuth();
  const [showCompetitorSection, setShowCompetitorSection] = React.useState<boolean>(aux__useCompetitorList);

  const clearCompetitorSection = () => {
    return setValue('competitorsList', null);
  };

  const setupInitialValuesForCompetitorList = () => {
    const clarkeInitialSetupValues = getSimulatorCompetitorDefaultValues(simulatorType, true);
    append(clarkeInitialSetupValues);

    const competitorInitialSetupValues = getSimulatorCompetitorDefaultValues(simulatorType);
    append(competitorInitialSetupValues);
  };

  const competitorSectionHandler = () => {
    setShowCompetitorSection(aux__useCompetitorList);
    showCompetitorSection
      ? clearCompetitorSection()
      : competitorsListFields.length <= 1 && setupInitialValuesForCompetitorList();
    !showCompetitorSection && setValue('competitorsList', null);
  };
  React.useEffect(() => {
    competitorSectionHandler();
  }, [aux__useCompetitorList]);
  return (
    <>
      {!user?.isCommercialDealer && !user?.isWhiteLabel && (
        <div className="flex flex-col gap-10 mt-3 bg-neutral-10 pt-5 pb-5 mb-6 px-6 rounded-medium">
          <CheckboxFormField<ISimulationPayloadBase>
            field="aux__useCompetitorList"
            inputOptions={[
              {
                id: 'check-box-show-competitor-comparison',
                value: true,
                optionLabel: 'Comparativo com concorrente',
                defaultChecked: showCompetitorSection,
              },
            ]}
            errors={errors}
            label=""
          />
          {showCompetitorSection &&
            competitorsListFields.map((field, index) => (
              <SimulatorFormCompetitorsListItem
                key={field.id}
                competitorIndex={index}
                removeCompetitor={remove}
                appendCompetitor={append}
                fieldsController={fieldsController}
                competitorsNumber={competitorsListFields?.length}
                simulatorType={simulatorType}
              />
            ))}
        </div>
      )}
    </>
  );
}

export default SimulatorFormCompetitorsListSection;
