import React from 'react';

import { IProductTwoProposal } from '@schemas/product-two/types';
import useProductTwo from '@hooks/use-product-two';

import ProductTwoLeadProposalsHub from '@components/organisms/product-two/lead-proposals-hub';
import LeadsHistory from '@components/organisms/general/leads-history';

const ProductTwoLeadsHistory = (): JSX.Element => {
  const { deleteProductTwoAllProposalsByLeadIdHandler } = useProductTwo();

  const [selectedLeadId, setSelectedLeadId] = React.useState<string>();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  return (
    <LeadsHistory<IProductTwoProposal>
      simulatorType="CLARKE_RETAIL"
      deleteAllProposalsByLeadIdHandler={deleteProductTwoAllProposalsByLeadIdHandler}
      selectedLeadId={selectedLeadId}
      setSelectedLeadId={setSelectedLeadId}
      setIsDrawerOpen={setIsDrawerOpen}
    >
      <>
        {isDrawerOpen && selectedLeadId && (
          <ProductTwoLeadProposalsHub setIsDrawerOpen={setIsDrawerOpen} leadId={selectedLeadId} />
        )}
      </>
    </LeadsHistory>
  );
};

export default ProductTwoLeadsHistory;
