import { useEffect, useState } from 'react';
import useProductOne from '@hooks/use-product-one';
import useProductTwo from '@hooks/use-product-two';
import { IProductOneProposal } from '@contexts/product-one/types';
import { IProductTwoProposal } from '@schemas/product-two/types';

import LegendContainer from '@components/atoms/new-proposal/legend-container';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import UnitsSavingTable from '@components/molecules/new-proposal/feasibility-analysis/table';

import useWhiteLabel from '@hooks/use-white-label';
import { dataForUnitsSaving } from './helper';

function UnitsSaving() {
  const { proposal: managementProposal } = useProductOne();
  const { proposal: retailProposal } = useProductTwo();
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();
  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  const isRetailPath = window.location.pathname.includes('clarke-varejo');

  const [proposal, setProposal] = useState<
    | IProductOneProposal['calculatorMetadata']['firstResult']
    | IProductTwoProposal['calculatorMetadata']['bestRetailTraderResult']['calculatorMetadata']
  >();
  const [targetYear, setTargetYear] = useState<number>();

  useEffect(() => {
    if (isManagementPath && managementProposal) {
      setProposal(managementProposal.calculatorMetadata?.firstResult);

      const firstTargetYear = managementProposal?.inputs?.targetYears && managementProposal?.inputs?.targetYears[0];
      setTargetYear(firstTargetYear as number);
    } else if (isRetailPath && retailProposal) {
      setProposal(retailProposal?.calculatorMetadata?.bestRetailTraderResult?.calculatorMetadata);

      const targetYear = retailProposal?.inputs?.targetYear;
      setTargetYear(targetYear);
    }
  }, [managementProposal, retailProposal, isManagementPath, isRetailPath]);

  const unitsReports = proposal?.unitsReports || [];
  const unitsSaving = dataForUnitsSaving(unitsReports);
  const isVisible = unitsReports.length >= 2;

  return (
    <SlideContainer className="h-fit xl:min-h-screen xl:px-20" isVisible={isVisible}>
      <section className="flex flex-col gap-5 w-full h-fit min-h-full py-8 px-8 sm:px-10 ">
        <LegendContainer isWhiteLabelProposal={isWhiteLabelProposal} legend="Próximos passos" />
        <div className="w-full flex flex-col gap-6 sm:gap-10 items-start sm:items-center xl:items-start">
          <div className="w-full flex flex-col sm:gap-4">
            <span className="text-heading-xsmall xl:text-heading-large text-[#222222]">
              <b
                style={{
                  color: isWhiteLabelProposal ? whiteLabelSupplier?.colors?.main : '#00DF7C',
                }}
                className="text-heading-medium xl:text-heading-xlarge mt-2 sm:break-normal"
              >
                Resumo das unidades consumidoras
              </b>
              {targetYear && (
                <p className="text-paragraph-small sm:text-heading-xsmall text-justify w-64 sm:w-full">
                  Proposta de economia em {targetYear} anos
                </p>
              )}
            </span>
          </div>
          {<UnitsSavingTable unitsSaving={unitsSaving} />}
        </div>
      </section>
    </SlideContainer>
  );
}

export default UnitsSaving;
