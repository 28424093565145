import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { IContractedDemandSimulatorInputs } from '@schemas/contracted-demand/types';

import SimulatorFormUnitsSection from '@components/molecules/form/simulator/sections/simulator-form-units-section';

interface IContractedDemandSimulatorFormCore {
  formController: UseFormReturn<IContractedDemandSimulatorInputs, any>;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onSubmit: (values: IContractedDemandSimulatorInputs) => void;
}

const ContractedDemandSimulatorFormCore = ({
  formController,
  formRef,
  onSubmit,
}: IContractedDemandSimulatorFormCore) => {
  const selectedTariff = useWatch({ name: 'units.0.tariff' });
  const shouldShowConsumptionPeak = selectedTariff ? selectedTariff === 3 || selectedTariff === 4 : false;

  return (
    <form
      ref={formRef}
      onSubmit={formController.handleSubmit(onSubmit)}
      className="flex flex-col flex-wrap w-full gap-3 justify-start"
    >
      <SimulatorFormUnitsSection<IContractedDemandSimulatorInputs>
        fieldsController={{
          aclType: false,
          submarket: false,
          proposalValidity: false,
          supplyStartDate: false,
          generatorCost: false,
          initialExpenses: false,
          managementPrice: false,
          consumptionPeak: shouldShowConsumptionPeak,
        }}
        unitary
      />
    </form>
  );
};

export default ContractedDemandSimulatorFormCore;
