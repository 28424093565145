import React from 'react';
import numeral from 'numeral';
import { clone } from 'lodash';
import { ColumnOptions } from '@clarke-energia/foton';

import { IProposalEconomyProjectionTableData, ISimulationExtendedResultBase } from '../schemas/types';
import { IProductOneSimulatorInputs } from '../schemas/product-one/types';
import { IProductTwoSimulatorInputs } from '../schemas/product-two/types';
interface IEconomyProjectionTableData {
  item: JSX.Element;
  investment: string;
  total: string;
  2023?: string;
  2024?: string;
  2025?: string;
  2026?: string;
  2027?: string;
  2028?: string;
  2029?: string;
  2030?: string;
}

const parseProposalEconomyProjectionTableColumnsDefinition = (
  simulationResult: ISimulationExtendedResultBase,
): Array<ColumnOptions<IEconomyProjectionTableData>> => {
  const columnDefinitions: ColumnOptions<IEconomyProjectionTableData>[] = [
    { accessor: 'item', header: '   ', renderAsElement: (value: any): string => value.item },
    {
      accessor: 'investment',
      header: 'Investimento¹',
      renderAsElement: (value: any): string => value.investment,
    },
    { accessor: 'total', header: 'Total', renderAsElement: (value: any): string => value.total },
  ];

  simulationResult.simulationResultYearsList?.map((year: string) =>
    columnDefinitions.splice(-1, 0, {
      accessor: year.toString() as keyof IEconomyProjectionTableData,
      header: year.toString(),
      renderAsElement: (value: any): string => value[year.toString()],
    }),
  );

  return columnDefinitions;
};

export interface IEconomyProjectionTable {
  columnDefinitions: Array<ColumnOptions<IEconomyProjectionTableData>>;
  data: Array<IEconomyProjectionTableData>;
  superheader: string;
}

export const parseProposalEconomyProjectionTable = (
  simulationResult: ISimulationExtendedResultBase,
  isUnitAtHeaderVersion?: boolean,
): IEconomyProjectionTable => {
  const parsedEconomyProjectionTableData = parseProposalEconomyProjectionTableData(
    simulationResult,
    isUnitAtHeaderVersion,
  );

  const columnDefinitions = parseProposalEconomyProjectionTableColumnsDefinition(simulationResult);

  const parsedEconomyProjectionTable = {
    columnDefinitions,
    data: parsedEconomyProjectionTableData,
    superheader: isUnitAtHeaderVersion ? 'Detalhamento anual  (em R$)' : 'Detalhamento anual',
  };

  return parsedEconomyProjectionTable as IEconomyProjectionTable;
};

export const parseProposalEconomyProjectionTableData = (
  simulationResult: ISimulationExtendedResultBase,
  isUnitAtHeaderVersion?: boolean,
): Array<IProposalEconomyProjectionTableData> => {
  const parsedTotalTargetSpentByYear: Record<string, string> = {};
  const parsedTotalCurrentSpentByYear: Record<string, string> = {};
  const parsedTotalSavingByYear: Record<string, string> = {};

  simulationResult.simulationResultYearsList?.forEach((year) => {
    parsedTotalTargetSpentByYear[year] = numeral(simulationResult.targetSpentByYear[year]).format(
      isUnitAtHeaderVersion ? '0,0.00' : '$ 0,0.00',
    );
    parsedTotalCurrentSpentByYear[year] = numeral(simulationResult.currentSpentByYear[year]).format(
      isUnitAtHeaderVersion ? '0,0.00' : '$ 0,0.00',
    );
    parsedTotalSavingByYear[year] = numeral(simulationResult.savingByYear[year]).format(
      isUnitAtHeaderVersion ? '0,0.00' : '$ 0,0.00',
    );
  });

  const parsedEconomyProjectionTableData = [
    {
      item: (
        <div className="flex align-middle justify-start content-center pl-1 gap-[4px] pt-1">
          <div className="flex bg-neutral-80 rounded-lg w-[16px] h-[16px] self-center" />
          <div className="flex">{isUnitAtHeaderVersion ? 'ACR' : 'Mercado Cativo'}</div>
        </div>
      ),
      investment: '-',
      total: numeral(simulationResult.currentSpent).format(isUnitAtHeaderVersion ? '0,0.00' : '$ 0,0.00'),
    },
    {
      item: (
        <div className="flex align-middle justify-start content-center pl-1 gap-[4px]">
          <div className="flex bg-primary-60 rounded-lg w-[16px] h-[16px] self-center" />
          <div className="flex">{isUnitAtHeaderVersion ? 'ACL' : 'Mercado Livre'}</div>
        </div>
      ),
      investment: numeral(simulationResult.initialExpenses).format(isUnitAtHeaderVersion ? '0,0.00' : '$ 0,0.00'),
      total: numeral(simulationResult.targetSpent).format(isUnitAtHeaderVersion ? '0,0.00' : '$ 0,0.00'),
    },
    {
      item: (
        <div className="flex align-middle justify-start content-center pl-1 gap-[4px]">
          <div className="flex bg-white rounded-lg w-[16px] h-[16px] border-1 border-black self-center" />
          <div className="flex">Economia</div>
        </div>
      ),
      investment: numeral(simulationResult.initialExpenses).format(isUnitAtHeaderVersion ? '0,0.00' : '$ 0,0.00'),
      total: numeral(simulationResult.saving).format(isUnitAtHeaderVersion ? '0,0.00' : '$ 0,0.00'),
    },
  ];

  Object.assign(parsedEconomyProjectionTableData[0], parsedTotalCurrentSpentByYear);
  Object.assign(parsedEconomyProjectionTableData[1], parsedTotalTargetSpentByYear);
  Object.assign(parsedEconomyProjectionTableData[2], parsedTotalSavingByYear);

  return parsedEconomyProjectionTableData;
};

export const parseProposalEconomyProjectionTableColumns = (simulationResultYearsList: Array<string>) => {
  const tableColumns = [
    {
      key: 'item',
      label: '   ',
      render: (value: React.FC): React.FC => value,
    },
    {
      key: 'investment',
      label: 'Investimento',
      render: (value: any): string => value,
    },
    {
      key: 'total',
      label: 'Total',
      render: (value: any): string => value,
    },
  ];
  simulationResultYearsList?.map((year: string) =>
    tableColumns.splice(-1, 0, {
      key: year.toString(),
      label: year.toString(),
      render: (value: React.FC): React.FC => value,
    }),
  );
  return tableColumns;
};

export const parseFinancialProjectionPayload = (financialProjection?: any, asPercentage?: boolean) => {
  if (!financialProjection) return;
  const parsedFinancialProjection = clone(financialProjection);
  Object.keys(financialProjection).forEach((targetYear) => {
    financialProjection[targetYear] &&
      Object.entries(financialProjection[targetYear]).forEach((entry) => {
        const year = entry[0];
        const value = entry[1];
        if (!(value && isFinite(value as number))) {
          const parsedValueInPercentage = asPercentage ? (value as number) / 100 : value;
          parsedFinancialProjection[targetYear][year] = parsedValueInPercentage;
        }
      });
  });
  return parsedFinancialProjection;
};

export const parseEnergyPrices = (
  energyPrices?: Record<string, Record<string, string | number>>,
  targetYear?: number,
): Array<Record<string, string>> | undefined => {
  if (energyPrices && targetYear) {
    return Object.entries(energyPrices[targetYear]).map((entry) => ({
      title: entry[0].toString(),
      value: numeral(entry[1]).format('$ 0,0.00'),
    }));
  }
};

export const getParsedCustomFee = (simulationData: IProductOneSimulatorInputs | IProductTwoSimulatorInputs) => {
  if (simulationData.aux__useManualAclPrice) {
    return null;
  } else if (simulationData.customFee?.type) {
    const parsedCustomFee = { ...simulationData.customFee };
    // @ts-expect-error - This is a workaround to avoid the need to create a new type for customFee
    delete parsedCustomFee.auxiliarFields;

    if (parsedCustomFee.type == 'PERCENTAGE' && simulationData.customFee?.values) {
      const customFeeValues = { ...simulationData.customFee.values };
      for (const year in customFeeValues) {
        // eslint-disable-next-line no-prototype-builtins
        if (customFeeValues.hasOwnProperty(year)) {
          customFeeValues[year] *= 0.01;
        }
      }
      parsedCustomFee.values = customFeeValues;
    }
    return parsedCustomFee;
  }
};
