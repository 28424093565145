import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { IProductTwoSimulatorInputs } from '@schemas/product-two/types';
import { NumericFormField } from '@components/atoms/form/fields/numeric-form-field';

export const SimulatorFormPremisesCustomFeeByYearSection = ({
  year,
  customFeeType,
  isMarginUnique,
}: {
  year: number;
  customFeeType: 'ABSOLUTE' | 'PERCENTAGE' | undefined;
  isMarginUnique: boolean | undefined;
}) => {
  const { watch, setValue } = useFormContext<IProductTwoSimulatorInputs>();

  const uniqueMargin = watch('customFee.auxiliarFields.uniqueMargin');
  const fieldName = `customFee.values.${year}`;
  const customFeeValues = useWatch({ name: 'customFee.values' });
  const handleOnChange = (year: number, value: number) => {
    const currentCustomFeeValues = { ...customFeeValues };
    setValue('customFee.values', { ...currentCustomFeeValues, [year]: value });
  };

  React.useEffect(() => {
    if (isMarginUnique) {
      setValue(`customFee.values.${year}`, uniqueMargin as number);
    }
  }, [uniqueMargin, isMarginUnique]);

  return (
    <NumericFormField<IProductTwoSimulatorInputs>
      key={fieldName}
      id={fieldName}
      field={fieldName as keyof IProductTwoSimulatorInputs}
      label={year.toString()}
      formatProps={{
        allowNegative: false,
        fixedDecimalScale: false,
        decimalSeparator: ',',
        thousandSeparator: '.',
        decimalScale: 1000,
        prefix: customFeeType === 'ABSOLUTE' ? 'R$ ' : '',
        suffix: customFeeType === 'PERCENTAGE' ? '%' : '',
      }}
      options={{
        required: isMarginUnique ? false : true,
        onChange: (value) => handleOnChange(year, value as number),
      }}
      disabled={isMarginUnique}
    />
  );
};
