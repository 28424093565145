import LegendContainer from '@components/atoms/new-proposal/legend-container';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import { CardsSection, DescriptionText, LogosSection } from './helpers';
import './styles.css';

export function AboutEnergisaSection() {
  return (
    <SlideContainer className="min-h-fit xl:min-h-screen xl:h-fit py-0">
      <section className="exclusive-energisa-section">
        <div className="exclusive-energisa-logos-and-legend">
          <LogosSection />
          <LegendContainer legend="Sobre" className="sm:mt-5" />
        </div>
        <div className="exclusive-energisa-description-and-cards my-auto">
          <DescriptionText />
          <CardsSection />
        </div>
      </section>
    </SlideContainer>
  );
}
