import { z } from 'zod';
import { simulationPayloadBaseSchema } from '@schemas';
import { validateProductOneSimulatorInputs } from '@schemas/validations';
const productOneSimulatorInputsSchema = z.object({
  useIRecCertification: z.boolean().optional(),
});

export const productOneSimulatorSchema = productOneSimulatorInputsSchema
  .merge(simulationPayloadBaseSchema)
  .superRefine((data, ctx) => {
    validateProductOneSimulatorInputs(data, ctx);
  });
