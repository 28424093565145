import { ISimulationUnitPayloadBase } from '@schemas/types';

export const getsupplyStartDateList = (units: ISimulationUnitPayloadBase[]) => {
  return units.map(function (unit) {
    return Number(unit.supplyStartDate?.slice(-4));
  });
};

export const fullfillMissingYears = (yearsRange: number[]) => {
  const parsedYearRange = [];
  yearsRange.sort();

  for (let i = yearsRange[0]; i <= yearsRange[yearsRange.length - 1]; i++) {
    parsedYearRange.push(i);
  }
  return parsedYearRange;
};

const filterDuplicates = (supplyStartDateList: number[]) => {
  return supplyStartDateList.filter((item, index) => {
    return supplyStartDateList.indexOf(item) === index;
  });
};

const addTargetYearsTosupplyStartDateList = (fullfilldedsupplyStartDateList: number[], targetYear: number) => {
  const parsedYearsRange = [...fullfilldedsupplyStartDateList];
  for (let i = 1; i < targetYear; i++) {
    parsedYearsRange.push(fullfilldedsupplyStartDateList[fullfilldedsupplyStartDateList?.length - 1] + i);
  }
  return parsedYearsRange;
};

export const getYearsRange = (supplyStartDateList: number[], targetYear: number) => {
  const filteredsupplyStartDateList = filterDuplicates(supplyStartDateList);
  const fullfilldedsupplyStartDateList = fullfillMissingYears(filteredsupplyStartDateList);

  const parsedYearsRange = addTargetYearsTosupplyStartDateList(fullfilldedsupplyStartDateList, targetYear);
  return parsedYearsRange;
};
