/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import { IEconomyProjectionTable, parseProposalEconomyProjectionTable } from '@contexts/parser';
import {
  IProductTwoSimulationExtendedResult,
  IProductTwoSimulationUnitExtendedResult,
} from '@schemas/product-two/types';
import { IProductOneSimulationExtendedResult } from '@contexts/product-one/types';

import PremisesTableColumns from '@components/atoms/simulation/premises-table/premises-table-columns';
import PremisesTableHeaders from '@components/atoms/simulation/premises-table/premises-table-headers';
import PricesTableColumns from '@components/atoms/simulation/prices-table/prices-table-columns';
import PricesTableHeaders from '@components/atoms/simulation/prices-table/prices-hable-headers';

import { IEconomyChart } from '@components/molecules/general/economy-chart';
import { ColumnMajorHeader, MainContentColumnMajor } from './helper';

export interface IEconomySlide {
  showSideData?: boolean;
  simulationResult:
    | IProductTwoSimulationExtendedResult
    | IProductTwoSimulationUnitExtendedResult
    | IProductOneSimulationExtendedResult;
  proposalValidity?: string;
  hasTraderDescription?: boolean;
}

const ProductEconomySlide = ({
  showSideData,
  simulationResult,
  proposalValidity,
  hasTraderDescription,
}: IEconomySlide) => {
  const [economyProjectionTable, setEconomyProjectionTable] = React.useState<IEconomyProjectionTable>();

  const [chartData, setChartData] = React.useState<IEconomyChart>();

  const totalColumns = simulationResult.simulationResultYearsList
    ? simulationResult.simulationResultYearsList?.length
    : 8;

  React.useEffect(() => {
    if (simulationResult) {
      const isUnitAtHeaderVersion = true;
      const parsedEconomyProjectionTable = parseProposalEconomyProjectionTable(simulationResult, isUnitAtHeaderVersion);
      setEconomyProjectionTable(parsedEconomyProjectionTable);

      const gridColumns = {
        totalColumns: totalColumns,
        yearsColumns: totalColumns - 3,
      };

      setChartData({
        labels: simulationResult.simulationResultYearsList,
        currentValues: simulationResult.currentSpentByYearList,
        targetValues: simulationResult.targetSpentByYearList,
        savingValues: simulationResult.savingByYearPercentageList,
        gridColumns,
      });
    }
  }, [simulationResult]);

  return (
    <div className="flex flex-col justify-between h-full w-full p-8 overflow-clip">
      <ColumnMajorHeader simulationResult={simulationResult} />
      <MainContentColumnMajor
        showSideData={showSideData}
        simulationResult={simulationResult}
        proposalValidity={proposalValidity}
        chartData={chartData}
        PremisesTableColumns={PremisesTableColumns}
        PremisesTableHeaders={PremisesTableHeaders}
        PricesTableColumns={PricesTableColumns}
        PricesTableHeaders={PricesTableHeaders}
        hasTraderDescription={hasTraderDescription}
        economyProjectionTable={economyProjectionTable}
      />
    </div>
  );
};

export default ProductEconomySlide;
