import { UseFormClearErrors, UseFormSetError } from 'react-hook-form';

import { isArrayEmpty } from '@utils/common';
import { ISimulationPayloadBase } from '@schemas/types';

interface IValidateSimulatorFormFields {
  values: ISimulationPayloadBase;
  setError: UseFormSetError<ISimulationPayloadBase>;
  clearErrors: UseFormClearErrors<ISimulationPayloadBase>;
  onlyFields?: Array<keyof ISimulationPayloadBase | string>;
}

function validateSimulatorFormFields({ values, setError, clearErrors, onlyFields }: IValidateSimulatorFormFields) {
  let isFormValid = true;

  clearErrors();

  const shouldFieldBeValidated = (values: Array<keyof ISimulationPayloadBase | string>) => {
    if (!onlyFields) return true;
    else return onlyFields.find((ignoredField) => values.includes(ignoredField));
  };

  const validationHandler = (fieldName: keyof ISimulationPayloadBase, message: string, error?: boolean) => {
    if (error) {
      setError(fieldName as keyof ISimulationPayloadBase, {
        type: 'manual',
        message: message,
      });
      isFormValid = false;
    } else clearErrors([fieldName]);
  };

  if (shouldFieldBeValidated(['targetYear'])) {
    validationHandler(
      'targetYear',
      'Selecione um grupo de ano(s)',
      values.targetYear !== undefined && !isFinite(values.targetYear ?? undefined),
    );
  }

  if (shouldFieldBeValidated(['targetYears'])) {
    validationHandler(
      'targetYears',
      'Selecione entre um a três grupos de ano(s)',
      isArrayEmpty(values.targetYears) || (values.targetYears && values.targetYears.length > 3),
    );
  }

  return isFormValid;
}

export default validateSimulatorFormFields;
