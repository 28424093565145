import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { get } from 'lodash';

import {
  IOperationHoursRange,
  IOperationHoursRangeSet,
  ISimulationPayloadBase,
  ISimulationUnitPayloadBase,
} from '@schemas/types';

import { ISimulatorFormUnitSection } from '@components/molecules/form/simulator/sections/simulator-form-units-section/simulator-form-unit-section';
import { fieldVerificator } from '@components/molecules/form/simulator/helper';

import { removeNonNumericFromString } from '@utils/common';

import { TimeFormField } from '../fields/time-form-field';
import { RadioFormField } from '../fields/radio-form-field';
import { IGeneralFormSelectableInputsOptions } from '../types';
import { CheckboxFormField } from '../fields/checkbox-form-field';

const operationHoursRangeLimits: Array<keyof IOperationHoursRange> = ['openingTime', 'closingTime'];
const weekPeriods: Array<keyof IOperationHoursRangeSet> = ['businessDays', 'saturday', 'sunday'];

interface IOperationHoursRangeRowProps {
  weekPeriod: keyof IOperationHoursRangeSet;
  unitNum: number;
}

export const SimulatorUnitOperationHoursRangeSubsection: React.FC<ISimulatorFormUnitSection> = ({
  unitNum,
  fieldsController,
}) => {
  const { setValue } = useFormContext<ISimulationPayloadBase>();
  const aux__useOpenInWeekends = useWatch({ name: `units.${unitNum}.aux__useOpenInWeekends` });

  const defaultBooleanInputOptions: Array<IGeneralFormSelectableInputsOptions> = [
    { id: 'operationHoursRangeTrue', value: 'true', optionLabel: 'Sim', defaultChecked: aux__useOpenInWeekends },
    { id: 'operationHoursRangeFalse', value: 'false', optionLabel: 'Não', defaultChecked: !aux__useOpenInWeekends },
  ];

  const handleWeekendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isOpenOnWeekends = event.target.value === 'true';
    setValue(`units.${unitNum}.aux__useOpenInWeekends`, isOpenOnWeekends);

    if (!isOpenOnWeekends) {
      setValue(`units.${unitNum}.aux__useOperationInSaturdays`, false);
      setValue(`units.${unitNum}.aux__useOperationInSundays`, false);
      clearWeekendHours(unitNum);
    }
  };

  const clearWeekendHours = (unitNum: number) => {
    setValue(`units.${unitNum}.operationHoursRange.saturday`, null);
    setValue(`units.${unitNum}.operationHoursRange.sunday`, null);
  };

  return (
    <div>
      {fieldVerificator({ fieldName: 'operationHoursRange', fieldPriority: 'SECONDARY', fields: fieldsController }) && (
        <div className="flex flex-col w-full gap-5">
          <p className="text-paragraph-large font-semibold mb-4">Horários de Funcionamento</p>
          <div className="mt-3 bg-neutral-10 rounded-medium pt-4 pb-6 px-6">
            <RadioFormField<ISimulationUnitPayloadBase>
              label="Funciona aos finais de semana?"
              field="aux__useOpenInWeekends"
              inputOptions={defaultBooleanInputOptions}
              options={{ onChange: handleWeekendChange }}
            />
          </div>
          <div data-cy="operationHoursRangeContainerWeekPeriods" className="flex flex-col gap-5 w-full">
            {weekPeriods.map((weekPeriod) => (
              <OperationHoursRange
                key={`business-hours-week-period-${weekPeriod}-unit-${unitNum}`}
                unitNum={unitNum}
                weekPeriod={weekPeriod}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const OperationHoursRange: React.FC<IOperationHoursRangeRowProps> = ({ unitNum, weekPeriod }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext<ISimulationPayloadBase>();
  const aux__useOpenInWeekends = useWatch({ name: `units.${unitNum}.aux__useOpenInWeekends` });
  const aux__useOperationInSaturdays = useWatch({ name: `units.${unitNum}.aux__useOperationInSaturdays` });
  const aux__useOperationInSundays = useWatch({ name: `units.${unitNum}.aux__useOperationInSundays` });

  const isBusinessDaysPeriod = weekPeriod === 'businessDays';
  const fieldError = get(errors, `units.${unitNum}.aux__useOpenInWeekends`)?.message as string | undefined;

  useEffect(() => {
    if (!aux__useOperationInSaturdays) {
      clearDayHours(unitNum, 'saturday');
    }
  }, [aux__useOperationInSaturdays]);

  useEffect(() => {
    if (!aux__useOperationInSundays) {
      clearDayHours(unitNum, 'sunday');
    }
  }, [aux__useOperationInSundays]);

  const clearDayHours = (unitNum: number, day: keyof IOperationHoursRangeSet) => {
    setValue(`units.${unitNum}.operationHoursRange.${day}.openingTime`, '');
    setValue(`units.${unitNum}.operationHoursRange.${day}.closingTime`, '');
  };

  const handleChangePeriodActivation = (isChecked: boolean, weekPeriod: keyof IOperationHoursRangeSet) => {
    if (!isBusinessDaysPeriod) {
      const fieldName = `units.${unitNum}.aux__useOperationIn${
        weekPeriod === 'saturday' ? 'Saturdays' : 'Sundays'
      }` as keyof ISimulationPayloadBase;
      setValue(fieldName, isChecked);
    }
  };
  const formatTime = (value: string) => {
    const numericStringValue = removeNonNumericFromString(value);
    return numericStringValue.length < 2
      ? numericStringValue
      : `${numericStringValue.slice(0, 2)}:${numericStringValue.slice(2, 4)}h`;
  };

  const getCheckedValue = () => {
    if (isBusinessDaysPeriod) return true;
    if (weekPeriod === 'saturday') return aux__useOperationInSaturdays;
    if (weekPeriod === 'sunday') return aux__useOperationInSundays;
    return false;
  };
  return (
    <>
      <div className="flex gap-8 w-full items-center rounded-2xl bg-neutral-10 py-5 px-7">
        <div className="flex gap-3">
          <CheckboxFormField<ISimulationPayloadBase>
            label=""
            options={{
              disabled: isBusinessDaysPeriod || !aux__useOpenInWeekends,
              onChange: (event) => handleChangePeriodActivation(event.target.checked, weekPeriod),
            }}
            field={`units.${unitNum}.aux__useOperationIn${weekPeriod === 'saturday' ? 'Saturdays' : 'Sundays'}`}
            inputOptions={[
              {
                id: `unit-${unitNum}-${weekPeriod}-period-checkbox`,
                optionLabel: isBusinessDaysPeriod ? 'Dia Útil' : weekPeriod === 'saturday' ? 'Sábado' : 'Domingo',
                defaultChecked: getCheckedValue(),
                value: getCheckedValue(),
              },
            ]}
          />
        </div>
        {operationHoursRangeLimits.map((businessHour) => {
          const fieldName =
            `units.${unitNum}.operationHoursRange.${weekPeriod}.${businessHour}` as keyof ISimulationPayloadBase;
          return (
            <TimeFormField<ISimulationPayloadBase>
              key={`unit-${unitNum}-${fieldName}`}
              id={`unit-${unitNum}-${fieldName}`}
              field={fieldName}
              label={businessHour === 'openingTime' ? 'Abertura' : 'Fechamento'}
              formatProps={{
                format: formatTime,
                allowNegative: false,
                fixedDecimalScale: false,
                isNumericString: true,
              }}
              step={30}
              className="w-full"
              options={{
                required: isBusinessDaysPeriod || aux__useOpenInWeekends,
              }}
              disabled={isBusinessDaysPeriod || getCheckedValue() ? false : true}
            />
          );
        })}
      </div>
      {!isBusinessDaysPeriod && fieldError && <span className="text-paragraph-small text-danger-60">{fieldError}</span>}
    </>
  );
};
