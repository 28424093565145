import React from 'react';
import { ColumnOptions, Table } from '@clarke-energia/foton';

import { IProductTwoSimulationResultSummaryWithFixedTraderData } from '@schemas/product-two/types';

import SlideHeader from '@components/atoms/simulation/slide-header';

interface ITradersResultsTradersDifferentialsData {
  tradersResultsWithTradersFixedData: Array<IProductTwoSimulationResultSummaryWithFixedTraderData>;
}

const tradersDifferentialsTableColumns: Array<ColumnOptions<IProductTwoSimulationResultSummaryWithFixedTraderData>> = [
  {
    accessor: 'traderLogo',
    header: 'Fornecedora',
    renderAsElement: (entry) => <img className="max-h-[18px] object-contain" src={entry.traderLogo} />,
  },
  {
    accessor: 'cceeDuty',
    header: 'Encargos CCEE',
    renderAsElement: (entry): string => entry.cceeDuty,
  },
  {
    accessor: 'paymentTerms',
    header: 'Pagamento da fatura',
    renderAsElement: (entry): string => entry.paymentTerms,
  },
  {
    accessor: 'penalty',
    header: 'Multa de rescisão',
    renderAsElement: (entry): string => entry.penalty,
  },
  {
    accessor: 'IREC',
    header: 'I-REC',
    renderAsElement: (entry): string => entry.IREC,
  },
];

const TradersDifferentials: React.FC<ITradersResultsTradersDifferentialsData> = ({
  tradersResultsWithTradersFixedData,
}: ITradersResultsTradersDifferentialsData) => {
  return (
    <div className="w-full flex flex-col h-full pb-8">
      <SlideHeader
        title="Diferenciais das fornecedoras"
        subtitle="As fornecedoras tem algumas variações em pequenos pontos dos contratos, abaixo é possível ver as suas diferenças."
      />
      <div className="grid grid-cols-2 xl:grid-cols-5  mt-6 px-8 md:px-8 xl:px-16 w-full">
        <Table tableColumns={tradersDifferentialsTableColumns} data={tradersResultsWithTradersFixedData}></Table>
      </div>
    </div>
  );
};

export default TradersDifferentials;
