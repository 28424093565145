import logoEnergisa from '@assets/newProposal/logo energisa.png';
import logoClarke from '@assets/newProposal/heroLogo.svg';
import { NewIconCard } from '@components/atoms/new-proposal/icon-card/index';
import { exclusiveEnergisaCardData } from '@components/molecules/new-proposal/static';

export function LogosSection() {
  return (
    <div className="exclusive-energisa-logo-section">
      <img src={logoEnergisa} alt="Energisa" className="w-fit h-14 2xl:h-16" />
      <hr className="w-1 border-1 h-8 mt-4 border-[#9C9C9B]" />
      <img src={logoClarke} alt="Clarke" className="w-fit h-14 2xl:h-16 mr-10 sm:mr-0" />
    </div>
  );
}

export function DescriptionText() {
  return (
    <span className="exclusive-energisa-description-text">
      A segurança da <b className={`text-white-label-main`}>Energisa</b> com a tecnologia e o atendimento da{' '}
      <b>Clarke</b>
    </span>
  );
}

export function CardsSection() {
  return (
    <div className="exclusive-energisa-cards-section">
      {exclusiveEnergisaCardData.map((item) => (
        <NewIconCard
          key={`proposal-exclusive-energisa-section-${item.title}`}
          {...item}
          sizeCard="sm:min-w-80 xl:max-h-[7.875rem] sm:w-80 sm:h-28 sm:items-start sm:flex-row xl:w-4/5 2xl:h-48"
          isCardDescription={true}
        />
      ))}
    </div>
  );
}
