import * as z from 'zod';

import { AxiosPromise } from 'axios';

import {
  IContractPayloadBase,
  IResponseBase,
  ISimulationReportBase,
  IUpdateSellerPayload,
  ICommercialDealerComission,
} from '@schemas/types';

import {
  AlternativePriceListCompositionSchema,
  EnergyTypeComparisonItemSchema,
  FreeMarketBenefitSchema,
  ProductTwoBestSimulationResultSchema,
  ProductTwoEnergyTypeComparisonSchema,
  ProductTwoProposalSchema,
  ProductTwoRetailTraderPriceSchema,
  ProductTwoSimulationExtendedResultBaseSchema,
  ProductTwoSimulationExtendedResultSchema,
  ProductTwoSimulationResultSchema,
  ProductTwoSimulationResultSummarySchema,
  ProductTwoSimulationResultSummaryWithFixedTraderDataSchema,
  ProductTwoSimulationScenarioSchema,
  ProductTwoSimulationUnitExtendedResultSchema,
  ProductTwoSimulatorInputsSchema,
} from '../../schemas/product-two';

export interface IProductTwoContext {
  proposal: IProductTwoProposal;
  isLoadingProductTwo: boolean;
  isValidProposalProductTwo: boolean;
  createProductTwoSimulation: (
    rawInputs: IProductTwoSimulatorInputs,
    simulationData: IProductTwoSimulatorInputs,
  ) => AxiosPromise<IResponseBase>;
  getProductTwoProposalById: (proposalId?: string) => AxiosPromise<IResponseBase>;
  getProductTwoProposals: (leadId?: string, sellerId?: string) => AxiosPromise<IResponseBase>;
  deleteProductTwoProposalsHandler: (leadId: string, proposalsIds: Array<string>) => AxiosPromise<IResponseBase>;
  deleteProductTwoAllProposalsByLeadIdHandler: (leadId: string) => AxiosPromise<IResponseBase>;
  updateSellerOfProductTwoProposal: (updateSellerData: IUpdateSellerPayload) => AxiosPromise<IResponseBase>;
  updateProductTwoProposalCommercialDealerComission: (
    proposalId: string,
    commercialDealerComissionData: ICommercialDealerComission,
  ) => AxiosPromise;
}

export type IProductTwoContractInputs = IContractPayloadBase;

export type IProductTwoUnitReport = ISimulationReportBase;

export enum ProductTwoSimulationStatusEnum {
  IN_QUEUE = 'Aguardando na fila',
  IN_PROGRESS = 'Em andamento',
  DONE = 'Concluído',
  FAILED = 'Falha',
}

export type IProductTwoSimulationUnitExtendedResult = z.infer<typeof ProductTwoSimulationUnitExtendedResultSchema>;
export type IProductTwoRetailTraderPrice = z.infer<typeof ProductTwoRetailTraderPriceSchema>;
export type IProductTwoSimulatorInputs = z.infer<typeof ProductTwoSimulatorInputsSchema>;
export type IProductTwoProposal = z.infer<typeof ProductTwoProposalSchema>;
export type IProductTwoSimulationScenario = z.infer<typeof ProductTwoSimulationScenarioSchema>;
export type IProductTwoBestSimulationResult = z.infer<typeof ProductTwoBestSimulationResultSchema>;
export type IEnergyTypeComparisonItem = z.infer<typeof EnergyTypeComparisonItemSchema>;
export type IAlternativePriceListComposition = z.infer<typeof AlternativePriceListCompositionSchema>;
export type IProductTwoEnergyTypeComparison = z.infer<typeof ProductTwoEnergyTypeComparisonSchema>;
export type IProductTwoSimulationResult = z.infer<typeof ProductTwoSimulationResultSchema>;
export type IProductTwoSimulationExtendedResultBase = z.infer<typeof ProductTwoSimulationExtendedResultBaseSchema>;
export type IProductTwoSimulationExtendedResult = z.infer<typeof ProductTwoSimulationExtendedResultSchema>;
export type IProductTwoSimulationResultSummary = z.infer<typeof ProductTwoSimulationResultSummarySchema>;
export type IProductTwoSimulationResultSummaryWithFixedTraderData = z.infer<
  typeof ProductTwoSimulationResultSummaryWithFixedTraderDataSchema
>;
export type IFreeMarketBenefit = z.infer<typeof FreeMarketBenefitSchema>;
