import { formatNumber } from '@utils/numeral';
import { CreatePremisesTag, getTagStyle } from '@pages/detailed-analysis/helper';
import { IPriceListComposition } from '@pages/detailed-analysis/types';
import { IProductTwoEnergyTypeComparison } from '@schemas/product-two/types';
import { IProductOneEnergyTypeComparison } from '@contexts/product-one/types';

export interface IEnergyTypeComparisonItem {
  totalSaving: number;
  totalSavingPercentage: number;
  rankingPosition: number;
}

export const proposalDataMokExp = {
  incentivized50: {
    totalSaving: 5238050.98,
    totalSavingPercentage: 0.35,
    rankingPosition: 1,
  },
  incentivized100: {
    totalSaving: 5014636.2,
    totalSavingPercentage: 0.33,
    rankingPosition: 2,
  },
  alternativeEnergyType: 'INCENTIVIZED_100',
  alternativePriceListComposition: [
    {
      unitName: 'Unidade 2',
      alternativePriceListCompositionData: [
        {
          priceComponent: {
            title: 'raw_price',
            label: 'Preço bruto',
            type: 'ABSOLUTE',
            value: 0,
            valueByYear: null,
            variationValueCeil: null,
            priceValueCeil: null,
            variationValueFloor: null,
            priceValueFloor: null,
            additionalInfo: 'Preços Indicativos',
          },
          priceList: {
            '3': {
              '2025': 261,
              '2026': 245,
              '2027': 245,
            },
          },
        },
        {
          priceComponent: {
            title: 'flex_30',
            label: 'Flex 30',
            type: 'ABSOLUTE',
            value: 3,
            valueByYear: null,
            variationValueCeil: null,
            priceValueCeil: null,
            variationValueFloor: null,
            priceValueFloor: null,
            additionalInfo: null,
          },
          priceList: {
            '3': {
              '2025': 264,
              '2026': 248,
              '2027': 248,
            },
          },
        },
        {
          priceComponent: {
            title: 'price_margin',
            label: 'Margem de preço',
            type: 'ABSOLUTE',
            value: 10,
            valueByYear: null,
            variationValueCeil: null,
            priceValueCeil: null,
            variationValueFloor: null,
            priceValueFloor: null,
            additionalInfo: null,
          },
          priceList: {
            '3': {
              '2025': 274,
              '2026': 258,
              '2027': 258,
            },
          },
        },
        {
          priceComponent: {
            title: 'price_with_ipca',
            label: 'Preço com IPCA',
            type: 'PERCENTAGE',
            value: 0.040000000000000036,
            valueByYear: null,
            variationValueCeil: null,
            priceValueCeil: null,
            variationValueFloor: null,
            priceValueFloor: null,
            additionalInfo: null,
          },
          priceList: {
            '3': {
              '2025': 280.34103823903047,
              '2026': 269.19840985835975,
              '2027': 279.96634625269434,
            },
          },
        },
      ],
    },
    {
      unitName: 'Unidade 1',
      alternativePriceListCompositionData: [
        {
          priceComponent: {
            title: 'raw_price',
            label: 'Preço bruto',
            type: 'ABSOLUTE',
            value: 0,
            valueByYear: null,
            variationValueCeil: null,
            priceValueCeil: null,
            variationValueFloor: null,
            priceValueFloor: null,
            additionalInfo: 'Preços Indicativos',
          },
          priceList: {
            '3': {
              '2025': 261,
              '2026': 245,
              '2027': 245,
            },
          },
        },
        {
          priceComponent: {
            title: 'flex_30',
            label: 'Flex 30',
            type: 'ABSOLUTE',
            value: 3,
            valueByYear: null,
            variationValueCeil: null,
            priceValueCeil: null,
            variationValueFloor: null,
            priceValueFloor: null,
            additionalInfo: null,
          },
          priceList: {
            '3': {
              '2025': 264,
              '2026': 248,
              '2027': 248,
            },
          },
        },
        {
          priceComponent: {
            title: 'price_margin',
            label: 'Margem de preço',
            type: 'ABSOLUTE',
            value: 10,
            valueByYear: null,
            variationValueCeil: null,
            priceValueCeil: null,
            variationValueFloor: null,
            priceValueFloor: null,
            additionalInfo: null,
          },
          priceList: {
            '3': {
              '2025': 274,
              '2026': 258,
              '2027': 258,
            },
          },
        },
        {
          priceComponent: {
            title: 'price_with_ipca',
            label: 'Preço com IPCA',
            type: 'PERCENTAGE',
            value: 0.040000000000000036,
            valueByYear: null,
            variationValueCeil: null,
            priceValueCeil: null,
            variationValueFloor: null,
            priceValueFloor: null,
            additionalInfo: null,
          },
          priceList: {
            '3': {
              '2025': 280.34103823903047,
              '2026': 269.19840985835975,
              '2027': 279.96634625269434,
            },
          },
        },
      ],
    },
  ],
};

export const getEnergyTypeComparison = (data: IProductTwoEnergyTypeComparison | IProductOneEnergyTypeComparison) => {
  const formatEnergyData = (key: string, energyData?: IEnergyTypeComparisonItem) => [
    {
      label: 'Economia total (R$)',
      value: formatNumber(energyData?.totalSaving, '$0,0.0'),
      tooltipContent: `propriedade: ${key}.totalSaving`,
    },
    {
      label: 'Economia total (%)',
      value: formatNumber(energyData?.totalSavingPercentage, '%'),
      tooltipContent: `propriedade: ${key}totalSavingPercentage`,
    },
    {
      label: 'Posição no ranking',
      value: energyData?.rankingPosition === 1 ? 'Melhor opção' : '-',
      tooltipContent: `propriedade: ${key}rankingPosition`,
    },
  ];
  return {
    incentivized50: formatEnergyData('incentivized50', data.incentivized50),
    incentivized100: formatEnergyData('incentivized100', data.incentivized100),
  };
};

export const getMaxKeyIndex = (alternativeEnergyType: Array<IPriceListComposition>) => {
  const keysIndex = alternativeEnergyType?.map((item) => Object.keys(item.priceList ?? {})?.[0]);
  return keysIndex && Math.max(Number(...keysIndex));
};

export const getUniqueYears = (alternativeEnergyType: Array<IPriceListComposition>, keyIndex: number) => {
  const allYears = alternativeEnergyType?.flatMap((item) => Object?.keys(item.priceList?.[keyIndex] ?? {}));
  return Array?.from(new Set(allYears))?.sort();
};

export const formatValueForUniqueValue = (value: number, type: string) => {
  return type === 'ABSOLUTE' ? `${value ?? '0'} (R$/MWh)` : `${(value * 100)?.toFixed(0) ?? '0'}%`;
};

export const formatValueForValueByYear = (valueByYear: Record<string, number>, type: string) => {
  const formattedValueList: string[] = [];
  for (const year in valueByYear) {
    if (type === 'PERCENTAGE') {
      formattedValueList.push(`${year}: ${(valueByYear[year] * 100).toFixed(0)}%`);
    } else {
      formattedValueList.push(`${year}: ${valueByYear[year]} (R$/MWh)`);
    }
  }
  return formattedValueList.join(' | ');
};

export const createDataRow = (item: IPriceListComposition, uniqueYears: string[]) => {
  const keyIndex = Object.keys(item.priceList ?? {})?.[0];
  const years = uniqueYears?.map((year) => formatNumber(item.priceList?.[keyIndex][year], '$0,0.00'));

  const {
    value,
    type,
    variationValueCeil,
    variationValueFloor,
    label,
    valueByYear,
    additionalInfo,
    priceValueCeil,
    priceValueFloor,
  } = item.priceComponent;

  const formattedValue = valueByYear
    ? formatValueForValueByYear(valueByYear, type)
    : formatValueForUniqueValue(value, type);
  const premisesContent = additionalInfo ? additionalInfo : formattedValue;
  const premisesTag = CreatePremisesTag({
    premisesContent,
    value,
    variationValueCeil: variationValueCeil,
    variationValueFloor: variationValueFloor,
    priceValueCeil: priceValueCeil,
    priceValueFloor: priceValueFloor,
  });

  return { row: [label, premisesTag, ...years], tagStyle: getTagStyle(value, formattedValue, additionalInfo) };
};

export const getAlternativePriceListCompositionTableData = (alternativeEnergyType: Array<IPriceListComposition>) => {
  const keyIndex = getMaxKeyIndex(alternativeEnergyType);
  const uniqueYears = getUniqueYears(alternativeEnergyType, keyIndex);
  const headers = ['Etapa', 'Premissas', ...uniqueYears];
  const dataRows = alternativeEnergyType?.map((item) => createDataRow(item, uniqueYears));

  return {
    headers,
    rows: dataRows,
  };
};
