import { ISimulationPayloadBase, SimulatorTypeEnum } from '@schemas/types';
import { isOnSimulatorType } from '@utils/common';

const standardizeTotalMigrationCostSuggestion = (calculatedValue: number, units: ISimulationPayloadBase['units']) => {
  const unitsNumber = Array(units).length;
  const maximumValuePerUnit = 4000;
  const doesSuggestionExceedsMaximumPerUnit = calculatedValue / unitsNumber > maximumValuePerUnit;
  const maximumSuggestionValue = unitsNumber * maximumValuePerUnit;

  return doesSuggestionExceedsMaximumPerUnit ? maximumSuggestionValue : calculatedValue;
};

export const calculateTotalMigrationCostSuggestion = (units: ISimulationPayloadBase['units']) => {
  const filteredUnits = units?.filter((unit) => !unit.isAlredyAtACL);

  const sumOfManagementPriceOfUnits = (filteredUnits as ISimulationPayloadBase['units'])?.reduce(function (
    accumulator,
    unit,
  ) {
    const unitManagementPrice = unit.managementPrice ?? 0;
    return accumulator + unitManagementPrice;
  },
  0);

  const selectedSimulatorType = isOnSimulatorType(SimulatorTypeEnum.CLARKE_RETAIL);
  const multiplicativeFactor = selectedSimulatorType ? 4 : 5;
  const calculatedTotalMigrationCostSuggestion = (sumOfManagementPriceOfUnits ?? 0) * multiplicativeFactor;
  return standardizeTotalMigrationCostSuggestion(calculatedTotalMigrationCostSuggestion, filteredUnits);
};
