import { z } from 'zod';
import { simulationPayloadBaseSchema, SimulationUnitPayloadBaseSchema } from '@schemas';
import { isNumber } from '@contexts/calculator/units-payload-handlers';

const errorsMessages = {
  required_error: 'Campo é obrigatório',
  invalid_type_error: 'Campo é obrigatório',
};
export const ContractedDemandContextSchema = z.object({
  createContractedDemandSimulation: z.function().args(z.any()).returns(z.any()),
});

export const ContractedDemandSimulatorInputsSchema = simulationPayloadBaseSchema
  .omit({
    lead: true,
    productType: true,
    risk: true,
  })
  .superRefine((data, ctx) => {
    const { units } = data;
    units?.forEach((unit, index) => {
      const {
        nickname,
        icmsType,
        tariff,
        energyDistributionCompany,
        aclContractedDemandOffPeak,
        acrContractedDemandOffPeak,
        consumptionOffPeak,
      } = unit;

      if (!nickname) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['units', index, 'nickname'],
          message: 'Nome da unidade é obrigatório',
        });
      }
      if (!tariff) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['units', index, 'tariff'],
          message: 'Tarifa é obrigatória',
        });
      }
      if (!energyDistributionCompany) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['units', index, 'energyDistributionCompany'],
          message: 'Distribuidora de energia é obrigatória',
        });
      }
      if (!icmsType) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['units', index, 'icmsType'],
          message: 'Tipo de ICMS é obrigatório',
        });
      }
      const checkHasAtLeastOneValue = (arr: Record<string, string | number | null | undefined> | undefined) => {
        if (!arr) return false;
        return Object.values(arr).some((value) => Number(value) > 0);
      };

      if (!aclContractedDemandOffPeak) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['units', index, 'aclContractedDemandOffPeak'],
          message: 'Demanda contratada fora ponta é obrigatória',
        });
      }
      if (!acrContractedDemandOffPeak) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['units', index, 'acrContractedDemandOffPeak'],
          message: 'Demanda contratada fora ponta é obrigatória',
        });
      }
      if (!checkHasAtLeastOneValue(consumptionOffPeak) || !consumptionOffPeak) {
        for (let i = 1; i <= 12; i++) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['units', index, 'consumptionOffPeak', i],
            message: 'Insira um valor',
          });
        }
      }
    });
  });
export const ContractedDemandSimulationUnitPayloadSchema = SimulationUnitPayloadBaseSchema;

export const ContractedDemandSimulationPayloadSchema = z.object({
  contractedDemandOffPeak: z.union([z.number(errorsMessages), z.string(errorsMessages), z.null()]),
  contractedDemandPeak: z.union([z.number(errorsMessages), z.string(errorsMessages), z.null()]),
  energyDistributionCompany: z.number(errorsMessages).optional(),
  icmsType: z.string(errorsMessages).optional(),
  consumptionOffPeak: z.array(z.number(errorsMessages)).superRefine((data, ctx) => {
    const isValidConsumption = data.some((value) => isNumber(value));
    if (!isValidConsumption) {
      alert('Insira um valor para demanda medida fora de pico');
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['consumptionOffPeak', 0],
        message: 'Insira um valor para demanda medida fora de pico',
      });
    }
  }),
  measuredDemandPeak: z.array(z.number(errorsMessages)).optional(),
  tariff: z.union([z.number(errorsMessages), z.string(errorsMessages)]).optional(),
});

export const ContractedDemandSimulationSchema = z.object({
  annualClarkeValue: z.number(errorsMessages),
  annualEconomyOffPeak: z.number(errorsMessages),
  annualEconomyPeak: z.number(errorsMessages),
  clarkeFee: z.number(errorsMessages),
  isWorthwhile: z.boolean(),
  newAdjustedDemandOffPeak: z.number(errorsMessages),
  newAdjustedDemandPeak: z.number(errorsMessages),
});
