import React from 'react';
import { Checkbox } from '@clarke-energia/foton';
import { useFormContext } from 'react-hook-form';

import { ISimulationPayloadBase, SimulatorTypeEnum } from '@schemas/types';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { defaultNumericFormFieldOptionsAsRequired } from '@utils/form';
import { getCalculatorName, isOnSimulatorType } from '@utils/common';

import { ISimulatorFormUnitSection } from '@components/molecules/form/simulator/sections/simulator-form-units-section/simulator-form-unit-section';

import { NumericFormField } from '../fields/numeric-form-field';
import { fieldVerificator } from '@components/molecules/form/simulator/helper';
import { useAuth } from '@src/auth-wrapper';

interface ISimulatorUnitDemandSubsection extends ISimulatorFormUnitSection {
  selectedTariff?: number;
}

interface IContractedPeekDemandInput {
  name: string;
  tariff?: number;
  fieldName: string;
}

const ContractedPeekDemandInput = ({ name, tariff, fieldName }: IContractedPeekDemandInput) => {
  return (
    <>
      {(tariff === 3 || tariff === 4) && (
        <NumericFormField
          id={fieldName}
          field={fieldName}
          label={`(${name}) Demanda Contratada Ponta`}
          formatProps={{
            mask: '_',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 10,
            suffix: ' kW',
          }}
          options={defaultNumericFormFieldOptionsAsRequired}
        />
      )}
    </>
  );
};

export function SimulatorUnitDemandSubsection({
  unitNum,
  selectedTariff,
  fieldsController,
}: ISimulatorUnitDemandSubsection) {
  const { user } = useAuth();
  const {
    setValue,
    watch,
    formState: { defaultValues },
  } = useFormContext<ISimulationPayloadBase>();

  const [equalDemand, setEqualDemand] = React.useState<boolean>(false);

  const aclContractedDemandOffPeakFieldName =
    `units.${unitNum}.aclContractedDemandOffPeak` as keyof ISimulationPayloadBase;
  const aclContractedDemandPeakFieldName = `units.${unitNum}.aclContractedDemandPeak` as keyof ISimulationPayloadBase;

  const acrContractedDemandOffPeakFieldName =
    `units.${unitNum}.acrContractedDemandOffPeak` as keyof ISimulationPayloadBase;
  const acrContractedDemandPeakFieldName = `units.${unitNum}.acrContractedDemandPeak` as keyof ISimulationPayloadBase;

  const aclContractedDemandOffPeak = watch(aclContractedDemandOffPeakFieldName);
  const aclContractedDemandPeak = watch(aclContractedDemandPeakFieldName);

  React.useEffect(() => {
    if (equalDemand) {
      eventTracker.trackEvent(eventLabels.EQUAL_ENERGY_DEMAND_OPTION_CHECKED, {
        product: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
    }
  }, [equalDemand]);

  React.useEffect(() => {
    if (equalDemand) {
      setValue(acrContractedDemandOffPeakFieldName, aclContractedDemandOffPeak);
      setValue(acrContractedDemandPeakFieldName, aclContractedDemandPeak);
    }
  }, [aclContractedDemandOffPeak, aclContractedDemandPeak, equalDemand]);

  React.useEffect(() => {
    const unitDefaultValues = defaultValues && defaultValues.units && defaultValues.units[unitNum];
    if (
      unitDefaultValues &&
      (unitDefaultValues?.aclContractedDemandOffPeak != unitDefaultValues?.acrContractedDemandOffPeak ||
        unitDefaultValues?.aclContractedDemandPeak != unitDefaultValues?.acrContractedDemandPeak)
    ) {
      setEqualDemand(false);
    } else {
      setEqualDemand(true);
    }
  }, [defaultValues]);

  const isOnContractedDemandSimulator = isOnSimulatorType(SimulatorTypeEnum.CONTRACTED_DEMAND);
  return (
    <div>
      {fieldVerificator({ fieldName: 'demand', fieldPriority: 'PRIMARY', fields: fieldsController }) && (
        <>
          {!isOnContractedDemandSimulator && (
            <div className="flex flex-col mb-5">
              <h2 className="text-paragraph-large font-bold mb-5">Demanda Contratada</h2>
              <Checkbox
                label="Demanda em ACR igual à ACL"
                onChange={() => setEqualDemand((previous) => !previous)}
                checked={equalDemand}
              />
            </div>
          )}
          <div className="grid grid-cols-3 gap-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 col-span-3 gap-x-8">
              <ContractedPeekDemandInput
                name="ACL"
                tariff={selectedTariff}
                fieldName={aclContractedDemandPeakFieldName}
              />
              <NumericFormField<ISimulationPayloadBase>
                id={aclContractedDemandOffPeakFieldName}
                field={aclContractedDemandOffPeakFieldName}
                label="(ACL) Demanda Contratada Fora Ponta"
                formatProps={{
                  mask: '_',
                  allowNegative: false,
                  fixedDecimalScale: false,
                  decimalSeparator: ',',
                  thousandSeparator: '.',
                  decimalScale: 10,
                  suffix: ' kW',
                }}
                options={defaultNumericFormFieldOptionsAsRequired}
              />
            </div>
            {!equalDemand && (
              <div className="grid grid-cols-1 lg:grid-cols-2 col-span-3 gap-x-8">
                <ContractedPeekDemandInput
                  name="ACR"
                  tariff={selectedTariff}
                  fieldName={acrContractedDemandPeakFieldName}
                />
                <NumericFormField<ISimulationPayloadBase>
                  id={`units.${unitNum}.acrContractedDemandOffPeak`}
                  field={`units.${unitNum}.acrContractedDemandOffPeak`}
                  label="(ACR) Demanda Contratada Fora Ponta"
                  formatProps={{
                    mask: '_',
                    allowNegative: false,
                    fixedDecimalScale: false,
                    decimalSeparator: ',',
                    thousandSeparator: '.',
                    decimalScale: 10,
                    suffix: ' kW',
                  }}
                  options={defaultNumericFormFieldOptionsAsRequired}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
