import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from '@clarke-energia/foton';

import { useAuth } from '@src/auth-wrapper';
import { SimulatorFormTooltipsContentIndexer as tooltipsContent } from '../../static-data';
import { parseStringToBoolean } from '@utils/common';
import { ISimulationUnitPayloadBase, SimulatorTypeEnum } from '@schemas/types';
import { RadioFormField } from '@components/atoms/form/fields/radio-form-field';
import { IGeneralFormSelectableInputsOptions } from '@components/atoms/form/types';
import { NumericFormField } from '@components/atoms/form/fields/numeric-form-field';
import { SelectFormField } from '@components/atoms/form/fields/select-form-field';
import { SimulatorFormPremisesCustomFeeByYearSection } from './simulator-form-premises-custom-fee-by-year-section';
import { getsupplyStartDateList, getYearsRange } from './helper';
import { IProductTwoSimulatorInputs } from '@schemas/product-two/types';

interface ISimulatorFormPremisesSection {
  simulatorType?: keyof typeof SimulatorTypeEnum;
}

function SimulatorFormPremisesSection({ simulatorType }: ISimulatorFormPremisesSection) {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [yearsRange, setYearsRange] = React.useState<number[]>([]);
  const [isMarginUnique, setIsMarginUnique] = React.useState<boolean | undefined>(true);

  const {
    watch,
    setValue,
    formState: { defaultValues },
  } = useFormContext<IProductTwoSimulatorInputs>();

  const { user } = useAuth();

  const customFee = watch('customFee');
  const customFeeType = watch('customFee.type');
  const aux__useManualAclPrice = watch('aux__useManualAclPrice');

  const targetYears = watch('targetYears');

  const targetYear =
    simulatorType === 'CLARKE_MANAGEMENT' ? targetYears && targetYears[targetYears.length - 1] : watch('targetYear');

  const units = useWatch({ name: 'units' }) as ISimulationUnitPayloadBase[] | undefined;

  enum CustomFeeTypeEnum {
    undefined = 'Não utilizar',
    ABSOLUTE = 'Em R$',
    PERCENTAGE = 'Em %',
  }

  const defaultBooleanInputOptions: Array<IGeneralFormSelectableInputsOptions> = [
    {
      id: 'true',
      value: 'true',
      optionLabel: 'Sim',
      defaultChecked: isMarginUnique,
    },
    {
      id: 'false',
      value: 'false',
      optionLabel: 'Não',
      defaultChecked: !isMarginUnique,
    },
  ];

  function getUniqueMarginData(values: Record<string, any>) {
    const valuesAsString = Object.values(values);

    if (valuesAsString.length >= 0) {
      const isMarginUnique = valuesAsString.every((valor) => valor === valuesAsString[0]);
      setIsMarginUnique(isMarginUnique);
      setValue('customFee.auxiliarFields.isMarginUnique', isMarginUnique);

      isMarginUnique && setValue('customFee.auxiliarFields.uniqueMargin', valuesAsString[0]);
      return;
    }
  }

  React.useEffect(() => {
    if (customFee) {
      if (!customFee?.type) {
        setValue('customFee.type', undefined);
      } else {
        customFee.values && getUniqueMarginData(customFee.values);
        setModalOpen(true);
      }
    }
  }, [customFee, defaultValues, setValue]);

  const handleMarginTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const getIsMarginTypeByYear = parseStringToBoolean(event.target.value);
    if (event.target.checked) {
      setIsMarginUnique(getIsMarginTypeByYear);

      if (event.target.value === 'true') {
        setValue('customFee.auxiliarFields.isMarginUnique', getIsMarginTypeByYear as boolean);
      } else {
        setValue('customFee.auxiliarFields.isMarginUnique', false);
      }
    }
  };

  React.useEffect(() => {
    const supplyStartDateList = units && getsupplyStartDateList(units);
    supplyStartDateList && targetYear && setYearsRange(getYearsRange(supplyStartDateList, targetYear));
  }, [targetYear, units]);

  return (
    <>
      {!user?.isCommercialDealer && !aux__useManualAclPrice && (
        <div>
          <Button
            icon={modalOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
            iconPosition="right"
            kind="ghost"
            label="Premissas"
            onClick={() => setModalOpen(!modalOpen)}
            type="button"
            className="w-full"
          />
          {modalOpen && (
            <div className="space-y-6 lg:col-start-1 lg:col-span-2 mt-4 mb-5">
              <section aria-labelledby="applicant-information-title">
                <div className="bg-neutral-10 rounded-medium px-6 pb-7">
                  <h2 className="text-paragraph-large py-6">Informações da base de cálculo</h2>
                  <div className="flex flex-col">
                    <div className="flex flex-row gap-8 py-4 w-full">
                      <SelectFormField<IProductTwoSimulatorInputs>
                        id="customFee.type"
                        field="customFee.type"
                        label="Margem Personalizada"
                        options={{
                          onChange: (event) => {
                            const selectedValue = event.target.value;
                            if (selectedValue === 'undefined') {
                              setValue('customFee', undefined);
                            }
                          },
                        }}
                        inputOptions={Object.entries(CustomFeeTypeEnum).map((productTypeEntry) => ({
                          value: productTypeEntry[0],
                          optionLabel: productTypeEntry[1],
                        }))}
                        tooltipContent={tooltipsContent.customFee}
                      />
                      {(customFeeType === 'ABSOLUTE' || customFeeType === 'PERCENTAGE') && yearsRange && (
                        <>
                          <div className="flex flex-row pb-8 gap-8">
                            <RadioFormField
                              label="Valor único?"
                              field="customFee.auxiliarFields.isMarginUnique"
                              inputOptions={defaultBooleanInputOptions}
                              options={{
                                onChange: (event) => {
                                  handleMarginTypeChange(event);
                                },
                              }}
                            />
                            {isMarginUnique && (
                              <NumericFormField
                                id="customFee.auxiliarFields.uniqueMargin"
                                field="customFee.auxiliarFields.uniqueMargin"
                                label="Margem"
                                formatProps={{
                                  allowNegative: false,
                                  fixedDecimalScale: false,
                                  decimalSeparator: ',',
                                  thousandSeparator: '.',
                                  decimalScale: 1000,
                                  prefix: customFeeType === 'ABSOLUTE' ? 'R$ ' : '',
                                  suffix: customFeeType === 'PERCENTAGE' ? '%' : '',
                                }}
                                options={{
                                  required: isMarginUnique ? true : false,
                                }}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {(customFeeType === 'ABSOLUTE' || customFeeType === 'PERCENTAGE') && yearsRange && (
                      <>
                        <h3 className="text-paragraph-medium">
                          {customFeeType === 'ABSOLUTE' ? 'Margem de Preço (em R$)' : 'Margem de Preço (em %)'}
                        </h3>
                        <div className="flex flex-row gap-8">
                          {yearsRange.map(
                            (year) =>
                              !isNaN(year) && (
                                <SimulatorFormPremisesCustomFeeByYearSection
                                  key={`simulator-form-premises-custom-fee-by-year-section-${year}`}
                                  year={year}
                                  customFeeType={customFeeType}
                                  isMarginUnique={isMarginUnique}
                                />
                              ),
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default SimulatorFormPremisesSection;
