import { GenericFormField } from '../../fields/generic-form-field';
import { IWhiteLabelSupplierPayload } from '@schemas/white-label/types';
import { TextAreaFormField } from '../../fields/text-area-form-field';
import { HeroIconSelectInput } from '../../fields/heroicon-input-select';

export function WhiteLabelDifferenceGeneralSubsection({ differenceNum }: { differenceNum: number }) {
  const isUpdateWhiteLabellSupplierPath = window.location.pathname.includes('editar/');

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-start gap-4">
        <GenericFormField<IWhiteLabelSupplierPayload>
          id={`differences.${differenceNum}.title`}
          field={`differences.${differenceNum}.title`}
          label="Titulo"
          options={{
            required: isUpdateWhiteLabellSupplierPath ? { value: true, message: 'Campo é obrigatório' } : false,
          }}
        />
        <HeroIconSelectInput<IWhiteLabelSupplierPayload>
          id={`differences.${differenceNum}.icon`}
          field={`differences.${differenceNum}.icon`}
          label="Icone"
          index={differenceNum}
          options={{
            required: isUpdateWhiteLabellSupplierPath ? { value: true, message: 'Selecione um ícone' } : false,
          }}
        />
      </div>
      <TextAreaFormField<IWhiteLabelSupplierPayload>
        id={`differences.${differenceNum}.description`}
        field={`differences.${differenceNum}.description`}
        label="Descrição"
        className="col-span-2 w-full"
        options={{
          required: isUpdateWhiteLabellSupplierPath ? { value: true, message: 'Campo é obrigatório' } : false,
        }}
      />
    </div>
  );
}
