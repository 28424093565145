import React, { useState } from 'react';

import { IContractedDemandSimulatorInputs, IContractedDemandSimulation } from '@schemas/contracted-demand/types';

import ContractedDemandSimulatorForm from './contracted-demand-simulator-form';
import ContractedDemandSimulatorResult from './contracted-demand-simulator-result';

const ContractedDemandSimulator = (): JSX.Element => {
  const [simulatorResult, setSimulatorResult] = useState<IContractedDemandSimulation>();
  const [simulatorInputs, setSimulatorInputs] = useState<IContractedDemandSimulatorInputs>();

  return (
    <div className="w-full">
      {simulatorResult ? (
        <ContractedDemandSimulatorResult {...{ simulatorInputs, simulatorResult }} />
      ) : (
        <ContractedDemandSimulatorForm {...{ setSimulatorInputs, setSimulatorResult }} />
      )}
    </div>
  );
};

export default ContractedDemandSimulator;
