import React from 'react';
import { DetailedCard, DetailedCardProps } from '@clarke-energia/foton';
import numeral from 'numeral';

import { IProductTwoSimulationResultSummary } from '@schemas/product-two/types';
import tradersLogo from '@utils/traders';

interface ITradersResultsEconomyComparison {
  tradersResults?: Array<IProductTwoSimulationResultSummary> | undefined;
}

interface HighlightStyleType {
  label: string;
  color: 'green' | 'primary' | 'lightGreen' | undefined;
}

const getHighlightStyle = (index: number, bestTotalSavingScore: boolean): HighlightStyleType => {
  const highlightStyle: HighlightStyleType = { label: '', color: undefined };

  if (index === 0 && bestTotalSavingScore) {
    highlightStyle.label = 'Maior economia e melhor avaliação';
    highlightStyle.color = 'primary';
  } else if (index === 0 && !bestTotalSavingScore) {
    highlightStyle.label = 'Maior economia';
    highlightStyle.color = 'green';
  } else if (index === 1 && bestTotalSavingScore) {
    highlightStyle.label = 'Melhor avaliação';
    highlightStyle.color = 'lightGreen';
  }
  return highlightStyle;
};

const getTradersProposalsCardsContent = (
  simulationResultsSummaries: IProductTwoSimulationResultSummary[],
  bestTotalSavingScore: boolean,
) => {
  return simulationResultsSummaries.map((simulationResultSummary, index) => {
    const highlightStyle = getHighlightStyle(index, bestTotalSavingScore);

    const imageContent = {
      imgPath: tradersLogo[simulationResultSummary.retailTraderId],
      textWithIcon: simulationResultSummary.score
        ? ({
            iconName: 'StarIcon',
            text: String(simulationResultSummary.score),
          } as DetailedCardProps['imageContent']['textWithIcon'])
        : undefined,
    };

    const card: DetailedCardProps = {
      imageContent,
      cardHighLightInfo: {
        title: 'Economia',
        tag: {
          color: 'green',
          kind: 'default',
          label: numeral(simulationResultSummary.totalSavingInPercentage).format('%'),
        },
        value: numeral(simulationResultSummary.totalSaving).format('$ 0,00'),
      },
      ...(highlightStyle.color !== undefined ? { hightlightStyle: highlightStyle } : {}),
    };
    return card;
  });
};

interface IDefineBetterTotalSavingAndScoreResult {
  simulationResultsSummaries: IProductTwoSimulationResultSummary[];
  bestTotalSavingScore: boolean;
}

const defineBetterTotalSavingAndScore = (
  simulationResultsSummaries: IProductTwoSimulationResultSummary[],
): IDefineBetterTotalSavingAndScoreResult => {
  const bestScore = { index: 0, highestScore: 0, idx: '' };

  if (simulationResultsSummaries) {
    simulationResultsSummaries.sort((a, b) => b.totalSaving - a.totalSaving);

    simulationResultsSummaries.forEach((simulationResultSummary, index) => {
      if (simulationResultSummary.score !== undefined && simulationResultSummary.score > bestScore.highestScore) {
        bestScore.highestScore = simulationResultSummary.score;
        bestScore.index = index;
      }
    });
  }

  if (bestScore.index !== 0) {
    const bestScoreElement = simulationResultsSummaries.splice(bestScore.index, 1)[0];
    simulationResultsSummaries.splice(1, 0, bestScoreElement);
  }
  const theFirstElementHasScore = simulationResultsSummaries[bestScore.index].score !== undefined;

  return {
    simulationResultsSummaries: simulationResultsSummaries.slice(0, 6),
    bestTotalSavingScore: theFirstElementHasScore,
  };
};

const EmptyScope = () => {
  return (
    <div className="flex flex-col col-span-8 gap-5 h-full">
      <div className="text-heading-large">Sem propostas para esse processo.</div>
      <div className="text-paragraph-medium">Não há nenhuma proposta para esse processo.</div>
    </div>
  );
};

const ScopeDisplay: React.FC<ITradersResultsEconomyComparison> = ({ tradersResults }) => {
  if (!tradersResults || tradersResults.length === 0) {
    return <EmptyScope />;
  }

  const highlightedResults = defineBetterTotalSavingAndScore(tradersResults);
  const highlightedCards = getTradersProposalsCardsContent(
    highlightedResults.simulationResultsSummaries,
    highlightedResults.bestTotalSavingScore,
  );

  return (
    <div className="h-full items-end font-black text-2xl px-8 gap-5 md:justify-center grid grid-cols-12 lg:justify-between">
      {highlightedCards.map(({ cardHighLightInfo, imageContent, hightlightStyle }) => (
        <div
          key={`card-best-saving-score-${cardHighLightInfo.value}`}
          className="w-full col-span-full md:col-span-6 lg:col-span-4"
        >
          <DetailedCard
            hightlightStyle={hightlightStyle}
            cardHighLightInfo={cardHighLightInfo}
            imageContent={imageContent}
          />
        </div>
      ))}
    </div>
  );
};

export default ScopeDisplay;
