import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, LayoutProps } from '@clarke-energia/foton';
import { useAuth } from '@src/auth-wrapper';
import { HOME_PATH } from '@routers/constants';

import useCustomClipboard from '@hooks/use-custom-clipboard';
import { SimulatorTypeEnum, getSimulatorLabel } from '@schemas/types';

import { WhatsappButton } from '@components/atoms/general/whatsapp-button';

enum SimulatorFlowEnum {
  SIMULATOR_FORM = 'SIMULATOR_FORM',
  SIMULATOR_RESULT = 'SIMULATOR_RESULT',
  LIST = 'LIST',
  SIMULATION = 'SIMULATION',
  SUPPLIER = 'SUPPLIER',
}
interface ISimulatorLayoutBase<T> extends LayoutProps {
  children: React.ReactNode;
  simulatorType: keyof typeof SimulatorTypeEnum;
  simulatorFlow?: keyof typeof SimulatorFlowEnum;
  formValues?: T;
  setFormValues?: (formValues: T) => void;
  hidePasteValuesButtons?: boolean;
}

const SimulatorLayoutBase = <T,>({
  children,
  simulatorType,
  simulatorFlow,
  formValues,
  setFormValues,
  hidePasteValuesButtons,
  ...layoutProps
}: ISimulatorLayoutBase<T>) => {
  const { CopyFormValues, PasteFormValues } = useCustomClipboard();
  const { user } = useAuth();
  const navigate = useNavigate();

  const baseLinks = [
    {
      label: 'Início',
      url: HOME_PATH,
    },
    {
      label: getSimulatorLabel(simulatorType),
      url: '#',
    },
  ];

  const simulatorFormLinks =
    simulatorFlow === SimulatorFlowEnum.SIMULATOR_FORM
      ? [
          {
            label: 'Simulador',
            url: '#',
          },
        ]
      : [];

  const simulatorResultLinks =
    simulatorFlow === SimulatorFlowEnum.SIMULATOR_RESULT
      ? [
          {
            label: 'Simulador',
            url: '#',
          },
          {
            label: 'Resultado',
            url: '#',
          },
        ]
      : [];

  const listLinks =
    simulatorFlow === SimulatorFlowEnum.LIST
      ? [
          {
            label: 'Histórico',
            url: '#',
          },
        ]
      : [];

  const supplierListLinks =
    simulatorFlow === SimulatorFlowEnum.SUPPLIER
      ? [
          {
            label: 'Fornecedores',
            url: '#',
          },
        ]
      : [];

  const navigationHistoryLinks: LayoutProps['navigationHistoryLinks'] = [
    ...baseLinks,
    ...listLinks,
    ...simulatorFormLinks,
    ...simulatorResultLinks,
    ...supplierListLinks,
  ];

  const simulatorButtonsTooltipMessage =
    "- Copiar Dados: salva os dados do formulário para serem usados\
    posteriormente usando a opção 'Colar Dados'. Também permite colar os dados copiados\
    em qualquer lugar, usando Ctrl + V.\n\
    - Colar Dados: permite colar os dados da última simulação feita na plataforma\
    ou da última simulação que foi copiada usando o botão 'Copiar Dados'.\n\
    - Recuperar Dados: preenche o formulário com dados de fora da plataforma,\
    que foram copiados manualmente por você, em algum aplicativo de mensagens, por exemplo.";

  const headerButtons: LayoutProps['headerButtons'] =
    formValues && setFormValues
      ? [
          {
            kind: 'primary',
            label: 'Copiar Dados',
            type: 'button',
            onClick: () => CopyFormValues(formValues),
          },
          {
            kind: 'primary',
            label: 'Colar Dados',
            type: 'button',
            onClick: () => {
              navigate(`${location.pathname}?colar-dados`);
              PasteFormValues(setFormValues, simulatorType, true);
            },
            className: 'bg-blue-300 hover:bg-blue-400',
          },
          {
            kind: 'secondary',
            label: 'Recuperar Dados',
            type: 'button',
            onClick: () => PasteFormValues(setFormValues, simulatorType, true, true),
            className: 'px-2',
          },
          {
            icon: 'InformationCircleIcon',
            kind: 'ghost',
            onClick: () => alert(simulatorButtonsTooltipMessage),
            className: 'px-2',
          },
        ]
      : undefined;
  if (hidePasteValuesButtons) headerButtons?.splice(-1, 1);

  return (
    <Layout
      {...layoutProps}
      navigationHistoryLinks={navigationHistoryLinks}
      headerButtons={headerButtons}
      darkerBackground
      className="h-full overflow-y-hidden pb-16"
      customAttrs={{
        'data-cy': 'simulator-layout-base',
      }}
    >
      <div className="h-full w-full col-span-full overflow-y-auto relative">
        {user?.isCommercialDealer && <WhatsappButton />}
        <div className="col-span-full h-full p-2 md:p-5 overflow-y-auto">{children}</div>
      </div>
    </Layout>
  );
};

export default SimulatorLayoutBase;
