import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal } from '@clarke-energia/foton';

import { IProposalUser, IUpdateSellerPayload } from '@schemas/types';
import useUsers from '@hooks/use-users';

import { ComboBoxFormField } from '@components/atoms/form/fields/combobox-form-field';

interface IModalModalChangeSeller {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  proposalId: string;
  handleUpdateProposalSeller: (updateProposalSellerData: IUpdateSellerPayload) => Promise<void>;
  leadId: string;
}

export interface ISellerChangeLoad {
  seller: string;
}

const ModalChangeSeller = ({
  isModalOpen,
  setIsModalOpen,
  proposalId,
  handleUpdateProposalSeller,
  leadId,
}: IModalModalChangeSeller): JSX.Element => {
  const formController = useForm<ISellerChangeLoad>();
  const sellerId = formController.watch('seller');

  const [loading, setLoading] = React.useState<boolean>(false);

  const { getSellersUsersHandler, sellersUsers, isLoading: isFetchingUsers } = useUsers();

  const getSellerInfoById = (sellerId: string): IProposalUser | null => {
    const seller = sellersUsers && sellersUsers.find((sellerUser) => sellerUser.id == sellerId);

    if (seller) {
      return {
        id: seller.id,
        name: seller.name as string,
        pictureUrl: seller.pictureUrl as string,
      };
    }
    return null;
  };

  React.useEffect(() => {
    getSellersUsersHandler();
  }, []);

  const handleUpdateProposalSellerButtonClick = async (sellerId: string) => {
    const seller = getSellerInfoById(sellerId);
    if (seller && seller.id !== null) {
      setLoading(true);
      const updateProposalSellerData: IUpdateSellerPayload = {
        proposalId: proposalId,
        seller,
        leadId,
      };
      handleUpdateProposalSeller(updateProposalSellerData).finally(() => setLoading(false));
    }
  };

  return (
    <Modal
      className="md:w-96 md:h-full overflow-y-hidden"
      open={isModalOpen !== undefined}
      setOpen={() => setIsModalOpen(false)}
      title="Alterar vendedor"
      description="Edite o vendedor responsável por esta proposta."
      modalButtons={{
        primary: {
          label: 'Atualizar',
          style: { backgroundColor: 'primary', width: '100%' },
          onClick: () => sellerId && handleUpdateProposalSellerButtonClick(sellerId),
          loading,
        },
        secondary: {
          label: 'Cancelar',
          style: { width: '100%' },
          onClick: () => setIsModalOpen(false),
          disabled: loading,
        },
      }}
    >
      <FormProvider {...formController}>
        <form>
          {sellersUsers && (
            <div className="h-24 mb-20">
              <ComboBoxFormField
                id="seller"
                field="seller"
                label="Vendedor"
                placeholder="Selecione um vendedor"
                isLoading={isFetchingUsers}
                inputOptions={sellersUsers.map((sellerUserEntry) => ({
                  value: sellerUserEntry.id,
                  label: sellerUserEntry.name as string,
                }))}
                maxMenuHeightInPx={130}
                onInputChange={(value) => {
                  value && getSellersUsersHandler(value);
                }}
              />
            </div>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ModalChangeSeller;
