import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosPromise } from 'axios';
import { FeedbackNotificationProps } from '@clarke-energia/foton';

import { useAuth } from '@src/auth-wrapper';

import { IProposalBase, IResponseBase } from '@schemas/types';

import { getProposalManagementFeedbackNotificationContent, transformObjectKeysToCamelCase } from '@utils/common';
import { IAlertMessage } from '@components/atoms/general/semantic-marquee';

type IGetProposalRequest<T extends IProposalBase<any, any>> = (
  leadId?: string,
  sellerId?: string,
) => AxiosPromise<IResponseBase>;

type IDeleteProposalsByIds = (leadId: string, proposalId: Array<string>) => AxiosPromise<IResponseBase>;

function useLeadProposals<T extends IProposalBase<any, any>>(
  getProposals: IGetProposalRequest<T>,
  deleteProposalsByIds: IDeleteProposalsByIds,
) {
  const { leadId } = useParams<{ leadId: string }>();
  const [loading, setLoading] = useState(true);
  const [leadProposals, setLeadProposals] = useState<T[]>([]);
  const [alertMessage, setAlertMessage] = useState<IAlertMessage>();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [feedbackNotificationsContent, setFeedbackNotificationsContent] = useState<FeedbackNotificationProps>();

  const { user } = useAuth();
  const sellerId = user?.isCommercialDealer ? user.id : undefined;

  const getProposalsHandler = async (id: string) => {
    try {
      const response = await getProposals(id, sellerId);
      const getProposalsResponseData = response.data;

      if (getProposalsResponseData.message === 'No proposal(s) found.') {
        setAlertMessage({ kind: 'WARNING', content: 'Nenhuma proposta foi encontrada' });
      } else {
        const getProposalsResponseDataCamelized = transformObjectKeysToCamelCase(
          getProposalsResponseData,
        ) as IResponseBase;
        const leadProposals = getProposalsResponseDataCamelized.data;
        setLeadProposals(leadProposals);
        setIsDrawerOpen(true);
      }
    } catch {
      setAlertMessage({ kind: 'ERROR', content: 'Houve um erro no carregamento das propostas' });
    } finally {
      setLoading(false);
    }
  };

  const onCloseFeedbackNotification = () => {
    setFeedbackNotificationsContent(undefined);
  };

  const feedbackNotificationContent = getProposalManagementFeedbackNotificationContent(onCloseFeedbackNotification);

  const onClickDeleteProposal = async (leadId: string, proposalId: string) => {
    try {
      const responseDelete = await deleteProposalsByIds(leadId, [proposalId]);

      if (responseDelete.data.success) {
        setFeedbackNotificationsContent(feedbackNotificationContent.DELETE_SUCCESS);
        getProposalsHandler(leadId);
      }
    } catch {
      setFeedbackNotificationsContent(feedbackNotificationContent.DELETE_FAILURE);
    } finally {
      setIsDrawerOpen(false);
    }
  };

  useEffect(() => {
    if (leadId) {
      setLoading(true);
      getProposalsHandler(leadId);
    }
  }, [leadId]);

  useEffect(() => {
    if (leadProposals.length > 0) {
      setLoading(false);
    }
  }, [leadProposals]);

  return {
    leadId: leadId as string,
    leadProposals,
    loading,
    alertMessage,
    isDrawerOpen,
    onClickDeleteProposal,
    getProposalsHandler,
    feedbackNotificationsContent,
  };
}

export default useLeadProposals;
