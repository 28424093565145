import { IProductTwoProposal } from '@schemas/product-two/types';
import { ILeadProposalsHubHistoryBase } from '@schemas/types';

import {
  LeadProposalsHubHistoryAccordion,
  LeadProposalsHubHistory,
} from '@components/atoms/general/lead-proposals-hub/history';

import ProductTwoProposalPreviewBody from '../body';

export interface IProductTwoLeadProposalsHub extends ILeadProposalsHubHistoryBase {
  onClickDeleteProposal: (leadId: string, selectedProposalId: string) => Promise<void>;
  proposalsList: Array<IProductTwoProposal>;
}

const ProductTwoLeadProposalsHubHistory = ({
  leadId,
  proposalsList,
  selectedProposalId,
  setSelectedProposalId,
  onClickDeleteProposal,
  feedbackNotificationsContent,
}: IProductTwoLeadProposalsHub): JSX.Element => {
  return (
    <LeadProposalsHubHistory>
      <>
        {proposalsList?.map((proposal) => (
          <LeadProposalsHubHistoryAccordion
            key={`lead-proposals-hub-historic-p2-${proposal?.id}`}
            id={proposal?.id}
            sellerName={proposal.seller?.name}
            createdAt={proposal?.createdAt}
          >
            <ProductTwoProposalPreviewBody
              leadProposal={proposal}
              leadId={leadId}
              selectedProposalId={selectedProposalId}
              setSelectedProposalId={setSelectedProposalId}
              onClickDeleteProposal={onClickDeleteProposal}
              feedbackNotificationsContent={feedbackNotificationsContent}
            />
          </LeadProposalsHubHistoryAccordion>
        ))}
      </>
    </LeadProposalsHubHistory>
  );
};

export default ProductTwoLeadProposalsHubHistory;
