import { tv as twVariants } from 'tailwind-variants';
import { CardContentProps } from '.';
import { IWhiteLabelSupplierColors } from '@schemas/white-label/types';

export function applyWhiteLabelColors(colors: IWhiteLabelSupplierColors) {
  if (!colors.main || !colors.brighter || !colors.darker) return;
  const { main, brighter, darker } = colors;

  document.documentElement.style.setProperty('--text-white-label-main', main);
  document.documentElement.style.setProperty('--text-white-label-brighter', brighter);
  document.documentElement.style.setProperty('--text-white-label-darker', darker);
  document.documentElement.style.setProperty('--bg-white-label-main', main);
  document.documentElement.style.setProperty('--bg-white-label-brighter', brighter);
  document.documentElement.style.setProperty('--bg-white-label-darker', darker);
}

export const cardContentStyles = twVariants({
  slots: {
    container: '',
    titleWhiteLabel: '',
    title: '',
    label: '',
  },
  variants: {
    type: {
      variant: {
        container: 'card-content-standard-container-wl',
        title: 'card-content-standard-title-wl',
        label: 'card-content-standard-label-wl',
      },
      standard: {
        container: 'card-content-standard-container',
        title: 'card-content-standard-title',
        label: 'card-content-standard-label',
      },
      specialColor: {
        container: 'card-content-special-container',
        title: 'card-content-special-title',
        label: 'card-content-special-label',
      },
      description: {
        container: 'card-content-description-container',
        titleWhiteLabel: 'card-content-description-title-wl',
        title: 'card-content-description-title',
        label: 'card-content-description-label',
      },
    },
    variantClass: {
      primary: {
        title: 'text-primary-70',
        label: 'text-primary-70',
      },
      secondary: {
        title: 'text-neutral-50',
        label: 'text-neutral-50',
      },
      variant: {
        title: 'text-white-label-main',
        label: 'text-white-label-main',
      },
      description: {
        titleWhiteLabel: 'text-white-label-main',
        title: 'text-primary-70',
        label: 'text-neutral-50',
      },
      even: {
        title: 'card-content-special-light',
        label: 'card-content-special-bold',
      },
      odd: {
        title: 'card-content-special-bold',
        label: 'card-content-special-light',
      },
    },
  },
  defaultVariants: {
    type: 'standard',
    variantClass: 'secondary',
    props: 'colors',
  },
});

export function getVariantClassForTypeAndIndex(type: CardContentProps['type'], index: number) {
  if (type === 'variant') {
    return index === 0 ? 'variant' : 'secondary';
  }

  if (type === 'specialColor') {
    return index % 2 === 0 ? 'even' : 'odd';
  }

  if (type === 'description') {
    return index === 0 ? 'description' : 'secondary';
  }

  return index === 0 ? 'primary' : 'secondary';
}
